import { AxiosResponse } from 'axios';
import { getGoogleToken } from 'helpers/firebase';

import { post } from 'services/api';

export async function getVacations(user): Promise<AxiosResponse> {
  const googleToken = await getGoogleToken(user)
  const response = await post('sheets', {token: googleToken});
  return response;
}
