import React, { useState, FunctionComponent } from 'react';
import { HOME, SEARCH, VACATIONS, STAFF_DIRECTORY } from 'constants/urls';

import ProfilePicture from 'components/commons/ProfilePicture';

import myPshLogo from 'views/LoginView/assets/logo.svg';
import Novedades from './assets/icosn-05.svg';
import EquipoPSh from './assets/icosn-02.svg';
import PeopleAndAdmin from './assets/icosn-04.svg';
import Vacations from './assets/vacations.svg';
import Search from './assets/icosn-03.svg';
import Staff from './assets/staff.svg';
import { ReactComponent as InstagramLogo } from './assets/instagram-brands.svg';
import { ReactComponent as LinkedinLogo } from './assets/linkedin-brands.svg';
import { ReactComponent as YoutubeLogo } from './assets/youtube-brands.svg';
import { ReactComponent as TwitchLogo } from './assets/twitch-brands.svg';

import { ISideMenuProps } from './types'
import { Container, ItemsSection, ItemList, Item, MenuIcons, ContainerMobile, Logo, SocialMedia } from './styles';

const SideMenu: FunctionComponent<ISideMenuProps> = ({ isPromobarDisplayed, isPromobarOpen }: ISideMenuProps) => {
  const [isOpen] = useState(false);
  return (
    <>
      <Container isPromobarDisplayed={isPromobarDisplayed} isPromobarOpen={isPromobarOpen} >
        <ItemsSection>
          <Logo to={HOME}>
            <img src={myPshLogo} alt="We are P S H" />
          </Logo>
          <ItemList>
            <ProfilePicture />
            <Item to={`${HOME}?scrollTo=News`} >
              <MenuIcons src={Novedades} alt="news" />
              <p> News </p>
            </Item>
            <Item to={`${HOME}?scrollTo=PshTeam`}>
              <MenuIcons src={EquipoPSh} alt="team" />
              <p> PSh Team </p>
            </Item>
            <Item to={`${HOME}?scrollTo=PeopleAndAdmin`}>
              <MenuIcons src={PeopleAndAdmin} alt="human resources" />
              <p> People &amp; <br /> Admin </p>
            </Item>
            <Item to={`${STAFF_DIRECTORY}`}>
              <MenuIcons src={Staff} alt="Staff Directory" />
              <p> Staff </p>
            </Item>
            <Item to={`${VACATIONS}`}>
              <MenuIcons src={Vacations} alt="PTO" />
              <p> PTO </p>
            </Item>
            <Item to={SEARCH}>
              <MenuIcons src={Search} alt="search" />
              <p> Search </p>
            </Item>
          </ItemList>
        </ItemsSection>
        <SocialMedia>
          <a href="https://www.instagram.com/wearepsh/" target="_blank" rel="noreferrer" >
            <InstagramLogo />
          </a>
          <a href="https://ar.linkedin.com/company/wearepsh" target="_blank" rel="noreferrer" >
            <LinkedinLogo />
          </a>
          <a href="https://www.youtube.com/channel/UCxHM9n8wjMbN3l3YnRkrf8A" target="_blank" rel="noreferrer" >
            <YoutubeLogo />
          </a>
          <a href="https://www.twitch.tv/wearepsh" target="_blank" rel="noreferrer" >
            <TwitchLogo />
          </a>
        </SocialMedia>
      </Container>
      {isOpen && <ContainerMobile isPromobarDisplayed={isPromobarDisplayed} isPromobarOpen={isPromobarOpen}/>}
    </>
  );
};

export default SideMenu;
