import React, { FunctionComponent } from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import { ArrowContainer, BodyContainer, FaqBox, QAContainer, QSubtitle, QDesktopSkeleton, QMobileSkeleton } from './styles';

const FaqCardSkeleton: FunctionComponent = () => {


    const randomIntFromInterval = (min, max) => {
        return Math.floor(Math.random() * (max - min + 1) + min)
    }

    return (
        <SkeletonTheme baseColor='rgba(85, 85, 85, 0.541)' highlightColor='rgba(85, 85, 85, 0.541)'> 
            <QAContainer>
                <BodyContainer>   
                    <FaqBox>
                        <QSubtitle>
                            <QDesktopSkeleton>
                                <Skeleton className="FaqCardSkeleton" style={{width: `${randomIntFromInterval(50, 80)}%`}}/>
                            </QDesktopSkeleton>
                            <QMobileSkeleton>
                                <Skeleton className="FaqCardSkeleton" style={{width: `80%`}}/>
                                <Skeleton className="FaqCardSkeleton" style={{width: `${randomIntFromInterval(50, 80)}%`}}/>
                            </QMobileSkeleton>
                        </QSubtitle>
                    </FaqBox>
                    <ArrowContainer>
                        <Skeleton style={{width: '30px', height: '20px'}}/>
                    </ArrowContainer>
                </BodyContainer>
            </QAContainer>
        </SkeletonTheme>
    )
}

export default FaqCardSkeleton;
