import React, { FunctionComponent } from 'react';

import ImageCard from 'components/strapiComponents/ImageCard';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { GridContainer, GridItem, SeeAll } from 'styles/commons';

import { Title, TextContainer } from './styles';
import { ITechWellnessNewsletter } from './types';

const TechWellnessNewsletter: FunctionComponent<ITechWellnessNewsletter> = (props: ITechWellnessNewsletter) => {
const {techWellnessNewsletterCards}=props;

  return (
    <GridContainer marginTop='60px' marginTopMobile='30px' gapSize='30px 5px'>
      <GridItem spanSize={6} spanSizeMobile={18} startColumn={1} spanSizeTablet={9} >
        <TextContainer>
          <Title>Tech Talks</Title>
          <SeeAll to='/tech-talks'>VER TODO</SeeAll>
        </TextContainer>
        {techWellnessNewsletterCards.tech && <ImageCard data={techWellnessNewsletterCards.tech} hasHover />}
      </GridItem>
      <GridItem spanSize={6} spanSizeMobile={18} startColumn={10} spanSizeTablet={9} >
        <TextContainer>
          <Title>Wellness</Title>
          <SeeAll to='/wellness'>VER TODO</SeeAll>
        </TextContainer>
        {techWellnessNewsletterCards.wellness && <ImageCard data={techWellnessNewsletterCards.wellness} /> }
      </GridItem>
      <GridItem spanSize={6} spanSizeMobile={18} startColumn={1} spanSizeTablet={9} >
        <TextContainer>
          <Title>Newsletters</Title>
          <SeeAll to='/newsletters'>VER TODO</SeeAll>
        </TextContainer>
        {techWellnessNewsletterCards.newsletter && <ImageCard data={techWellnessNewsletterCards.newsletter} /> }
      </GridItem>
    </GridContainer>
  );
};

export default TechWellnessNewsletter;

TechWellnessNewsletter.defaultProps = {};
