import React, { FunctionComponent } from 'react';

import { StyledFilterButton } from './styles'

const FilterButton: FunctionComponent<IFilterButtonProps> = ({OnClickOption, filter} ) => {

    const getButtonText = () => {
        if (filter === 'ENG') return 'ENGINEERING' 
        if (filter === 'MKT') return 'MARKETING'  
        if (filter === 'QA') return 'QUALITY ASSURANCE'
        if (filter === 'PM/SM') return 'ACCOUNTING MANAGEMENT'
        if (filter === 'SOS') return 'S.O.S'
        return filter;
    }
    return (
        <StyledFilterButton type="button" onClick={() => OnClickOption(filter)} filter={filter} >
            {getButtonText()}
        </ StyledFilterButton>
    )
}
export default FilterButton;

interface IFilterButtonProps {
    OnClickOption: (filterName: any) => void,
    onClick?: () => void;
    filter: string
}