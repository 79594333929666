import React, { FunctionComponent, useEffect } from "react";

import Calendar from 'components/calendar';
import VideoComponent from 'components/video';
import Manifesto from 'components/manifesto';
import CalendarSkeleton from "components/calendar/Skeleton";

import { Background, GridContainer, GridItem, TeamTitle } from "styles/commons";

import  IconContact from './assets/contact-list.svg';
import Townhalls from './assets/townhalls.svg';
import Send from './assets/send.svg';

// import PeopleSlider from "components/PeopleSlider";
import {
    StarterDeck,
    StarterDeckTitle,
    StarterDeckAbstract,
    CultureSuggestions,
    SuggestionsTitle,
    SuggestionsAbstract,
    SuggestionsLink,
    CalendarContainer,
    CardLink,
    InnerSection,
    TeamIcon,
    TeamImage,
    SendImage,
    InternalLink,
    Abstract,
    NewNotificationContainer,
    NotificationText

} from './styles'
import { IRenderPshTeamProps } from "./types";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const RenderPshTeam: FunctionComponent<IRenderPshTeamProps> = React.memo(({ loading, calendar, scrollToPshTeam, newNotificationTownhall }: IRenderPshTeamProps) => {
    const PshTeam = React.useRef<HTMLInputElement>(null);
    useEffect(() => {
      if (scrollToPshTeam && PshTeam.current) {
        PshTeam.current.scrollIntoView({
          behavior: "smooth",
        });
      }
    }, [scrollToPshTeam]);

    return(
        <Background marginTop='60px' marginTopMobile='30px' ref={PshTeam}>
            <TeamTitle>PSh Team</TeamTitle>
            {/*
            <GridContainer gapSize='30px 5px'>
                <CalendarContainer>
                    {
                        !loading.CalendarCardsLoading && calendar[0] ?
                            <Calendar data={calendar} />
                            : <CalendarSkeleton />
                    }
                </CalendarContainer>
            </GridContainer>
            */}
            <VideoComponent data={{url: "https://vimeo.com/777001930"}} />
            <Manifesto/>
            {/* <CultureTitle>Organigrama</CultureTitle>
            <GridContainer>
                <ChartContainer>
                <CircularChart alt="organization chart" src="https://community.openenergymonitor.org/uploads/default/original/2X/a/a2dc32ffbdde81167c5e451b694ff8a428dd8ff0.png" />
                </ChartContainer>
                <PeopleSliderContainer>
                <PeopleSlider employeeCards={employeeCards} />
                </PeopleSliderContainer>
            </GridContainer> */}
            <GridContainer marginTop="30px" gapSize='30px 5px'>
                <GridItem spanSize={6} startColumn={0} spanSizeTablet={18} spanSizeMobile={18}>
                    <CardLink href="https://docs.google.com/presentation/d/1r6vMf-t8_cG-3ASpeNy9NUNqVXjPpT4wmVBn8SUnPpo/edit?usp=sharing" target="_blank">
                        <StarterDeck>
                            <StarterDeckTitle>Starter Deck</StarterDeckTitle>
                            <StarterDeckAbstract>
                                ¡Te damos la bienvenida! ¡Conocé todo sobre PSh!
                            </StarterDeckAbstract>
                        </StarterDeck>
                    </CardLink>
                </GridItem>
                <GridItem spanSize={6} startColumn={0} spanSizeTablet={18} spanSizeMobile={18}>
                    <InternalLink to='/staff-directory'>
                        <StarterDeck>
                            <StarterDeckTitle>STAFF DIRECTORY</StarterDeckTitle>
                            <InnerSection>
                                <StarterDeckAbstract>Contactate con todo el equipo</StarterDeckAbstract>
                                <TeamIcon>
                                    <TeamImage src={IconContact} alt="staff icon"/>
                                </TeamIcon>
                            </InnerSection>
                        </StarterDeck>
                    </InternalLink>
                </GridItem>
                <GridItem spanSize={6} startColumn={0} spanSizeTablet={18} spanSizeMobile={18}>
                    <InternalLink to='/townhall'>
                        <StarterDeck isPositionRelative>
                            {newNotificationTownhall && 
                                <NewNotificationContainer>
                                    <NotificationText>
                                        NEW
                                    </NotificationText>
                                </NewNotificationContainer>
                            }
                            <StarterDeckTitle>Townhalls</StarterDeckTitle>
                            <InnerSection>
                                <StarterDeckAbstract>Si te perdiste algún townhall, búscalo acá</StarterDeckAbstract>
                                <TeamIcon isAlignedBottom>
                                    <TeamImage src={Townhalls} alt="townhalls"/>
                                </TeamIcon>
                            </InnerSection>   
                        </StarterDeck>
                    </InternalLink>
                </GridItem>
            </GridContainer>
            <GridContainer marginTop='30px' gapSize='30px 5px'>
                <GridItem spanSize={18} startColumn={0} spanSizeTablet={18} spanSizeMobile={18}>
                    <CultureSuggestions>
                        <SuggestionsTitle>BUZÓN DE SUGERENCIAS</SuggestionsTitle>
                        <SuggestionsAbstract>
                            <Abstract>
                                Dejanos tu sugerencia o comentario para que podamos seguir creciendo como equipo.
                                <SuggestionsLink href="https://docs.google.com/forms/d/e/1FAIpQLSeDXr-YjYmTWwW3KuaDlSgkNYKgjLTEalqSrqoBf0xOryGQuQ/viewform?usp=sf_link" target="_blank">
                                    <span>Enviar ahora</span> &gt;
                                </SuggestionsLink>
                            </Abstract>
                            <TeamIcon>
                                <SendImage src={Send} alt="send suggestion"/>
                            </TeamIcon>
                        </SuggestionsAbstract>
                    </CultureSuggestions>
                </GridItem>
            </GridContainer>
        </Background>
    )
})
