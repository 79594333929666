import styled from 'styled-components'
// import { COLORS } from 'helpers/enums/colors';
// import { MOBILE_460 } from 'constants/sizes';

export const LottieContainer = styled.div<IProps>`
 display: flex;
 justify-content: center;

  svg{
    width: 45% !important;
  }
`

interface IProps {
    height: string,
    width: string
}
