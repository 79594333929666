import React, { FunctionComponent } from 'react';

import ContentCard from 'components/ContentCard';
import SocialMediaCard from 'components/socialMediaCard';
import ImageCard from 'components/strapiComponents/ImageCard';
import LoadingComponent from 'components/commons/Loading';
import { SearchNotFound } from 'components/SearchNotFound';
import BackButton from 'components/BackButton';
import Input from 'components/inputs/Input'

import ContentCardSkeleton from 'components/ContentCard/skeleton';
import SocialMediaCardSkeleton from 'components/socialMediaCard/skeleton';
import ImageCardSkeleton from 'components/strapiComponents/ImageCard/skeleton';

import { COLORS } from 'helpers/enums/colors';

import { Background, SectionNamePostView, Title, TopContainer } from 'styles/commons';
import { HomeContainer, SectionTitle, TotalResults, Bold, CardsGrid, NewsContainer, SeeMoreContent, SeeAll } from './styles';

import { IHomeViewProps } from './types';

const SearchResultsView: FunctionComponent<IHomeViewProps> = (props: IHomeViewProps) => {
  const { results, input, contentType, loading, textQuery } = props;
  let searchResults = results;
  const resultsNumber = results.length;
  if( contentType ){
    searchResults = results.filter(result => result.contentType === contentType);
  }
  
  const techTalks = searchResults.filter(result => result.contentType === "tech-talk");
  const friendlyReminder = searchResults.filter(result => result.contentType === "friendly-reminder");
  const socialMedias = searchResults.filter(result => result.contentType === "social-media");
  const trainings = searchResults.filter(result => result.contentType === "training-palooza");
  const newsletters = searchResults.filter(result => result.contentType === "monthly-newsletter");
  const wellness = searchResults.filter(result => result.contentType === "wellness");
  const townhall = searchResults.filter(result => result.contentType === "townhall");
  const sortedResults = (toBeSorted) => {
    toBeSorted.sort((a,b) => {  
      const dateA = new Date(a.publishedAt).getTime();
      const dateB = new Date(b.publishedAt).getTime();
      return dateA < dateB ? 1 : -1;  
    })
    return toBeSorted;
  };

  const meta = {
    touched: false,
    error: 'string',
  };
  
  const ContentName = { 
    'friendly-reminder': 'Welcome!',
    'social-media': 'En redes',
    'tech-talk': 'Tech Talks',
    'wellness': 'Wellness',
    'monthly-newsletter': 'Newsletters',
    'townhall': 'Townhalls',
    'training-palooza': 'Training Palooza',
  };
  
  const ContentFamily = { 
    'friendly-reminder': 'NEWS',
    'social-media': 'NEWS',
    'tech-talk': 'NEWS',
    'wellness': 'NEWS',
    'monthly-newsletter': 'NEWS',
    'townhall': 'NEWS',
    'training-palooza': 'PEOPLE',
  };

  const ColorParser = (contectColor) => {
    if(contectColor === 'NEWS') 
    {return COLORS.cherry }
    return COLORS.purple
  };

  const renderCardSkeleton = () => {
    switch (contentType) {
      case 'friendly-reminder':
        return (
          <>
            {
              [...Array(3).keys()].map(number =>
                <ContentCardSkeleton key={`friendly-reminder ImgCardSkeleton_ ${number}`} size='large'/> 
              )
            }
          </>
        )
      case 'social-media':
        return (
          <NewsContainer>
          <CardsGrid>
            {
              [...Array(6).keys()].map(number =>
                <SocialMediaCardSkeleton key={`social-media ImgCardSkeleton_ ${number}`} />
                )
              }
          </CardsGrid>
        </NewsContainer>
      )
      case 'tech-talk':
        return (
          <NewsContainer>
            <CardsGrid>
              {
                [...Array(6).keys()].map(number =>
                  <ImageCardSkeleton key={`tech-talk ImgCardSkeleton_ ${number}`} skeleton='searchview' />
                )
              }
            </CardsGrid>
          </NewsContainer>
        )
      case 'wellness':
        return (
          <NewsContainer>
            <CardsGrid>
              {
                [...Array(6).keys()].map(number =>
                  <ImageCardSkeleton key={`wellness ImgCardSkeleton_ ${number}`} skeleton='searchview' />
                )
              }
            </CardsGrid>
          </NewsContainer>
        )
      case 'monthly-newsletter':
        return (
          <NewsContainer>
            <CardsGrid>
              {
                [...Array(6).keys()].map(number =>
                  <ImageCardSkeleton key={`monthly-newsletter ImgCardSkeleton_ ${number}`} skeleton='searchview' />
                )
              }
            </CardsGrid>
          </NewsContainer>
        )
      case 'training-palooza':
        return (
          <NewsContainer>
            <CardsGrid>
              {
                [...Array(6).keys()].map(number =>
                  <ImageCardSkeleton isTraining key={`training-palooza ImgCardSkeleton_ ${number}`} skeleton='searchview' />
                )
              }
            </CardsGrid>
          </NewsContainer>
        ) 
      case 'townhall':
        return (
          <NewsContainer>
            <CardsGrid>
              {
                [...Array(6).keys()].map(number =>
                  <ImageCardSkeleton key={`townhall ImgCardSkeleton_ ${number}`} skeleton='searchview' />
                )
              }
            </CardsGrid>
          </NewsContainer>
        )
      default:
      return (<><LoadingComponent/></>)
    }
    return renderCardSkeleton();
  };
  
  return (
    <HomeContainer data-testid="home-view">
      <Background hasMarginBottom>
        <TopContainer>
          <BackButton />
          <SectionNamePostView contentColor={ColorParser(ContentFamily[contentType])}>{ ContentFamily[contentType] }</SectionNamePostView>
        </TopContainer>
        <Title>{ ContentName[contentType] || 'Resultado de búsqueda'}</Title>
        <Input variant="filled" input={input} meta={meta}/>
        {loading ? renderCardSkeleton()
        :
        <>
        { (!contentType) && (resultsNumber > 0) &&
          <TotalResults>Tenés  <Bold> {sortedResults(results).length} resultados </Bold> {(textQuery !== "") && <>para &quot;<Bold>{textQuery}</Bold>&quot; </>}</TotalResults>
        }

        {friendlyReminder.length > 0 &&
          <div>
            { !contentType &&
              <SectionTitle>Friendly Reminders!</SectionTitle>
            }
            {sortedResults(friendlyReminder).map((result) => {
              return( 
                <ContentCard data={result} key={result?.slug} size='large' />
              );
            })}
           {(friendlyReminder.length > 4 && !contentType) && <SeeMoreContent>Encontralos todos en <SeeAll to="/friendly-reminders">Friendly Reminders</SeeAll></SeeMoreContent>}
          </div>
        }
        {socialMedias.length > 0 &&
          <div>
            { !contentType &&
              <SectionTitle>Social Media</SectionTitle>
            }
            <NewsContainer>
              <CardsGrid>
                {sortedResults(socialMedias).map((result) => {
                  return(
                    <SocialMediaCard data={result} />
                  );
                })}
              </CardsGrid>
            </NewsContainer>
            {(socialMedias.length > 4 && !contentType) && <SeeMoreContent>Encontralos todos en <SeeAll to="/social-media">Social Media</SeeAll></SeeMoreContent>}
          </div>
        }
        {techTalks.length > 0 &&
          <div>
            { !contentType && <SectionTitle>Tech Talks</SectionTitle>}
            <NewsContainer>
              <CardsGrid>
                {sortedResults(techTalks).map((result) => {
                  return <ImageCard data={result} hasHover />
                })}
              </CardsGrid>
            </NewsContainer>
            {(techTalks.length > 4 && !contentType) && <SeeMoreContent>Encontralos todos en <SeeAll to="/tech-talks">Tech Talks</SeeAll></SeeMoreContent>}
          </div>
        }
        {newsletters.length > 0 &&
          <div>
            {!contentType && <SectionTitle>Newsletters</SectionTitle>}
            <NewsContainer>
              <CardsGrid>
                {sortedResults(newsletters).map((result) => {
                  return <ImageCard data={result} />
                })}
              </CardsGrid>
            </NewsContainer>
            {(newsletters.length > 4 && !contentType) &&<SeeMoreContent>Encontralos todos en <SeeAll to="/newsletters">Newsletters</SeeAll></SeeMoreContent>}
          </div>
        }
        {wellness.length > 0 &&
          <div>
            {!contentType && <SectionTitle>Wellness</SectionTitle>}
            <NewsContainer>
              <CardsGrid>
                {sortedResults(wellness).map((result) => {
                  return <ImageCard data={result} />
                })}
              </CardsGrid>
            </NewsContainer>
            {(wellness.length > 4 && !contentType) && <SeeMoreContent>Encontralos todos en <SeeAll to="/wellness">Wellness</SeeAll></SeeMoreContent>}
          </div>
        }
        {trainings.length > 0 &&
          <div>
            {!contentType && <SectionTitle>Training Palooza</SectionTitle>}
            <NewsContainer>
              <CardsGrid>
                {sortedResults(trainings).map((result) => {
                  return <ImageCard hasHover isTraining data={result} />
                })}
              </CardsGrid>
            </NewsContainer>
            {(trainings.length > 4 && !contentType) && <SeeMoreContent>Encontralos todos en <SeeAll to="/training-palooza">Training Palooza</SeeAll></SeeMoreContent>}
          </div>
        }
        {townhall.length > 0 &&
          <div>
              {!contentType && <SectionTitle>Townhalls</SectionTitle>}
              <NewsContainer>
                <CardsGrid>
              {sortedResults(townhall).map((result) => {
                return <ImageCard data={result}/>;
              })}
              </CardsGrid>
              </NewsContainer>
              {(townhall.length > 4 && !contentType) && <SeeMoreContent>Encontralos todos en <SeeAll to="/townhall">Townhall</SeeAll></SeeMoreContent>}
          </div>
        }
        { (resultsNumber === 0 || searchResults.length === 0) &&
          <SearchNotFound widthSize={60} />
        }
          </>
        }
      </Background>
    </HomeContainer>
  );
};

export default SearchResultsView;
