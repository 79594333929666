import { COLORS } from 'helpers/enums/colors';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #dfdfdf;
`;
export const LoginButton = styled.button`
  display: flex;
  flex-direction: column;
  padding: 17px 16px;
  background-color: #EF233C;
  color: #FFFFFF;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
  margin-top: 53px;
  border: none;
  border-radius: 10px;
  font-family: montserrat-semi-bold;
  transition: background-color 0.4s ease-in-out;
  &:hover {
    background-color: ${COLORS.darkRed};
  }
`;
