import styled from "styled-components";
import { TABLET_768, TABLET_992 } from 'constants/sizes';
import { COLORS } from "helpers/enums/colors";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  position: relative; 
  overflow: hidden;
  margin-bottom: 30px;
`
export const SpecificQuestion = styled.div`
  height: 50%;
  padding: 3% 5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background:${COLORS.purple} no-repeat padding-box;
  box-shadow: 0px 10px 20px ${({ theme }) => theme.boxShadow};
  border-radius: 10px;
  @media (max-width: ${TABLET_768}px) {
    flex-direction: column;
  }
`
export const SpecificText = styled.div`
  font: bold 25px/40px montserrat;
  color: ${COLORS.white};

  @media (max-width: ${TABLET_768}px) {
    font: bold 22px/30px montserrat;
    text-align: center;
  }
`
export const QButton = styled.button`
  width: fit-content;
  height: 40%;
  padding: 15px 20px;
  border: none;
  font: bold 18px/30px montserrat;
  text-transform: uppercase;
  color: ${COLORS.white};
  background: ${COLORS.cherry};
  border-radius: 10px;
  transition: background-color 0.4s ease-in-out;
  &:hover {
    background-color: ${COLORS.darkRed};
  }
  @media (max-width: ${TABLET_992}px) {
    font: bold 16px/25px montserrat;
  }
  @media (max-width: ${TABLET_768}px) {
    margin-top: 5px;
    height: fit-content;
  }
`
