import styled from 'styled-components'

export const LottieContainer = styled.div<IProps>`
	width: ${({ width }) => width};
	height: ${({ height }) => height};
`
interface IProps {
	height: string,
	width: string,
}
