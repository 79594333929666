import React, { FunctionComponent } from 'react';
import { useQuery } from 'helpers/router';

import { RenderNews } from 'components/News';
import { RenderPshTeam } from 'components/PShTeam';
import { RenderPeopleAndAdmin } from 'components/PeopleAndAdmin';
import {  HomeContainer,  } from './styles';

import { IHomeViewProps } from './types';

const HomeView: FunctionComponent<IHomeViewProps> = (props: IHomeViewProps) => {

  const { loading, friendlyReminders, socialMedias, calendar, techWellnessNewsletterCards,newNotificationEnglish, newNotificationTownhall } = props;
  const query = useQuery();
  const scrollToNews = (query.get("scrollTo") === "News");
  const scrollToPshTeam = (query.get("scrollTo") === "PshTeam");
  const scrollToPeopleAndAdmin = (query.get("scrollTo") === "PeopleAndAdmin");

  return (
    <HomeContainer data-testid="home-view">
        <>
          <RenderNews  loading={loading} friendlyReminders={friendlyReminders} socialMedias={socialMedias} scrollToNews={scrollToNews} newNotificationEnglish={newNotificationEnglish} techWellnessNewsletterCards={techWellnessNewsletterCards}/>
          <RenderPshTeam loading={loading} calendar={calendar} scrollToPshTeam={scrollToPshTeam} newNotificationTownhall={newNotificationTownhall}/>
          <RenderPeopleAndAdmin scrollToPeopleAndAdmin={scrollToPeopleAndAdmin} />
        </>
    </HomeContainer>
  );
};

export default HomeView;
