import styled from "styled-components";

export const BodyContainer = styled.div`
    box-sizing: border-box;
`

export const ComponentContainer = styled.div`
    max-width: 1200px;
    margin: 40px auto;
`
