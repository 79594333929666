import React, { FunctionComponent, useState } from 'react';

import { TEAMS } from 'helpers/enums/pshTeams';

import FilterButton from './FilterButton';

import { ReactComponent as FilterArrow } from './assets/arrow.svg';
import { Container, DropdownButton, List, WhiteLine } from './styles'

const EmployeeDropdown: FunctionComponent<IEmployeeDropdownProps> = ({ OnClickOption, filterOption} ) => {
   
    const [dropdownState, setDropdownState] = useState<boolean>(false)
    const getButtonText = (filter: string) => {
        if (filter === 'ENG') return 'ENGINEERING' 
        if (filter === 'MKT') return 'MARKETING'  
        if (filter === 'QA') return 'QUALITY ASSURANCE'
        if (filter === 'PM/SM') return 'ACCOUNTING MANAGEMENT'
        if (filter === 'SOS') return 'S.O.S'
        return filter;
    }
    return (
        <Container onClick={()=>setDropdownState(!dropdownState)} >
        <DropdownButton isOpen={dropdownState} filter={filterOption}>
            {filterOption === '' ? "Filtrar por team" :  getButtonText(filterOption)} 
            <FilterArrow />
        </DropdownButton>
        <List isOpen={dropdownState}>
            <WhiteLine />
                {
                    Object.keys(TEAMS).map(key => <FilterButton key={key} filter={key} OnClickOption={OnClickOption} />)
                }
            </List>
        </Container>
    );
};

export default EmployeeDropdown;

interface IEmployeeDropdownProps {
    OnClickOption: (filterName: any) => void,
    filterOption: string
}