import styled from 'styled-components';
import { MOBILE_575 } from 'constants/sizes';

import { ReactComponent as Dots } from './assets/dots.svg';
import { ReactComponent as Heart } from './assets/heart.svg';
import { ReactComponent as Instagram } from './assets/instagram.svg';
import { ReactComponent as Linkedin } from './assets/linkedin.svg';
import { ReactComponent as Youtube } from './assets/youtube.svg';
import { ReactComponent as Twitch } from './assets/twitch.svg';

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 13px 18px;
  border-radius: 10px;
  min-height: 100%;
  justify-content: space-between;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.cardBackgroundColor};
  box-shadow: 0px 10px 20px ${({ theme }) => theme.boxShadow};
`
export const LogoSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
  min-height: 50px;
`
export const LikeSection = styled.div`
  max-width: 80px;
  display: flex;
  align-items: center;
`
export const Image = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 6px;
  overflow: hidden;
`

export const TextContainer = styled.div`
  height: 100%;
  align-self: center;
  justify-content: center;
  align-items: center;
`

export const Text = styled.div`
  margin-top: 10px;
  min-height: 54px;
  font: 600 24px/28px montserrat-semi-bold;
  display: flex;
  align-self: center; // "container" horizontally centered
  text-align: center; // center aligned text
  align-items: center; // vertically centered when is only one line
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: ${({ theme }) => theme.title};
  @media (max-width: ${MOBILE_575}px) {
    font: 600 18px/20px montserrat-semi-bold;
    min-height: 40px;
  }
`
export const DotsIcon = styled(Dots)`
`
export const HeartIcon = styled(Heart)`
  margin-right: 15px;
`
export const InstagramIcon = styled(Instagram)`
`
export const LinkedinIcon = styled(Linkedin)`
`
export const YoutubeIcon = styled(Youtube)`
`
export const TwitchIcon = styled(Twitch)`
`
export const ExternalLink = styled.a`
  text-decoration: none;
  color: inherit;
  &:focus, &:hover, &:visited, &:link, &:active {
    text-decoration: none;    
  }
  &:focus {
    outline: none;
  }
`
