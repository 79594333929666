import styled from 'styled-components';
import { DESKTOP_1920 } from 'constants/sizes';

export const Container = styled.div<IDivProps>`
    position: relative;
    margin-top: 60px;
    margin-bottom: 50px;
`;

export const Text = styled.div<IDivProps>`
    position: absolute;
    font-size: 55px;
    top: 140px;
    left: 100px;
    z-index: 20;
    color: white;
    font-family: montserrat-extra-bold;
    transition: 4s;
    display: ${({ isVisible }) => isVisible ? "block" : "none"};
`;

export const VideoContainer = styled.div`
  position: relative;
  padding-top: 56.25% ;

  @media (max-width: ${DESKTOP_1920}px) {
      border-radius: 5px;
  }

    .player {
    position: absolute;
    top: 0;
    left: 0;

    @media (max-width: ${DESKTOP_1920}px) {
        border-radius: 10px;
        overflow: hidden;
    }
    }

    .player iframe, object, embed  {
        max-height: 800px !important;
    }
`

interface IDivProps {
    isVisible?: boolean | null;
}