import React, { FunctionComponent } from 'react';

import {
  CardContainer, 
  LogoSection, 
  Image, 
  Text, 
  DotsIcon, 
  HeartIcon, 
  LinkedinIcon, 
  InstagramIcon, 
  LikeSection, 
  ExternalLink, 
  YoutubeIcon, 
  TwitchIcon,
  TextContainer
} from './styles';

const SocialMediaCard: FunctionComponent<any> = (data: any) => {

  const { title, previewImage, socialMediaName, url } = data.data;

  const SocialMediaIcon = () => {
    switch (socialMediaName) {
      case 'linkedin':
        return <LinkedinIcon />;
        break;
      case 'instagram':
        return <InstagramIcon />;
        break;
      case 'youtube':
        return <YoutubeIcon />;
        break;
      case 'twitch':
        return <TwitchIcon />;
        break;
      default:
        return <LinkedinIcon />;
        break;
    }
  };

  return (
    <ExternalLink href={`${url}`} target="_blank">
      <CardContainer>
        <LogoSection>
          <div>
            <SocialMediaIcon />
          </div>
          <LikeSection>
            <HeartIcon />
            <DotsIcon />
          </LikeSection>
        </LogoSection>
          <Image src={previewImage.url} />
        <TextContainer>
          <Text>
            {title}
          </Text>
        </TextContainer>
      </CardContainer>
    </ExternalLink>
  );
};

export default SocialMediaCard;
