import React, { FunctionComponent } from 'react';

import moment from 'moment';

import {
  Container,
  LargeImage,
  SmallImage,
  TextContainer,
  Title,
  Extract,
  Date,
  InternalLink,
  ExternalLink,
} from './styles';

import { IContentCardProps } from './types';

const ContentCard: FunctionComponent<IContentCardProps> = (data: IContentCardProps) => {
  const { extract, title, previewImage, url, publishedAt, slug, contentType, bodyLength } = data.data;
  const size = data?.size;
  const DateRefactor = (cardDate:Date) => {
    moment.locale('es');
    const newDate = moment(cardDate).format("DD MMM YYYY"); 
    return newDate;
  }

  const renderCard = () => {
    switch (size) {
      case 'large': // used in search view
        return (
          <Container searchView >
            <LargeImage src={previewImage?.url} searchView/>
            <TextContainer searchView >
              <div>
              <Title searchView>{title}</Title>
              <Extract searchView>{extract}</Extract>
              </div>
              {publishedAt && <Date>{DateRefactor(publishedAt)}</Date>}
            </TextContainer>
          </Container>
        )
        case 'medium': // used in home view
          return (
            <Container>
              <LargeImage src={previewImage?.url} />
              <TextContainer marginCard='10px'>
                <div>
                <Title>{title}</Title>
                <Extract>{extract}</Extract>
                </div>
                {publishedAt && <Date>{DateRefactor(publishedAt)}</Date>}
              </TextContainer>
            </Container>
          )
        case 'short':  // used in home view
          return (
            <Container>
            <SmallImage src={previewImage?.url} />
              <TextContainer smallerText>
                <Title smallerText>{title}</Title>
                <Extract smallerText>{extract}</Extract>
            </TextContainer>
            </Container>
          )
        default:
          return (<></>)
    }
  }

  if(slug){
    return (
      bodyLength > 0 ? <InternalLink to={`/${contentType}/${slug}`}>
        {renderCard()}
      </InternalLink>
      :
      renderCard()
    );
  }

  return (
    <ExternalLink href={`${url}`}>
      {renderCard()}
    </ExternalLink>
  );

};

export default ContentCard;
