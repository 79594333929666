import styled from 'styled-components';

import { COLORS } from 'helpers/enums/colors';

import { ReactComponent as LogOut } from './assets/close-sesion.svg';

export const Container = styled.div<IContainerProps>`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  ${props => props.mobile ? "" : "width: 100%"};
  height: ${props => props.mobile ? "50px" : "min-content"};
  border-radius: 50%;
  text-align: center;
  position: relative;
  &:hover {
    cursor: pointer;
  }
  &:focus {
    outline: none;
  }
`
interface IContainerProps {
  mobile: boolean
}

export const Image = styled.img<IImageProps>`
  grid-column-start: 1;
  grid-column-end: 1;
  grid-row-start: 1;
  grid-row-end: 1;
  ${props => props.mobile ? "height: 50px" : "width: 100%"};
  border-radius: 50%;
  z-index: 2;
`;

interface IImageProps {
  mobile: boolean
}

export const Tooltip = styled.div<ITooltipProps>`
  width: 130px;
  height: ${props => props.isOpen ? "60px" : "0px"};
  transition: 0.25s;
  overflow: hidden;
  opacity: ${props => props.isOpen ? "1" : "0"};
  border-radius: 4px;
  background-color: ${ props => props.isDarkTheme ? `${COLORS.black}` : `${COLORS.darkBlue}`};
  box-shadow: 0 0 3px ${({ theme }) => theme.boxShadow};
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: absolute; 
  top: 120%;
  z-index: 1;
  ${props => props.mobile ? "right: 0px" : ""};

  & button {
    width: 100%;
    height: 20px;
    margin: auto;
    padding: 2px 15px;
    display: flex;
    align-items: center;
    color: white;
    background: none;
    border: none;
    box-shadow: none;
    word-break: keep-all;
    white-space: nowrap;
    font-size: 0.7rem;
    text-align: left;
  }

  & hr {
    width: 100px;
    height: 0;
    margin: 0 auto;
    padding: 0;
    border: 1px solid ${COLORS.darkGray};
  }
`;

export const BoxArrow = styled(LogOut)`
  height: 16px;
  position: relative;
  left: 25px;
`
interface ITooltipProps {
  readonly isOpen: boolean;
  readonly mobile: boolean;
  isDarkTheme: boolean;
}