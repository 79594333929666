import React, { FunctionComponent, useContext, useState, useRef, useEffect } from 'react';
import { UserContext } from 'context/UserContext';

import ToggleButton from 'components/ToggleButton';

import { IProfilePictureProps } from './types';

import { BoxArrow, Container, Image, Tooltip } from './styles';

const ProfilePicture: FunctionComponent<IProfilePictureProps> = ({mobile}: IProfilePictureProps) => {
  const context = useContext(UserContext);
  const { user } = context;
  
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const imageRef = useRef(null);
  const logOutRef = useRef(null);
  const switchModeRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        imageRef.current 
        && !imageRef.current.contains(event.target)
        && switchModeRef.current 
        && !switchModeRef.current.contains(event.target)
        && logOutRef.current 
        && !logOutRef.current.contains(event.target)      
        ) {
          setIsOpen(false)
        }
      }
  
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
  }, [imageRef, logOutRef, switchModeRef]);

  const switchMode = () => {
    context.switchMode();
  };

  const {logout} = context;

  return (
    <Container mobile={mobile} >
      <Image
        mobile={mobile}
        ref={imageRef}
        onClick={() => setIsOpen(!isOpen)}
        src={user?.photoURL || 'https://image.flaticon.com/icons/png/512/17/17603.png'}
      />
      <Tooltip mobile={mobile} isOpen={isOpen} isDarkTheme={useContext(UserContext).darkMode}>
        <button type="button" onClick={switchMode}>
          Cambiar tema
          <ToggleButton onClick={switchMode} isDarkTheme={useContext(UserContext).darkMode}/>
        </button>
        <hr/>
        <button type="button" onClick={logout} ref={logOutRef} >
          Cerrar sesión
          <BoxArrow />
        </button>
      </Tooltip>
    </Container>
  );
};

export default ProfilePicture;
ProfilePicture.defaultProps = {
  mobile: false
}
