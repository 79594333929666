import styled from 'styled-components'

import { TABLET_740 } from 'constants/sizes';

export const VideoContainer = styled.div`
  height: auto;
  width: 100%;
  @media (max-width: ${TABLET_740}px) {
    width: 100%;
    margin-top: 15px;
  }
`

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`

export const StandardVideo = styled.div`
  height: 0;
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  
  iframe, object, embed {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
`
