import { COLORS } from 'helpers/enums/colors';
import { MOBILE_460 } from 'constants/sizes';
import styled from 'styled-components'

export const Container = styled.div`
  margin: 60px 0;
  padding-left: 20%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  color: ${COLORS.purple};

  @media (max-width: ${MOBILE_460}px) {
    padding-left: 20px;
  }
`;

export const Quote = styled.div`
  padding-left: 2%;
  border-left: 2px solid ${COLORS.purple};
  min-width: 80%
`;

export const Author = styled.div`
  text-align: right;
  text-transform: capitalize;
  font-style: italic;
  margin-top: 20px;
`;
