import React, { useState, useContext, useEffect, FunctionComponent } from 'react';

import LayoutContainer from 'containers/LayoutContainer';
import { UserContext } from 'context/UserContext';

import { getVacations } from 'services/vacations.service';
import { getFaqVacations } from 'services/faq.service';

import VacationsView from 'views/VacationsCalendar';
import { IFaqData } from 'views/VacationsCalendar/types';

const VacationsContainer: FunctionComponent = () => {

  const [vacationsData, setVacationsData] = useState<'error' | any[]>(null);
  const [faqData, setFaqData] = useState<IFaqData[]>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const Session = useContext(UserContext)
  const { user } = Session;


    useEffect(() => {
    const fetchVacationsData = async () => {
      const fetechedData = await getVacations(user);
      const fetechedFaq = await getFaqVacations();
      setFaqData(fetechedFaq.data);
      if(!fetechedData.data[0]){
        setVacationsData('error')
      } else {
        setVacationsData(fetechedData.data[0]);
      }
    };

    fetchVacationsData();
  }, [user]);

  useEffect(() => {
    if (vacationsData && faqData) {
      setLoading(false);
    }
  }, [vacationsData, faqData]);

  return (
    <LayoutContainer>
      <VacationsView loading={loading} vacationsData={vacationsData} faqData={faqData} />
    </LayoutContainer>
  );
};

export default VacationsContainer;
