import React, { FunctionComponent } from 'react';

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import {
    CardContainer, ImageContainer, ImageBorder, Name, Role, Email, Slack, SocialContainer, EmailIcon, SlackIcon
} from './styles';


const StaffCardSkeleton: FunctionComponent = () => {

    return (
        <SkeletonTheme baseColor='rgba(85, 85, 85, 0.541)' highlightColor='rgba(85, 85, 85, 0.541)'> 
            <CardContainer>
                <ImageContainer>
                    <ImageBorder>
                        <Skeleton style={{aspectRatio: '1/1', width: '100px', borderRadius: '50%'}}/>
                    </ImageBorder>
                </ImageContainer>
                <Name>
                    <Skeleton style={{width: '150px',  height: '30px'}}  />
                </Name>
                <Role>
                    <Skeleton style={{margin: '0',  width: '80px', marginTop: '10px'}}/>
                </Role>
                <SocialContainer hasMarginTop>
                    <EmailIcon />
                    <Email>
                    <Skeleton style={{margin: '0',  width: '80px'}}/>
                    </Email>
                </SocialContainer>
                <SocialContainer>
                    <SlackIcon />
                    <Slack>
                    <Skeleton style={{margin: '0',  width: '80px'}}/>
                    </Slack>
                </SocialContainer>
                <Skeleton style={{width: '100%', height: '50px', borderRadius: '0'}} />
            </CardContainer>
        </SkeletonTheme>
    );
};

export default StaffCardSkeleton;
