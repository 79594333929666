import { AxiosResponse } from 'axios';

import { get } from 'services/api';

export async function getCalendar(): Promise<AxiosResponse> {
  const date = new Date();
  const today = date.toISOString();

  const response = await get(`calendars?_limit=10&_sort=date:ASC&_where[0][date_gt]=${today}`);
  return response;
}
