import React, { FunctionComponent, ReactNode, useState } from 'react';

import SideMenu from 'components/main/SideMenu';
import MobileMenu from 'components/main/MobileMenu';
import { Promobar } from 'components/Promobar';

import styled,  { css } from 'styled-components';
import { DESKTOP_1920, TABLET_768, TABLET_740 } from 'constants/sizes';
import { COLORS } from 'helpers/enums/colors';
import stain from 'assets/background/mancha-1.svg';
import stain2 from 'assets/background/mancha-2.svg';

interface ILayoutContainerProps {
  children: ReactNode;
  isGradient?: boolean;
  loading?: {  
    PromobarLoading: boolean,
  };
  promobar?: {
    Text: string;
    Display: boolean;
    contentType?: string;
    CTA: {
      __component: string;
      text: string;
      url: string;
      // eslint-disable-next-line camelcase
      open_new_tab: boolean;
    }
  }
}

interface IDivProps {
  isGradient?: boolean | null;
}

const Stein1 = styled.img`
  position: fixed;
  z-index: 0;
  right: -300px;
  top: -300px;
  width: 1000px;
`;

const Stein2 = styled.img`
  position: fixed;
  z-index: 0;
  left: -300px;
  bottom: 80px;
  width: 1200px;
  top: 450px;
  @media(max-width: 740px) {
    top: 600px;
  }
`;

const GlobalContainer = styled.div<IDivProps>`
  word-break: break-word;
  scroll-behavior: smooth;
  min-height: 100vh;

  ${( {isGradient} ) =>
    isGradient 
      ? css`
        background: ${({ theme }) => theme.linearGradient} linear-gradient(100deg, ${COLORS.cherry} 0%, ${COLORS.purple} 100%) 0% 0%
          no-repeat padding-box;
        `
      : css`
        background-color: ${({ theme }) => theme.mainBackgroundColor};
  `}
`;

const ViewContainer = styled.div`
  width: 100%;
  width: 90vw;
  max-width: 1920px;
  margin: auto;
  position: relative;
  display: flex;
  flex-direction: row;
  @media (max-width: ${DESKTOP_1920}px) {
    max-width: 1366px;
  }
  @media (max-width: ${TABLET_768}px) {
    position: static;
    padding-top: 85px;
    overflow-x: hidden !important;
  }
  @media screen and (max-width: ${TABLET_740}px) {
    width: 100%;
  }
  @media (max-height: 628px) {
    padding-top: 85px;
  }
`;

const MainContainer = styled.div`
  flex: 1;
  width: 80%;
  @media (max-width: ${TABLET_740}px){
    width: 100%;
  }
`;

const LayoutContainer: FunctionComponent<ILayoutContainerProps> = (
  {children, isGradient, loading, promobar}: ILayoutContainerProps,
) => {
  const [isPromobarOpen, setIsOpen] = useState<boolean>(true);
  const promobarState = (state) => setIsOpen(state);
  return (
    <GlobalContainer isGradient={isGradient}>
      {!isGradient &&
        <>
          <Stein1 src={stain} />
          <Stein2 src={stain2} />
        </>
      }
      {
        promobar.Display &&
        <Promobar loading={loading} promobar={promobar} isPromobarOpen={isPromobarOpen} promobarState={promobarState} />
      }
      <MobileMenu isPromobarDisplayed={promobar.Display} isPromobarOpen={isPromobarOpen} />
      <ViewContainer>
        <SideMenu isPromobarDisplayed={promobar.Display} isPromobarOpen={isPromobarOpen} />
        <MainContainer> {children} </MainContainer>
      </ViewContainer>
    </GlobalContainer>
  );
};

export default LayoutContainer;

LayoutContainer.defaultProps = {
  isGradient: false,
  loading: {  
    PromobarLoading: false,
  },
  promobar: {
    Text: '',
    Display: false,
    contentType: '',
    CTA: {
      __component: '',
      text: '',
      url: '/',
      // eslint-disable-next-line camelcase
      open_new_tab: true,
    }
  }
};
