/* eslint-disable no-underscore-dangle */
import React from 'react'
// eslint-disable-next-line
import ImageGallery from 'react-image-gallery';
// eslint-disable-next-line
import "react-image-gallery/styles/css/image-gallery.css";
import { ImageGalleryContainer } from './styles';
import { IGallery } from './types';

export const Gallery: React.FunctionComponent<IGallery> = ({ data }) => {
    const images = data.gallery_image.map(obj => {
        let thumbnailUrl
        if (obj.file.formats === null) {
            thumbnailUrl = obj.file.url
        } else {
            thumbnailUrl = obj.file.formats.thumbnail.url;
        }

        return {
            original: obj.file.url,
            thumbnail: thumbnailUrl,
        }
    })

    return (
        <ImageGalleryContainer>
            <ImageGallery 
                items={images}
                showFullscreenButton = {false}
                showPlayButton = {false}
                renderLeftNav = {() => <></>}
                renderRightNav = {() => <></>}
            />
        </ImageGalleryContainer>
    )
}