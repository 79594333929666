/* eslint-disable camelcase */
import React, { FunctionComponent } from 'react';
import { COLORS } from 'helpers/enums/colors';
import { MajorContainer, Text, Container, Cards, ImgCard, EnglishExtract, SpanishExtract, TipGridContainer } from './styles';
import { IEnglishTipsProps } from "./types";

const EnglishTipsCards: FunctionComponent<IEnglishTipsProps> = ({ dataList } : IEnglishTipsProps) => {
    const finalColor = (cardColor) => {  
        let backgroundColor   
        switch (cardColor) {
            case 'red':
                backgroundColor = COLORS.cherry;
            break;

            case 'purple':
                backgroundColor = COLORS.purple;
            break;

            default:
                backgroundColor = COLORS.gray;
            break;
        }
        return backgroundColor;
    }
    return (
        <MajorContainer>
            <Container>
                <Text>Estas frases te ayudaran a mejorar la comunicación con tus compañeros y clientes</Text>
                <TipGridContainer>
                    {dataList.map(( {id, body, image}  ) => {

                        if (image) return (
                            <ImgCard  bgColor={finalColor(body.background_color)} key={id}>
                                <img src={image.file.url} alt={image.file.alternativeText} />
                            </ImgCard>
                        )

                        return (
                            <Cards bgColor={finalColor(body.background_color)} key={id}>
                                <div>
                                    <SpanishExtract>
                                        {body.spanish_version}
                                    </SpanishExtract>
                                    <EnglishExtract >
                                        {body.english_version}
                                    </EnglishExtract>
                                </div>
                            </Cards>
                        )
                    })}
                </TipGridContainer>
            </Container>
        </MajorContainer>
    );
};

export default EnglishTipsCards;

EnglishTipsCards.defaultProps = {};
