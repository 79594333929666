import styled from 'styled-components';
import { DESKTOP_1200,  DESKTOP_1024, TABLET_992, TABLET_740, MOBILE_460 } from 'constants/sizes';

export const Title = styled.div`
    font: 600 38px montserrat-bold;
    color: ${({ theme }) => theme.title};
    @media (max-width: ${DESKTOP_1200}px) {
        font-size: 26px;
    }
    @media (max-width: ${DESKTOP_1024}px) {
        font-size: 22px;
    }
    @media (max-width: ${TABLET_992}px) {
        font-size: 28px;
    }
    @media (max-width: ${TABLET_740}px) {
        font-size: 32px;
    }
    @media (max-width: ${MOBILE_460}px) {
        font: 600 24px/30px montserrat-semi-bold;
    }
`
export const TextContainer = styled.div`
    max-height: 35px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
    @media (max-width: ${TABLET_992}px) {
        margin-bottom: 10px;
    }
`
