import React from 'react'
import { Link } from './styles'
import { ICallToAction } from './types'

export const CallToAction: React.FunctionComponent<ICallToAction> = ({ data }) => {
  return(
    <Link href={data.url} target={data.open_new_tab ? "_blank" : "_self"}>
      {data.text} 
    </Link>
  )
}
