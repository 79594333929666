import styled from 'styled-components';
import { MOBILE_460 } from 'constants/sizes';

export const PostViewContainer = styled.div`
  background: ${({ theme }) => theme.cardBackgroundColor} no-repeat;
  padding: 5% 10% 1%;
  border-radius: 10px;
  box-shadow: 0px 10px 20px ${({ theme }) => theme.boxShadow};
  @media (max-width: ${MOBILE_460}px) {
    border-radius: 0px;
  }
`
export const Extract = styled.div`
  width: 100%;
  color: ${({ theme }) => theme.extractCardsColor};
  font: 600 20px/24px montserrat-semi-bold;
  margin: 30px 0 40px 0;
`
export const Image = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  min-width: 100%;
  min-height: 100%;
  border-radius: 6px;
  overflow: hidden;
`