import { COLORS } from "helpers/enums/colors";

export const lightTheme = {
  backgroundBlurred: `${COLORS.homeBackgroundWhite}`,
  boxShadow: `${COLORS.boxShadow}`,
  calendarCardBackground: `${COLORS.white}`,
  cardBackgroundColor: `${COLORS.white}`,
  faqCardBackgroundColor: `${COLORS.white}`,
  textWithColorVariant: '#414141',
  dotsSliderColors: '#414141',
  extractCardsColor: `${COLORS.darkGray}`,
  globalText: `${COLORS.darkGray}`,
  mainBackgroundColor: `${COLORS.white}`,
  purpleCalendar: `${COLORS.purple}`,
  purpleCalendarDarker: `${COLORS.darkPurple}`, 
  purpleSeeMoreEmployeeCard:'#AEA9FC',
  promoTextColor: `${COLORS.white}`,
  promoBarShadow:  `${COLORS.promoDarkShadow}`,
  redCalendar: `${COLORS.cherry}`,
  redSeeAll: `${COLORS.cherry}`,
  SOSButton: `${COLORS.darkRed}`,
  SOSButtonText: `${COLORS.white}`,
  subTitleCardsColor: `${COLORS.black}`,
  title: `${COLORS.black}`,
  whiteCalendar: '#ffffff',
  purpleLink: '#7971FA',
  linearGradient: 'transparent',
  menuIcons: `grayscale(100%) brightness(0)`,
};

export const darkTheme = {
  backgroundBlurred: `${COLORS.homeBackgroundBlue}`,
  boxShadow: `${COLORS.boxlightShadow}`,
  calendarCardBackground: `${COLORS.homeBackgroundBlue}`,
  cardBackgroundColor: `${COLORS.darkBlue}`,
  textWithColorVariant: `${COLORS.white}`,
  dotsSliderColors: `${COLORS.white}`,
  faqCardBackgroundColor: `${COLORS.bluishGray}`,
  extractCardsColor: `${COLORS.white}`,
  globalText: `${COLORS.white}`,
  mainBackgroundColor: `${COLORS.black}`,
  purpleCalendar: `${COLORS.purple}`,
  purpleSeeMoreEmployeeCard:'#AEA9FC',
  promoTextColor: `${COLORS.white}`,
  promoBarShadow:  `${COLORS.promoDarkShadow}`,
  redCalendar: `${COLORS.cherry}`,
  redSeeAll: `${COLORS.cherry}`,
  SOSButton: `${COLORS.lightPurple}`,
  SOSButtonText: `${COLORS.cherry}`,
  subTitleCardsColor: `${COLORS.white}`,
  title: `${COLORS.white}`,
  purpleLink: '#7971FA',


  linearGradient: `linear-gradient(100deg, #29292963 0%, #29292963 100%) ,`,
  menuIcons: `brightness(0) invert(1)`,
};

