import React, { FunctionComponent } from 'react';

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import { singleLineExtension, strikethroughExtension } from 'components/strapiComponents/Markdown';
import MarkdownView from 'react-showdown';
import { MarkdownStyles } from './MarkdownStyles';

import { IPromobarProps } from './types';
import { PromobarContainer, PromoTitle, CloseIcon, TextContainer, ExternalLink, InternalLink } from './styles';

export const Promobar: FunctionComponent<IPromobarProps> = ({ loading, promobar, isPromobarOpen, promobarState }: IPromobarProps) => {
    const {Text, CTA}=promobar;
    const comparison = (link: string) => {
        const expression = /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/gi;
        const regex = new RegExp(expression);
        if (link.match(regex)) {
            return true;
        } 
        return false;
    }

    return (
        loading.PromobarLoading ?
            <SkeletonTheme baseColor='rgba(85, 85, 85, 0.541)' highlightColor='rgba(85, 85, 85, 0.541)'>
                <Skeleton style={{minWidth: '100vw', height: '50px', borderRadius: '0 0 0 0'}} />
            </SkeletonTheme>
        : 
            <PromobarContainer isOpen={isPromobarOpen} >
                <TextContainer>
                    <PromoTitle>
                        <MarkdownStyles>
                            <MarkdownView
                                markdown={Text}
                                extensions={[singleLineExtension, strikethroughExtension]}
                            />
                        </MarkdownStyles>
                        {
                            CTA && comparison(CTA.url) &&
                                <ExternalLink href={CTA.url} target={CTA.open_new_tab ? "_blank" : "_self"}>
                                    {CTA.text}
                                </ExternalLink>
                        }
                        {
                            CTA && !comparison(CTA.url) &&
                                <InternalLink to={CTA.url} target={CTA.open_new_tab ? "_blank" : "_self"}>
                                    {CTA.text}
                                </InternalLink>
                        }
                    </PromoTitle>
                </TextContainer>
                <CloseIcon onClick={() => {promobarState(!isPromobarOpen)}} />
            </PromobarContainer>
    )
}
