import React, { FunctionComponent, useState } from 'react';

import { MarkdownParser } from 'components/strapiComponents/Markdown';
import { IFaqAdminProps } from './types';
import { ArrowIcon, ArrowContainer, BodyContainer, FaqBox, QAContainer, QSubtitle, Text } from './styles';

const FaqAdmin: FunctionComponent<IFaqAdminProps> = (props: IFaqAdminProps) => {
	const  { dataList }  = props;
	const [activeQuestion, setActiveQuestion] = useState<number>();
	const handleClick = (i) => {
		return setActiveQuestion(activeQuestion === i ? -1 : i)
	}
	return (
		<QAContainer>
			{dataList.map(( {id, content, title, question, answer}, i) => {
				return (
					<BodyContainer onClick={() => {handleClick(i)}} key={id}>
						<FaqBox>
						<QSubtitle>
							{!!title && title}
							{!!question && question}
						</QSubtitle>
						<div >
							<Text activeQuestion={i === activeQuestion}>
								<MarkdownParser data={content} />
								<MarkdownParser data={answer} />
							</Text>
						</div>
						</FaqBox>
						<ArrowContainer>
							<ArrowIcon activeQuestion={i === activeQuestion}/>
						</ArrowContainer>
					</BodyContainer>
				)
			})}
		</QAContainer>
	);
};

export default FaqAdmin;
