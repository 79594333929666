import React, { FunctionComponent } from 'react';

import FontStyles from 'styles/font.styles';
import ResetStyles from 'styles/reset.styles';

const GlobalStyles: FunctionComponent = () => {
  return (
    <>
      <ResetStyles />
      <FontStyles />
    </>
  );
};

export default GlobalStyles;
