import styled from "styled-components";
import { DESKTOP_1440, DESKTOP_1200, DESKTOP_1024, TABLET_992, MOBILE_575, MOBILE_460, MOBILE_380 } from "constants/sizes";
import { COLORS } from "helpers/enums/colors";
import { Link } from "react-router-dom";

export const CultureTitle = styled.h2`
    margin-top: 50px;
    font: 600 38px/47px montserrat-bold;
    color: ${COLORS.black};
`
export const CalendarContainer = styled.div`
    grid-column: auto/span 18;
    max-width: 100%;
`
export const OrganizationSection = styled.div`
    margin-top: 50px;
    width: 100%;
    height: fit-content;
`
export const OrganizationContainer = styled.div`
    display: flex;
    justify-content: space-between;
`
export const ChartContainer = styled.div`
    width: 60%;
    text-align: center;
    display: grid;
    grid-column: 1/7;
`
export const CircularChart = styled.img`
    width: auto;
    height: auto;
`
export const StarterDeck = styled.div<IDivProps>`
    width: -webkit-fill-available;
    height: 100%;
    min-height: 208px;
    padding: 20px;
    border-radius: 10px;
    background-color: ${COLORS.purple};
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 10px 20px ${({ theme }) => theme.boxShadow};
    position: ${({ isPositionRelative }) => (isPositionRelative && 'relative')};
    @media (max-width: ${TABLET_992}px) {
        min-height: 160px;
    }
    @media (max-width: ${MOBILE_460}px) {
        padding: 15px;
        padding-right: 10px;
        min-height: 150px;
        place-items: center;
    }
    @media (max-width: ${MOBILE_380}px) {
        min-height: 170px;
    }
`
export const StarterDeckTitle = styled.h2`
    margin: 0;
    text-transform: uppercase;
    font: 600 51px/47px montserrat-extra-bold;
    font-size: 38px;
    line-height: 47px;
    color: ${COLORS.white};
    align-self: flex-start;
    flex-shrink: 1;
    
    @media (max-width: ${DESKTOP_1440}px) {
        font: 600 34px/36px montserrat-extra-bold;
    }
    @media (max-width: ${DESKTOP_1200}px) {
        font-size: 26px;
        line-height: 30px;
    }
    @media (max-width: ${TABLET_992}px) {
        font-size: 34px;
        line-height: 35px;
    }
    @media (max-width: ${MOBILE_575}px) {
        font-size: 32px;
    }
`
export const StarterDeckAbstract = styled.p`
    font: 400 22px/31px montserrat;
    color: ${COLORS.white};
    margin: auto 0;
    @media (max-width: ${DESKTOP_1200}px) {
        font: 400 20px/22px montserrat;
    }
    @media (max-width: ${TABLET_992}px) {
        width: 100%;
        font: 400 22px/28px montserrat;
    }
    @media (max-width: ${MOBILE_575}px) {
        font: 400 18px/22px montserrat;
    }
`
export const InnerSection = styled.div`
    width: 100%;
    height: 100%;
    padding: 0;
    margin: auto 0;
    display: flex;
`
export const TeamIcon = styled.div<IDivProps>`
    margin: ${({ isAlignedBottom }) => (isAlignedBottom ? 'auto 0 0 0' : '0 0 auto 0')};
    min-width: 80px;
    display: flex;
    align-self: center;
    margin-left: auto;
    @media (max-width: ${DESKTOP_1440}px) {
        min-width: 75px;
        justify-content: right;
    }
`
export const TeamImage = styled.img`
    @media (max-width: ${MOBILE_575}px) {
        height: 80px;
        width: auto;
    }
    @media (max-width: ${MOBILE_380}px) {
        height: 70px;
    }
`
export const CultureSuggestions = styled(StarterDeck)`
    background: transparent linear-gradient(100deg, ${COLORS.purple} 0%, ${COLORS.cherry} 100%) 0% 0%
        no-repeat padding-box;
    box-shadow: 0px 10px 20px ${({ theme }) => theme.boxShadow};
    flex-direction: row;
    grid-column: auto/span 9;
    @media (max-width: ${DESKTOP_1024}px) {
        flex-direction: column;
    }
    @media (max-width: ${MOBILE_460}px) {
        flex-direction: column;
    }
`
export const SuggestionsTitle = styled(StarterDeckTitle)`
    width: 30%;
    align-self: center;
    @media (max-width: ${DESKTOP_1024}px) {
        width: 100%;
        padding: 0;
    }
    @media (max-width: ${MOBILE_460}px) {
        width: 95%;
        align-self: flex-start;
    }
`
export const SuggestionsAbstract = styled(StarterDeckAbstract)`
    width: 70%;
    padding: 20px 10px 0 0;    
    align-self: center;
    display: flex;
    flex-direction: row;
    @media (max-width: ${DESKTOP_1024}px) {
        width: 100%;
        padding: 10px;
    }
    @media (max-width: ${MOBILE_460}px) {
        display: flex;
        flex-direction: column;
    }
`
export const Abstract = styled.div`
    width: 70%;
    margin: auto 0;
    @media (max-width: ${DESKTOP_1024}px) {
        align-self: left;
    }
    @media (max-width: ${MOBILE_575}px) {
        width: 80%;
    }
    @media (max-width: ${MOBILE_460}px) {
        width: 100%;
    }
`
export const SuggestionsLink = styled.a`
    font: 400 22px/31px montserrat;
    color: ${COLORS.white};
    padding-right: 14px;
    width: fit-content;
    margin-top: 10px;
    font-weight: bold;
    text-decoration: none;
    display: block;
    > span {
        font: 600 20px/25px montserrat-semi-bold;
        margin-bottom: 0.5px;
        border-bottom: 2px white solid;
    }
    &:focus {
        outline: none;
    }
`
export const SuggestionsImage = styled.div`
    margin-left: auto;
`
export const SendImage = styled.img`
    display: flex;
    align-self: center;
    width: 100%;
    height: auto;
    width: 300px;
    @media (max-width: ${DESKTOP_1440}px) {
        width: 250px;
    }
    @media (max-width: ${DESKTOP_1024}px) {
        width: 150px;
    }
    @media (max-width: ${MOBILE_460}px) {
        width: 60%;
    }
`
export const CardLink = styled.a`
    text-decoration: none;
    width: 100%;
    grid-column: auto/span 3;
    &:focus {
        outline: none;
    }
`
export const PeopleSliderContainer = styled.div`
    grid-column: 7/10;
`
export const InternalLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;
    }
    &:focus {
        outline: none;
    }
`

export const NewNotificationContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
  position: absolute;
  right: 0;
  top: 62px;

  @media (max-width: ${TABLET_992}px) {
    top: 22px;
    }
`

export const NotificationText = styled.div`
  text-align: center;
  width: 20%;
  min-width: 51px;
  font-weight: bold;
  background-color: red;
  color: white;
  padding: 4px 0;
`

interface IDivProps {
    isAlignedBottom?: boolean | null;
    isPositionRelative?: boolean | null;
}