export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;

export const GTM_ID = process?.env?.REACT_APP_GTM_ID || 'GTM-MWJN27DS';

export const FIRASEBASE_CONFIG = {
  apiKey: process.env.FIREBASE_API_KEY || 'AIzaSyCu4NW3O_JYCked0g6jI-t18NqFyb6E2E8', // not secret
  authDomain: 'mypsh-portal.firebaseapp.com',
  projectId: 'mypsh-portal',
  storageBucket: 'mypsh-portal.appspot.com',
  messagingSenderId: '170229337122',
  appId: '1:170229337122:web:e1ae1350ede4c461ab4e7b',
};
