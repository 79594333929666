import { AxiosResponse } from 'axios';

import { get } from 'services/api';

export async function getSearchResults(
  textQuery: string,
  contentType?: string
): Promise<AxiosResponse> {

  const categoryQuery = contentType ? `category=${contentType}` : ``;
  
  const response = await get(`search/?_q=${textQuery}&${categoryQuery}`);
  return response;
}

export async function getPostBySlug(slug: string): Promise<AxiosResponse> {
  const response = await get(`friendly-reminders?slug=${slug}`);
  return response;
}
