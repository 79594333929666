/* eslint-disable */

import React from 'react'
import MarkdownView from 'react-showdown'
import { IRichText } from './types'

import { MarkdownStyles } from './styles'

export const singleLineExtension = {
  type: 'lang',
  regex: /\n\n/g,
  replace: ' '
}
export const strikethroughExtension = {
  type: 'lang',
  regex: /~(.*)~/g,
  replace: '<s>$1</s>'
}

export const MarkdownParser: React.FunctionComponent<IRichText> = ({ data }) => {

  const targetBlankExtension = {
    type:   'output',
    regex: '<a(.*?)>',
    replace: function (match, content) {
      if(content.indexOf('href="https://my.wearepsh.com') === -1){
        return content.indexOf('mailto:') !== -1 ? '<a' + content + '>' : '<a target="_blank"' + content + '>';
      } else {
        return '<a target="_self"' + content + '>';
      }
    }
  }

  return (
		<MarkdownStyles>
			<MarkdownView
				markdown={data}
				extensions={[strikethroughExtension, targetBlankExtension]}
			/>
		</MarkdownStyles>
  )
}
