import { COLORS } from 'helpers/enums/colors'
import styled from 'styled-components'

// CSS is a Markdown template taken (and slightly edited) from: https://github.com/jasonm23/markdown-css-themes;
// Themes Preview here: https://jasonm23.github.io/markdown-css-themes/
// Also checkout: https://github.com/markdowncss/modest (specialy for lists)

const MarkdownBaseStyle = styled.div`
	em {
		font-style: italic;
	}
	strong {
		font-weight: bold;
	}

	ol                         { list-style-type: decimal; }
	ol ol                      { list-style-type: lower-alpha; }
	ol ol ol                   { list-style-type: lower-roman; }
	ol ol ol ol                { list-style-type: decimal; }
	ol ol ol ol ol             { list-style-type: lower-alpha; }
	ol ol ol ol ol ol          { list-style-type: lower-roman; }
	ol ol ol ol ol ol ol       { list-style-type: decimal; }
	ol ol ol ol ol ol ol ol    { list-style-type: lower-alpha; }
	ol ol ol ol ol ol ol ol ol { list-style-type: lower-roman; }

	ul                         { list-style-type: disc; }
	ul ul                      { list-style-type: circle; }
	ul ul ul                   { list-style-type: square; }
	ul ul ul ul                { list-style-type: disc; }
	ul ul ul ul ul             { list-style-type: circle; }
	ul ul ul ul ul ul          { list-style-type: square; }
	ul ul ul ul ul ul ul       { list-style-type: disc; }
	ul ul ul ul ul ul ul ul    { list-style-type: circle; }
	ul ul ul ul ul ul ul ul ul { list-style-type: square; }

`

export const MarkdownStyles = styled(MarkdownBaseStyle)`
	body {
		font-family: montserrat;
		font-size: 14px;
		color: ${({ theme }) => theme.globalText};
		line-height: 24px;
		padding-top: 10px;
		padding-bottom: 10px;
		padding: 30px; 
    }

	body > *:first-child {
    	margin-top: 0 !important;
	}
	body > *:last-child {
    	margin-bottom: 0 !important; 
	}

	a {
		color: ${COLORS.purple};
		text-decoration: none;
			strong{
				color: inherit;
			}
		:hover{
			text-decoration: underline;
		}	
	}
	
	a.anchor {
		display: block;
		padding-left: 30px;
		margin-left: -30px;
		cursor: pointer;
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0; 
	}

	h2, h3, h4 {
		margin: 20px 0 10px;
		padding: 0;
		font-family: montserrat-semi-bold;
		-webkit-font-smoothing: antialiased;
		cursor: text;
		position: relative; 
	}
	
	h1:hover a.anchor, h2:hover a.anchor, h3:hover a.anchor, h4:hover a.anchor{
    	text-decoration: none; 
	}

	h1 tt, h1 code {
    	font-size: inherit; 
	}

	h2 tt, h2 code {
   		font-size: inherit; 
	}

	h3 tt, h3 code {
    	font-size: inherit; 
	}

	h4 tt, h4 code {
    	font-size: inherit; 
	}

	h2 {
		font-size: 30px;
		border-bottom: 1px solid ${COLORS.lightPurple};
		color: ${({ theme }) => theme.globalText};
	}

	h3 {
    	font-size: 26px;
		color: ${({ theme }) => theme.globalText};
	}

	h4 {
    	font-size: 24px;
		color: ${({ theme }) => theme.globalText};
	}

	p, ul, ol, dl, li, table, pre {
    	margin: 15px 0; 
		color: ${({ theme }) => theme.globalText};
	}

	p > strong {
		color: ${COLORS.cherry};
	}

	hr {
		border: 0 none;
		color: ${COLORS.lightPurple};
		height: 4px;
		padding: 0;
	}

	body > h2:first-child {
		margin-top: 0;
		padding-top: 0; 
	}
	body > h1:first-child {
		margin-top: 0;
		padding-top: 0; 
	}
	body > h1:first-child + h2 {
		margin-top: 0;
		padding-top: 0; 
	}
	body > h3:first-child, body > h4:first-child, body > h5:first-child, body > h6:first-child {
		margin-top: 0;
		padding-top: 0; 
	}
	a:first-child h1, a:first-child h2, a:first-child h3, a:first-child h4, a:first-child h5, a:first-child h6 {
		margin-top: 0;
		padding-top: 0; 
	}
	h2 p, h3 p, h4 p{
    	margin-top: 0; 
	}
	li p.first {
    	display: inline-block; 
	}
	p {
		font-size: 16px;
		line-height: 22px;
	}
	li {
		font-size: 14px;
		line-height: 22px;
    	margin: 0;
		margin-top: 10px;
		color: ${({ theme }) => theme.globalText};
		strong {
			color: ${COLORS.cherry}
		}
		a{
			color: ${COLORS.purple};
			strong{
				color: inherit;
			}
		}
		::marker{
			color: ${COLORS.cherry};
		}
	}

	ul, ol {
    	padding-left: 30px; 
	}
	ul :first-child, ol :first-child {
    	margin-top: 0; 
	}

	dl {
    	padding: 0; 
	}
	dl dt {
		font-size: 14px;
		font-weight: bold;
		font-style: italic;
		padding: 0;
		margin: 15px 0 5px;
	}
	dl dt:first-child {
    	padding: 0;
	}
	dl dt > :first-child {
    	margin-top: 0;
	}
	dl dt > :last-child {
    	margin-bottom: 0;
	}
	dl dd {
		margin: 0 0 15px;
		padding: 0 15px; }
	dl dd > :first-child {
    	margin-top: 0;
	}
	dl dd > :last-child {
    	margin-bottom: 0; 
	}

	blockquote {
		margin: 10px 0 10px 20%;
		padding-left: 2%;
		border-left: 2px solid ${COLORS.cherry}; 
	}
	blockquote > p {
		color: ${COLORS.cherry};
	}

	table {
    	padding: 0;border-collapse: collapse; 
	}
	table tr {
		border-top: 1px solid ${COLORS.lightPurple};
		background-color: #33333312;
		margin: 0;
		padding: 0; 
	}
	table tr:nth-child(2n) {
    	background-color: #33333312;
	}
	table tr th {
		font-weight: bold;
		border: 1px solid ${COLORS.lightPurple};
		margin: 0;
		padding: 6px 13px; 
	}
	table tr td {
		border: 1px solid ${COLORS.lightPurple};
		margin: 0;
		padding: 6px 13px; 
	}
	table tr th :first-child, table tr td :first-child {
    	margin-top: 0; 
	}
	table tr th :last-child, table tr td :last-child {
    	margin-bottom: 0; 
	}

	span.frame {
		display: block;
		overflow: hidden; 
	}
	span.frame > span {
		border: 1px solid ${COLORS.white};
		display: block;
		float: left;
		overflow: hidden;
		margin: 13px 0 0;
		padding: 7px;
		width: auto; 
	}
	span.frame span img {
		display: block;
		float: left; 
  	}
	span.frame span span {
		clear: both;
		color: #333333;
		display: block;
		padding: 5px 0 0; 
  	}
	code, tt {
		margin: 0 2px;
		padding: 0 5px;
		white-space: nowrap;
		border: 1px solid #eaeaea;
		background-color: #f8f8f8;
		border-radius: 3px; 
  	}

	pre code {
		margin: 0;
		padding: 0;
		white-space: pre;
		border: none;
		background: transparent; 
	}

	.highlight pre {
		background-color: #f8f8f8;
		border: 1px solid ${COLORS.lightPurple};
		font-size: 13px;
		line-height: 23px;
		overflow: auto;
		padding: 6px 10px;
		border-radius: 3px; 
	}

	pre {
		background-color: #f8f8f8;
		border: 1px solid ${COLORS.lightPurple};
		font-size: 13px;
		line-height: 23px;
		overflow: auto;
		padding: 6px 10px;
		border-radius: 3px; 
	}
	pre code, pre tt {
		background-color: transparent;
		border: none; 
	}

	sup {
		font-size: 0.83em;
		vertical-align: super;
		line-height: 0;
	}
	* {
      -webkit-print-color-adjust: exact;
	}
	@media screen and (min-width: 914px) {
		body {
			width: 854px;
			margin:0 auto;
		}
	}
	@media print {
		table, pre {
			page-break-inside: avoid;
		}
		pre {
			word-wrap: break-word;
		}
	}
`
