import React, { FunctionComponent, useState, useEffect, useCallback } from 'react';

import { Tooltip } from '@mui/material';

import { TEAMS } from 'helpers/enums/pshTeams';

import EmployeeCard from 'components/employeeCard';
import Input from 'components/inputs/Input'
import BackButton from 'components/BackButton';
import EmployeeDropdown from 'components/EmployeeDropdown';
import { SearchNotFound } from 'components/SearchNotFound';

import StaffCardSkeleton from 'components/employeeCard/skeleton';

import { Background, SectionName, Title, TopContainer } from 'styles/commons';
import { IEmployeesDirectoryProps } from './types';

import { ViewContainer, CardsGrid, FilterSection, OptionsSection, OptionsRow, Option, FilterTitle, SeeAll, NotFoundDiv } from './styles';

const EmployeesDirectoryView: FunctionComponent<IEmployeesDirectoryProps> = ({loading, employeesInfo}: IEmployeesDirectoryProps) => {

  const [filterOption, setFilterOption] = useState<string>("");  
  const [clickSearch, setOnClickSearch] = useState<boolean>(false);
  const [filterEmployees, setFilterEmployees] = useState(employeesInfo);
  const [visibleID, setVisibleId] = useState<string>("-1");
  const [textQuery, setTextQuery] = useState<string>('');
  const [textSearchInput, setTextSearchInput] = useState<string>('');

  const filterFunction = useCallback(
    () => {
      let filteredResults = employeesInfo;
      filteredResults = filteredResults.filter(employee => employee.complete_name.toLowerCase().includes(textQuery.toLowerCase()));
      if (filterOption !== 'SOS') {
        return filterOption !== "" ? setFilterEmployees(filteredResults.filter(employee => employee.team?.toLowerCase() === filterOption.toLowerCase())) : setFilterEmployees(filteredResults);
      }
      return setFilterEmployees(filteredResults.filter(employee => employee.phone_number != null));
    },
    [employeesInfo, filterOption, textQuery],
  );

  const OnclickCard = (employeeId: string) => {
    if (employeeId === visibleID) {
      setVisibleId("-1");
    } else {
      setVisibleId(employeeId);
    }
  }

  const OnClickOption = (filterName) => {
    setFilterOption(filterName);
  }

  const meta = {
    touched: false,
    error: 'string',
  };

  const input = {
    onChange: (text: any) => {
      setTextSearchInput(text)
      setTextQuery(text)
      setOnClickSearch(!clickSearch);
    },
    value: textSearchInput,
  };

  useEffect(() => {
    filterFunction();
  }, [filterOption, clickSearch,filterFunction]);

  const TeamSpecificName = (key) => {
    const team = TEAMS[key];
    const teamName = team.team;
    let teamNameConverter = teamName;
    
    if (teamName === 'PM/SM') {
      teamNameConverter = 'AM';
    } else if (teamName === 'SOS') {
      teamNameConverter = 'S.O.S';
    } return (
        <Tooltip title={team.TeamCompleteName} placement="top">
          <Option onClick={()=>OnClickOption(team.team)} key={team.team} filter={team.team} isSelected={filterOption === team.team}>
            {teamNameConverter}
          </Option>
        </Tooltip>
      );
  };

  return (
    <ViewContainer data-testid="home-view">
      <Background hasMarginBottom>
        <TopContainer>
          <BackButton />
          <SectionName>PSH TEAM</SectionName>
        </TopContainer>
        <Title>Staff Directory</Title>
        <Input variant="filled" input={input} meta={meta}/>
        <FilterTitle> Filtra por team: </FilterTitle>
        <FilterSection>
          <OptionsSection>
            <OptionsRow>
              {Object.keys(TEAMS).map(TeamSpecificName)}
              <SeeAll onClick={()=>OnClickOption("")}>
                VER TODO
              </SeeAll>
            </OptionsRow>
          </OptionsSection>
        </FilterSection>
        <EmployeeDropdown OnClickOption={OnClickOption} filterOption={filterOption} />
        {
          !loading ?
            (filterEmployees.length > 0) && 
              <CardsGrid>           
                {filterEmployees.map((employee)=> {                  
                  return (
                    <EmployeeCard employeesInfo={employee} key={employee.email} onClick={() => OnclickCard(employee.id)} visibleId={visibleID}/>
                  )
                })}
              </CardsGrid>
          :
            <CardsGrid>           
              <StaffCardSkeleton />
              <StaffCardSkeleton />
              <StaffCardSkeleton />
              <StaffCardSkeleton />
              <StaffCardSkeleton />
              <StaffCardSkeleton />
              <StaffCardSkeleton />
              <StaffCardSkeleton />
            </CardsGrid>
        }
        {
          !loading && (filterEmployees.length === 0) &&
            <NotFoundDiv>
              <SearchNotFound widthSize={70} />
            </NotFoundDiv>                
        }
      </Background>
    </ViewContainer>
  );
};
export default EmployeesDirectoryView;
