import React, { FunctionComponent } from 'react';

import { IToggleButtonProps } from './types';

import { Ball, Checkbox, Label, Moon, Sun } from './styles';

import  MoonIcon from './assets/moon-white.png';
import  SunIcon from './assets/sun.png';

const ToggleButton: FunctionComponent<IToggleButtonProps> = (props: IToggleButtonProps) => {
    const { onClick, isDarkTheme } = props;
    return (
    <>
        <Checkbox onClick={onClick} isDarkTheme={isDarkTheme}>
            <Label >
                <Moon isDarkTheme={isDarkTheme} src={MoonIcon}/>
                <Sun isDarkTheme={isDarkTheme} src={SunIcon} />
                <Ball isDarkTheme={isDarkTheme} />
            </Label>
        </Checkbox>
    </>
  )
}
export default ToggleButton;
