import React, { FunctionComponent } from 'react';

import Vacations from 'components/Vacations';
import BackButton from 'components/BackButton';

import { Background, SectionName, Title, TopContainer } from 'styles/commons';
import { Container } from './styles';

import { IVacationsProps } from './types';

const VacationsView: FunctionComponent <IVacationsProps> = ({ loading, vacationsData, faqData }: IVacationsProps) => {

  return (
    <Container>
      <Background hasMarginBottom>
        <TopContainer>
          <BackButton />
          <SectionName>CALENDAR</SectionName>
        </TopContainer>
        <Title>PTO</Title>
        <Vacations loading={loading} vacationsData={vacationsData} faqData={faqData} />
      </Background>
    </Container>
  )
}
export default VacationsView;
