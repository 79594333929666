import styled, { css } from "styled-components";

import { COLORS } from "helpers/enums/colors";
import { DESKTOP_1200, TABLET_1023, TABLET_600 } from "constants/sizes";

const TabletBreak = TABLET_1023;
const MobileBreak = TABLET_600;

export const CommonStyle = styled.div`
  border-radius: 10px;
`
export const QAContainer = styled(CommonStyle)`
  margin-top: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: transparent;
`

export const Subtitle = styled.h6`
  font: 500 18px/40px 'montserrat';
  color: ${({ theme }) => theme.textWithColorVariant};

  @media (max-width: ${MobileBreak}px) {
    font: 500 18px/26px 'montserrat';
  }
`

export const ErrorMessage = styled.h6`
  text-align: center;
  padding: 15px 0;
  font: 700 25px/40px 'montserrat';
  color: ${({ theme }) => theme.redCalendar};

  @media (max-width: ${MobileBreak}px) {
    font: 500 18px/26px 'montserrat';
  }

  a{
    color: ${COLORS.purple};
    strong{
      color: inherit;
    }
  }

`

export const GridContainer = styled(CommonStyle)`
  width: 100%;
  height: 220px;
  margin: 20px 0;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
  background-color: transparent;
  box-sizing: border-box;

  @media (max-width: ${TabletBreak}px) {
    height: 420px;
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: ${MobileBreak}px) {
    height: auto;
    grid-template-columns: repeat(1, 1fr);
  }
`
export const Box = styled.div<IDivProps>`
  box-sizing: border-box;
  background: ${({ theme }) => theme.cardBackgroundColor};
  box-shadow: 0px 10px 20px #010F401A;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  box-shadow: rgb(1 15 64 / 10%) 0px 10px 20px;
  position: relative;
  overflow: hidden;
  -webkit-box-pack: justify;
  justify-content: space-between;
`

export const MainText = styled.div`
  padding: 20px 18px 0px;

  @media (max-width: ${DESKTOP_1200}px) {
    padding: 15px 0px 14px;
  }
  @media (max-width: ${TabletBreak}px) {
    font: bold 18px/30px 'montserrat-bold';
  }
`
export const BoxTitle = styled.h3`
  color: ${({ theme }) => theme.subTitleCardsColor};
  font: bold 20px/28px 'montserrat-bold';
  text-align: center;

  @media (max-width: ${DESKTOP_1200}px) {
    font: bold 16px/24px 'montserrat-bold';
  }
  @media (max-width: ${TabletBreak}px) {
    font: bold 20px/28px 'montserrat-bold';
  }
`
export const Days = styled.p<IDivProps>`
  width: 100%;
  height: 120px;
  text-align: center;
  font: bold 60px/24px 'montserrat-bold';
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.textWithColorVariant};

  @media (max-width: ${DESKTOP_1200}px) {
    height: 130px;
  }
  @media (max-width: ${TabletBreak}px) {
    height: 108px;
  }
`
export const RemainingDays = styled(Days)<IDivProps>`
  color: ${({colorText}) => colorText ? COLORS.purple : `#7F7F7F`};
`
export const CurrentPeriod = styled.p`
  height: 120px;
  color: ${({ theme }) => theme.textWithColorVariant};
  font: normal 20px/36px 'montserrat';
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  & span {
    font: bold 26px/24px 'montserrat-bold';
  }

  @media (max-width: ${DESKTOP_1200}px) {
    height: 130px;
  }
  @media (max-width: ${TabletBreak}px) {
    height: 108px;
  }
`
export const MoreInfo = styled.div`
  width: 100%;
  height: 40px;
  background-color: ${({ theme }) => theme.purpleSeeMoreEmployeeCard};
  display: flex;
  align-items: center;
  border-radius: 0 0 9px 9px;
  cursor: pointer;

  & p {
    width: 100%;
    text-align: center;
    font: 600 12px/24px 'montserrat-semi-bold';
    color: ${COLORS.white};
    text-transform: uppercase;
  }
`

export const CollapsibleContainer = styled.div<IDivProps>`
  position: absolute;
  height: 0;
  top: 232px;
  background-color: ${({ theme }) => theme.purpleSeeMoreEmployeeCard};
  z-index: -1;
  transition: height 0.2s ease-out;

  ${({ isOpen }) =>
    isOpen
      && css`
        background-color: ${COLORS.purple};
        top: 55px;
        z-index: 3;
        width: 100%;
        height: 100%;
        transition: top 0.3s linear;
      `
  }
`;
export const CollapsibleContent = styled.div<IDivProps>`
`;

export const InfoCollapsible = styled.p<IDivProps>`
  padding: 18px;
  font: 600 14px/18px 'montserrat-semi-bold';
  color: ${COLORS.white};
`;

export const SeeMoreButton = styled.div<IDivProps>`
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  background-color: ${({isOpen, theme}) => isOpen ? COLORS.purple : theme.purpleSeeMoreEmployeeCard};
  z-index: 4;
  position: relative;
  top: 1px;
  cursor: pointer;
  align-items: center;
  border-radius: 0 0 9px 9px;

  & p {
    width: 100%;
    text-align: center;
    font: 600 12px/24px 'montserrat-semi-bold';
    color: ${COLORS.white};
    text-transform: uppercase;
  }
`;

export const ArrowImg = styled.img`
  width: 18px;
  height: 14px;
  margin-top: 4px;
  filter: brightness(0) invert(1);
  transform: rotate(180deg);
`;

interface IDivProps {
  activeBox?: boolean | null;
  isOpen?: boolean;
  colorText?: boolean;
}
