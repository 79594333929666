import React from 'react'
import ReactPlayer from 'react-player'
import { ImageContainer, VideoContainer, StandardVideo } from './styles'
import { IVideo } from './types'

export const Video: React.FunctionComponent<IVideo> = ({ data }) => {

  return (
    <ImageContainer>
      <VideoContainer>
        <StandardVideo>
          <ReactPlayer
            url={data.url}
            controls
          />
        </StandardVideo>
      </VideoContainer>
    </ImageContainer>
  )
}