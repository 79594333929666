import { AxiosResponse } from 'axios';
import { get } from 'services/api';

export async function getFaq(): Promise<AxiosResponse> {
  const response = await get('faq-admins?_sort=published_at:desc');
  return response;
}

export async function getFaqVacations(): Promise<AxiosResponse> {
  const response = await get('faq-vacations?_sort=published_at:desc');
  return response;
}
