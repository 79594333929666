import React, { FunctionComponent } from 'react';
import StrapiBody from 'components/strapiComponents/StrapiBody';
import BackButton from 'components/BackButton';
import { Background, SectionName, Title, TopContainer } from 'styles/commons';
import { HomeContainer } from '../HomeView/styles';
import { IPostView } from './types';
import { Image, Extract, PostViewContainer } from './styles';

const PostView: FunctionComponent <IPostView>=(props: IPostView) => {
  const { data, isLoading, contentType } = props;
  return (
    <HomeContainer>
      <Background hasMarginBottom paddingMobile='15px 0 25px'>
        <TopContainer>
          <BackButton />
        </TopContainer>
        <PostViewContainer>
          <SectionName isAlignedLeft>{contentType==="wellness" ? "Wellness" : "WELCOME!"}</SectionName>
          <Title isAlignedLeft>{data.title}</Title>
          <Extract>{data.extract}</Extract>
          <Image src={data.cover_image?.url} />
          <StrapiBody data={data} isLoading={isLoading}/>
        </PostViewContainer>
      </Background>
    </HomeContainer>
  )
}
export default PostView;
