export const TEAMS = {
    ENG: { team: 'ENG', TeamCompleteName: 'ENGINEERING' },
    QA: { team: 'QA', TeamCompleteName: 'QUALITY ASSURANCE' },
    DESIGN: { team: 'DESIGN', TeamCompleteName: '' },
    MOTION: { team: 'MOTION', TeamCompleteName: '' },
    MKT: { team: 'MKT', TeamCompleteName: 'MARKETING' },
    'PM/SM': { team: 'PM/SM', TeamCompleteName: 'Accounting Management' },
    PEOPLE: { team: 'PEOPLE', TeamCompleteName: '' },
    CONTENT: { team: 'CONTENT', TeamCompleteName: '' },
    PARTNERS: { team: 'PARTNERS', TeamCompleteName: '' },
    SOS: { team: 'SOS', TeamCompleteName: '' },
};
  