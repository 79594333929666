import styled from 'styled-components';
import { Link } from "react-router-dom";
import { COLORS } from 'helpers/enums/colors';
import { DESKTOP_1200, TABLET_992, TABLET_740, MOBILE_575, MOBILE_460 } from 'constants/sizes';

export const Container = styled.div`
  background: ${COLORS.white};
  padding: 2%;
`;

export const PageContainer = styled.div`
  width: 85%;
`;

export const InternalWrapper = styled.div`
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;

  @media (max-width: 1024px) {
    max-width: 90%;
  }

  @media (max-width: 992px) {
    max-width: 90%;
    flex: 1;
  }
`;

export const Background = styled.div<IDivProps>`
  border-radius: 10px;
  padding: 20px 30px 30px 30px;
  opacity: 1;
  background-color: ${({ theme }) => theme.backgroundBlurred};
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(7px);
  box-shadow: 0px 10px 20px ${({ theme }) => theme.boxShadow};
  margin-top: ${({ marginTop }) => marginTop};
  margin-bottom: ${({ hasMarginBottom }) => hasMarginBottom && '30px'};
  position: relative;
  top: 20px;
  @media (max-width: ${TABLET_740}px) {
    grid-gap: ${({ gapSize }) => gapSize};
    border-radius: 0;
  }
  @media (max-width: ${MOBILE_575}px) {
    margin-top: ${({ marginTopMobile }) => marginTopMobile};
    padding: 20px 15px 15px 15px;
  }
  @media (max-width: ${MOBILE_460}px) {
    padding: ${({ paddingMobile }) => paddingMobile};
  }
`;

export const Title = styled.h1<IDivProps>`
  width: 100%;
  margin: 20px auto;
  font: 600 33px/47px montserrat-extra-bold;
  text-align:${({ isAlignedLeft }) => (isAlignedLeft ? 'left' :  'center')};
  color: ${({ theme }) => theme.title};
  margin-bottom: ${({ hasMarginBottom }) => hasMarginBottom && '30px'};
  @media (max-width: ${MOBILE_575}px) {
    font: 600 30px/34px montserrat-extra-bold;
  }
`;

export const SecondaryTitle = styled.h2<IDivProps>`
  font: 600 38px/46px montserrat-bold;
  color: ${({ theme }) => theme.title};
  @media (max-width: ${TABLET_740}px) {
    font: 600 32px/36px montserrat-bold;
  }
  @media (max-width: ${MOBILE_460}px) {
    width: 70%;
    font: 600 24px/30px montserrat-semi-bold;
  }
`;

export const SectionName = styled.div<IDivProps>` // this is used for the name of the section in the detail view
  width: calc(100% - 140px);
  display: flex;
  font: 600 14px/18px montserrat-semi-bold;
  text-transform: uppercase;
  color: ${({ theme }) => theme.redSeeAll};
  justify-content: ${({ isAlignedLeft }) => (isAlignedLeft ? 'left' :  'center')};
`;

export const SeeAll = styled(Link)` // this is used for the 'ver todo'
  color: ${COLORS.purple};
  text-transform: uppercase;
  align-self: flex-end;
  font: 600 14px/18px montserrat-semi-bold;
  text-decoration: none;
  @media (max-width: ${DESKTOP_1200}px) {
    font: 600 12px/16px montserrat-semi-bold;
  }
  &:focus {
    outline: none;
  }
`;

export const SectionNamePostView = styled(SectionName)<IDivProps>` // this is used for the name of the section in the search view
  color: ${({contentColor}) => { return contentColor}};
`;

export const TeamTitle = styled.h2<IDivProps>`  // this is used for the name of the section in the home view
  font: 600 20px/24px montserrat-bold;
  margin-bottom: 18px;
  color:${({ isPurple }) => (isPurple ? COLORS.purple :  COLORS.cherry)};
  text-transform: uppercase;
  align-self: flex-start;
`;

export const TopContainer = styled.div`
  display: flex;
`;

export const GridContainer = styled.div<IDivProps>`
  display: grid;
  grid-template-columns: repeat(18, 1fr);
  grid-gap: 30px;
  margin-top: ${({ marginTop }) => marginTop};
  margin-bottom: ${({ marginBottom }) => marginBottom && marginBottom};
  @media (max-width: ${TABLET_740}px) {
    grid-gap: ${({ gapSize }) => gapSize};
  }
  @media (max-width: ${MOBILE_575}px) {
    margin-top: ${({ marginTopMobile }) => marginTopMobile};
  }
`;

export const GridItem = styled.div<IDivProps>`
  display: grid;
  grid-column: auto/span ${({ spanSize }) => spanSize && spanSize};
  padding: ${({ padding }) => padding};
  @media (max-width: ${TABLET_992}px) {
    grid-column: auto/span ${({ spanSizeTablet }) => spanSizeTablet && spanSizeTablet};
    grid-column-start: ${({ startColumn }) => startColumn && startColumn};
    display: ${({ notDisplayTablet }) => notDisplayTablet && 'none'};
  }
  @media (max-width: ${TABLET_740}px) {
    grid-column: auto/span ${({ spanSizeMobile }) => spanSizeMobile && spanSizeMobile};
    grid-gap: ${({ gapSize }) => gapSize};
  }
  @media (max-width: ${MOBILE_460}px) {
    grid-column: auto/span ${({ spanSizeMobile }) => spanSizeMobile && spanSizeMobile};
    display: ${({ notDisplayMobile }) => notDisplayMobile && 'none'};
  }
`;

interface IDivProps {
  hasMarginTop?: boolean | null;
  hasMarginBottom?: boolean | null;
  marginTop?: string | null;
  marginTopMobile?: string | null;
  gapSize?: string | '20px';
  marginBottom?: string | null;
  padding?: string | null;
  paddingMobile?: string | null;
  notDisplayMobile?: boolean | null;
  notDisplayTablet?: boolean | null;
  spanSize?: number | null;
  spanSizeTablet?: number | null;
  spanSizeMobile?: number | null;
  startColumn?: number | null;
  contentColor?: string | null;
  isPurple?: boolean | null;
  isAlignedLeft?: boolean | null;
}