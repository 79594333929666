import styled from "styled-components";
import { DESKTOP_1024, DESKTOP_1200, DESKTOP_1440, DESKTOP_1920, MOBILE_460, TABLET_768, TABLET_992 } from "constants/sizes";
import { COLORS } from "helpers/enums/colors";

export const MajorContainer = styled.div`
    text-align: center;
`
export const Text = styled.div`
    margin-bottom: 20px;
    font: normal 22px/31px montserrat;
    color: ${({ theme }) => theme.title};
    text-align: left;
    word-wrap: break-word;

    @media (max-width: ${DESKTOP_1440}px) {
        font-size: 18px;
        line-height: 22px;
    }
`
export const Container = styled.div`
    width: fit-content;
    margin: auto;
`
export const TipGridContainer = styled.div`
    grid-template-columns: repeat(4, 1fr);
    display: grid;
    grid-gap: 30px 30px;
    @media (max-width: ${DESKTOP_1920}px) {
        grid-template-columns: repeat(3, 1fr);
    }
    @media (max-width: ${DESKTOP_1024}px) {
        grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: ${TABLET_992}px) {
        grid-gap: 30px 20px;
        justify-items: center;
    }
    @media (max-width: ${TABLET_768}px) {
        grid-template-columns: 1fr;
        justify-items: center;
    }
`
export const Cards = styled.div<ICardProps>`
    padding: 25px;
    border-radius: 15px;
    width: 350px;
    height: 350px;
    overflow: hidden;
    box-shadow: 0px 10px 20px ${({ theme }) => theme.boxShadow};
    background-color: ${({ bgColor }) => bgColor};
    box-sizing: border-box;
    text-align: left;
    @media (max-width: ${DESKTOP_1440}px) {
        width: 280px;
        height: 280px;
    }
    @media (max-width: ${DESKTOP_1200}px) {
        padding: 15px;
        width: 240px;
        height: 240px;
    }
    @media (max-width: ${DESKTOP_1024}px) {
        padding: 20px;
        width: 280px;
        height: 280px;
    }
    @media (max-width: ${TABLET_768}px) {
        height: fit-content;
        max-height: 280px;
        min-height: 250px;
        max-width: 300px;
        justify-items: center;
    }
    @media (max-width: ${MOBILE_460}px) {
        max-height: 100%;
        min-height: 200px;
        max-width: 350px;
    }
`
export const ImgCard = styled(Cards)`
    padding: 0px;
    @media (max-width: ${TABLET_768}px) {
        height: 280px;
    }
    & > img {
        object-fit: cover;
        box-sizing: border-box;
        width:100%;
        height:100%;
    }
`

export const SpanishExtract = styled.div`
    font: normal 22px/30px montserrat;
    color: ${COLORS.white};
    @media (max-width: ${DESKTOP_1440}px) {
        font-size: 18px;
        line-height: 22px;
    }
    @media (max-width: ${DESKTOP_1024}px) {
        font-size: 22px;
        line-height: 26px;
    }
`
export const EnglishExtract = styled.div`
    margin-top: 20px;
    color: ${COLORS.white};
    font: 800 35px/43px montserrat-extra-bold;
    word-wrap: break-word;
    @media (max-width: ${DESKTOP_1440}px) {
        font-size: 28px;
        line-height: 32px;
    }
    @media (max-width: ${DESKTOP_1200}px) {
        margin-top: 10px;
        font-size: 22px;
        line-height: 25px;
    }
    @media (max-width: ${DESKTOP_1024}px) {
        font-size: 28px;
        line-height: 32px;
    }
`

interface ICardProps {
    bgColor: string | null;
}
