import React, { FunctionComponent, useState, useEffect } from 'react';

import { getEnglishTips } from 'services/englishTips.service';
import Loading from 'components/commons/Loading';
import LayoutContainer from 'containers/LayoutContainer';
import EnglishTipsView from 'views/EnglishTipsView';
import { IEnglishTipsContainer } from 'views/EnglishTipsView/types';

const EnglishTipsContainer: FunctionComponent = () => {

    const [tips, setTips] = useState<IEnglishTipsContainer[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    const fetchSetTips = async () => {
        const fetechedTips = await getEnglishTips();
        setTips(fetechedTips.data);
        setLoading(false);
    };
    
    useEffect(() => {
        fetchSetTips();
    }, []);

    return (
    <LayoutContainer>
        {loading ? (
            <Loading />
        ) : (
            <EnglishTipsView dataList={tips} />
        )}
    </LayoutContainer>
    );
};

export default EnglishTipsContainer;
