import styled, { css } from 'styled-components';
import { DESKTOP_1200, DESKTOP_1366, TABLET_992  } from 'constants/sizes';
import { Link } from 'react-router-dom';

const DESKTOP_1200_GRID_VERTICAL_GAP = 20;

const BIGGER_H_MODULE = 390;
const BIG_H_MODULE = 315;
const MEDIUM_H_MODULE = 270;

export const Container = styled.div<ITextProps>`
    display: flex;
    flex-direction: row;
    background: ${({ theme }) => theme.cardBackgroundColor};
    border-radius: 15px;
    width: 100%;
    height: 100%;
    box-shadow: 0px 10px 20px ${({ theme }) => theme.boxShadow};
    overflow: hidden;
    margin: ${({searchView}) => searchView ? "30px 0" : "none"};
    @media (max-width: ${TABLET_992}px) {
        flex-direction: column;
    }
`

export const LargeImage = styled.img<ITextProps>`
    aspect-ratio: 1/1;
    object-fit: cover;
    height: ${({searchView}) => searchView ? `${BIG_H_MODULE}px` : `${BIGGER_H_MODULE}px`};
    @media (max-width: ${DESKTOP_1366}px) {
        height: ${BIG_H_MODULE}px;
    }
    @media (max-width: ${DESKTOP_1200}px) {
        height: ${MEDIUM_H_MODULE}px;
    }
    @media (max-width: ${TABLET_992}px) {
        height: auto;
        width: 100%;
    }
`

export const SmallImage = styled.img`
    aspect-ratio: 1/1;
    object-fit: cover;
    height: ${ BIGGER_H_MODULE / 2 - DESKTOP_1200_GRID_VERTICAL_GAP / 2 }px;
    @media (max-width: ${DESKTOP_1366}px) {
        height: ${ BIG_H_MODULE / 2 - DESKTOP_1200_GRID_VERTICAL_GAP / 2 }px;
    }
    @media (max-width: ${DESKTOP_1200}px) {
        height: ${ MEDIUM_H_MODULE / 2 - DESKTOP_1200_GRID_VERTICAL_GAP / 2 }px;
    }
    @media (max-width: ${TABLET_992}px) {
        height: auto;
        width: 100%;
    }
`

export const TextContainer = styled.div<ITextProps>`
    display: flex;
    flex-direction: column;
    justify-content: ${({smallerText}) => smallerText ? "left" : "space-between"};
    width: 100%;
    margin: ${({marginCard}) => marginCard};
    padding: ${({searchView}) => searchView ? "25px" : "15px"};
    @media (max-width: ${DESKTOP_1200}px) {
        padding: ${({searchView}) => searchView ? "15px" : "10px"};
    }
    @media (max-width: ${TABLET_992}px) {
        width: auto;
        height: 100%;
        margin: 0;
    };
`

export const Title = styled.h3<ITextProps>`
    color: ${({ theme }) => theme.title};
    font-weight: 700;
    margin-bottom: 7px;
    width: 100%;
    font-size: ${({smallerText})=> smallerText ? "calc(20px + 0.5vw)" : "calc(15px + 1.2vw)"} ;
    line-height:  ${({smallerText})=> smallerText ? "calc(30px + 0.5vw)" : "calc(25px + 1.2vw)"};
    max-height: ${({smallerText})=> smallerText ? "calc((30px + 0.5vw) * 2)" : "calc((25px + 1.2vw) * 2)"};
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    @media (max-width: ${DESKTOP_1366}px) {
        font-size: ${({smallerText})=> smallerText ? "calc(15px + 0.5vw)" : "calc(11px + 1.2vw)"} ;
        line-height:  ${({smallerText})=> smallerText ? "calc(20px + 0.5vw)" : "calc(12px + 1.2vw)"};
        max-height: ${({smallerText})=> smallerText ? "calc((20px + 0.5vw) * 2)" : "calc((12px + 1.2vw) * 2)"};
    }
    @media (max-width: ${DESKTOP_1200}px) {
        font-size: ${({smallerText})=> smallerText ? "15px" : "22px"} ;
        line-height:  ${({smallerText})=> smallerText ? "22px" : "30px"};
        max-height: ${({smallerText})=> smallerText ? "calc(20px * 2)" : "calc(30px * 2)"};
    }
    @media (max-width: ${TABLET_992}px) {
        font-size: 33px;
        line-height: 40px;
        margin-top: 15px;
        max-height: none;
    }    
`

export const Extract = styled.p<ITextProps>`
    font-weight: 300;
    color: ${({ theme }) => theme.extractCardsColor};
    width: 100%;
    font-size: calc(9px + 0.35vw);
    line-height: calc(12px + 0.35vw);
    max-height: calc((12px + 0.35vw) * 3);
    margin: ${({searchView}) => searchView ? "30px 0" : "0"};
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    @media (max-width: ${DESKTOP_1200}px) {
        ${({ searchView }) =>
        searchView
        ? css`
            font-size: calc(10px + 0.6vw);
            line-height: calc(15px + 0.6vw);
            max-height: calc((15px + 0.6vw) * 3); 
        `
        : css`
            font-size: calc(10px + 0.3vw);
            line-height: calc(15px + 0.3vw);
            max-height: calc((15px + 0.3vw) * 3);             
        `
        }
    }
    @media (max-width: ${TABLET_992}px) {
        ${({ searchView }) =>
        searchView
        ? css`
            font-size: calc(15px + 1vw);
            line-height: calc(20px + 1vw);
            max-height: none;
            margin: 20px 0;
            text-overflow: ellipsis;
          `
        : css`
            font-size: calc(15px + 1vw);
            line-height: calc(20px + 1vw);
            max-height: none;
            margin: 20px 0;
            text-overflow: ellipsis;
        `
        }
    }
`

export const Date = styled.p`
    font-style: normal;
    align-self: flex-start;
    font-weight: bold;
    color: ${({ theme }) => theme.extractCardsColor};
`

export const InternalLink = styled(Link)`
    text-decoration: none;
    color: inherit;
    &:focus, &:hover, &:visited, &:link, &:active {
    text-decoration: none; 
    }
    &:focus {
        outline: none;
    }
`;

export const ExternalLink = styled.a`
    text-decoration: none;
    color: inherit;
    &:focus, &:hover, &:visited, &:link, &:active {
    text-decoration: none;   
    }
    &:focus {
        outline: none;
    }
`;



interface ITextProps {
    smallerText?: boolean | null;
    searchView?: boolean | null;
    font?: string | null;
    opacity?: number | null;
    dateHeight?: boolean | null;
    marginCard?: string | null;
}

