import React from 'react'
import { ISubtitle } from './types'
import { SubTitleText } from './styles'

export const SubTitle: React.FunctionComponent<ISubtitle> = ({ data }) => {
  return (
    <SubTitleText>
      {data.text}
    </SubTitleText>
  )
}
