import styled from 'styled-components';
import { DESKTOP_1366, DESKTOP_1200, DESKTOP_1024, TABLET_740, MOBILE_575, MOBILE_460 } from 'constants/sizes';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.cardBackgroundColor};
  display: flex;
  flex-direction: column;
  padding: 24px 22px 20px 24px;
  border-radius: 10px;
  position: relative;
  box-shadow: 0px 10px 20px ${({ theme }) => theme.boxShadow};
  height: 100%;
  box-sizing: border-box;
  position: relative;
  @media (max-width: ${MOBILE_460}px) {
    min-height: 110px;
  }
`

export const Title = styled.div`
  font-size: 38px;
  font-weight: 600;
  font-family: montserrat-extra-bold;
  color: ${({ theme }) => theme.redSeeAll};
  text-transform: uppercase;
  @media (max-width: ${DESKTOP_1200}px) {
    font-size: 34px;
  }
  @media (max-width: ${DESKTOP_1024}px) {
    font-size: 26px;
  }
  @media (max-width: ${MOBILE_460}px) {
    font-size: 24px;
  }
`
export const LogoAndText = styled.div`
  display: flex;
  flex-direction: row;
`
export const TextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 75%;
  @media (max-width: ${DESKTOP_1200}px) {
    padding-top: 10px;
  }
  @media (max-width: ${MOBILE_460}px) {
    width: 90%;
  }
`
export const Text = styled.div`
  width: 100%;
  max-height: 5.6em;
  font: normal 22px/26px montserrat;
  align-items: center;
  color: ${({ theme }) => theme.title};
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  @media (max-width: ${DESKTOP_1366}px) {
    font-size: 18px;
    line-height: 22px;
  }
  @media (max-width: ${DESKTOP_1024}px) {
    font-size: 16px;
    line-height: 20px;
  }
  @media (max-width: ${TABLET_740}px) {
    font: normal 22px/26px montserrat;
  }
  @media (max-width: ${MOBILE_575}px) {
    font: normal 18px/22px montserrat;
  }
  @media (max-width: ${MOBILE_460}px) {
    -webkit-line-clamp: 3;
  }
`
export const ImageContainer = styled.div`
  margin-left: auto;
  align-self: center;
  @media (max-width: ${MOBILE_460}px) {
    width: 1px;
    height: 1px;
  }
`
export const Image = styled.img`
  width: 100px;
  height: auto;
  padding-left: 10px;
  @media (max-width: ${DESKTOP_1200}px) {
    width: 85px;
  }
  @media (max-width: ${DESKTOP_1024}px) {
    width: 60px;
  }
  @media (max-width: ${TABLET_740}px) {
    width: 100px;
  }
  @media (max-width: ${MOBILE_575}px) {
    width: 80px;
  }
  @media (max-width: ${MOBILE_460}px) {
    position: absolute;
    width: 70px;
    right: -6px;
    top: -22px;
  }
`

export const NewNotificationContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
  position: absolute;
  right: 0;
  top: 22px;

  @media (max-width: ${MOBILE_460}px) {
    bottom: 0;
    top: initial;

  }
`

export const NotificationText = styled.div`
  text-align: center;
  width: 18%;
  min-width: 51px;
  font-weight: bold;
  background-color: red;
  color: white;
  padding: 4px 0;
`
// 460