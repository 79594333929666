import { AxiosResponse } from 'axios';
import { get } from 'services/api';

export async function getEnglishTips(): Promise<AxiosResponse> {
  const response = await get('english-tips?_sort=published_at:desc');
  return response;
}

export async function getEnglishTipsLastPostDate(): Promise<AxiosResponse> {
  const response = await get('english-tips-last-post-date');
  return response;
}
