import styled from 'styled-components';
import { DESKTOP_1440, DESKTOP_1366, DESKTOP_1200, DESKTOP_1024, TABLET_992, MOBILE_575, MOBILE_380 } from 'constants/sizes';
import { COLORS } from 'helpers/enums/colors';

export const Title = styled.h1`
  font-size: 38px;
  line-height: 44px;
  font-weight: 800;
  text-align: center;
`;

export const ViewContainer = styled.div`
  margin-top: 10px;
`;

export const FilterTitle = styled.div`
  margin: 27px 0 18px;
  color: ${({ theme }) => theme.title};
  @media (max-width: ${TABLET_992}px) {
    display: none;
  }

  user-select: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
`;

export const FilterSection = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: ${TABLET_992}px) {
    display: none;
  }
`;

export const OptionsSection = styled.div`
  width: 100%;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  @media (max-width: ${TABLET_992}px) {
    flex-direction: column;
    margin-bottom: 0;
  }
`;

export const OptionsRow = styled.div<IDivProps>`
  display: flex;
  flex-direction: row;
  height: fit-content;
  justify-content: space-around;
  width: 100%;
`;

export const Option = styled.div<IDivProps>`
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
  display: flex;
  width: fit-content;
  padding: 6px 10px;
  border-radius: 8px;
  justify-content: center;
  color: ${({ theme, isSelected, filter}) => {
    if (filter === "SOS") return !isSelected ? COLORS.white : theme.SOSButtonText 
    return COLORS.white
  }};
  background-color: ${({ theme, isSelected, filter}) => {
    if (filter === "SOS") return !isSelected ? theme.redSeeAll : theme.SOSButton 
    return !isSelected ? COLORS.purple : COLORS.darkPurple
  }};
  @media (max-width: ${DESKTOP_1366}px) {
    font-size: 0.9rem;
    white-space: nowrap;
    align-items: center;
    align-self: center;
  }
  @media (max-width: ${DESKTOP_1200}px) {
    font-size: 0.8rem;
  }
  @media (max-width: ${DESKTOP_1024}px) {
    font-size: 0.7rem;
  }
  @media (max-width: ${MOBILE_380}px) {
    font-size: 0.8rem;
  }
`;

export const CardsGrid = styled.div`
  grid-template-columns: repeat(4, 1fr);
  display: grid;
  grid-gap: 35px;

  @media (max-width: ${DESKTOP_1440}px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: ${TABLET_992}px) {
    padding: 20px 0;
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: ${MOBILE_575}px) {
    grid-template-columns: 1fr;
  }
`;

export const SeeAll = styled.div`
  color: ${COLORS.purple};
  font-size: 14px;
  font-weight: 600;
  align-self: center;
  align-items: center;
  text-align: center;
  cursor: pointer;

  @media (max-width: ${DESKTOP_1200}px) {
    font-size: 0.8rem;
  }
  @media (max-width: ${DESKTOP_1024}px) {
    font-size: 0.7rem;
  }
  @media (max-width: ${TABLET_992}px) {
    margin-top: 10px;
  }
  @media (max-width: ${MOBILE_380}px) {
    white-space: nowrap;
    margin-left: 4px;
  }
`;

export const NotFoundDiv = styled.div`
  margin: 40px auto;
  @media (max-width: 660px) {
    margin: 70px auto;
  }
`

interface IDivProps {
  isSelected?: boolean,
  filter?: string,
}