import styled from 'styled-components';
import { COLORS } from 'helpers/enums/colors';

export const Checkbox = styled.div<ISelectedProps>`
    opacity: 1;
    position: absolute;
    top: 5px;
    right: 0px;
    transition: all 0.25s;
`

export const Label = styled.label`
    width: 38px;
    height: 15px;
    background-color: ${COLORS.transparent};
    display: flex;
    border-radius: 10px;
    align-items: center;
    justify-content: space-between;
    position: relative;
    cursor: pointer;    
`
export const Ball = styled.label<ISelectedProps>`
    width: 13px;
    height: 13px;
    background-color: ${COLORS.lightPurple};
    position: absolute;
    top: 1px;
    border-radius: 50%;
    transition: all 0.2s linear;
    transform: ${ props => props.isDarkTheme ? "translateX(20px)" : "translateX(0px)"};
    cursor: pointer;
`

export const Sun = styled.img<ISelectedProps>`
    width: 16px;
    margin-right: 4px;
    opacity: ${ props => props.isDarkTheme ? "0" : "1"};
    transition: all 0.2s linear;
`

export const Moon = styled.img<ISelectedProps>`
    width: 13px;
    filter: invert(1);
    opacity: ${ props => props.isDarkTheme ? "1" : "0"};
    transition: all 0.2s linear;
`

interface ISelectedProps {
    isDarkTheme: boolean;
}
