import { COLORS } from 'helpers/enums/colors'
import styled from 'styled-components'

export const Link = styled.a`
    display: block;
    color: ${COLORS.white};
    text-decoration: none;
    font-size: 20px;
    font-weight: bold;
    line-height: 18px;
    text-align: center;
    width: fit-content;
    padding: 15px 15px;
    margin: 20px auto;
    background: ${COLORS.red};
    border-radius: 7px;
    box-shadow: 0 0 5px rgba(0,0,0,0.3);
    text-shadow: 0 0 0 rgba(0,0,0,0.0);
    transition: 0.15s;
    &:focus{
        outline: none;
    }
    &:hover {
        box-shadow: 0 2px 7px rgba(0,0,0,0.45);
        transform: scale(1.1);
        text-shadow: 1px 1px 7px rgba(0,0,0,0.6);
    }
`
