import React from 'react';

import { IFirebaseUser } from 'types/user.types';

interface context {
  user: IFirebaseUser | undefined;
  darkMode: boolean;
  login: (user: IFirebaseUser | undefined, userEmail: string | null | undefined) => void;
  logout: () => void;
  switchMode: () => void;
}

const UserContext = React.createContext<context>({
  user: { email: 'aaa', photoURL: null },
  darkMode: false,
  login: () => {},
  logout: () => {},
  switchMode: () => {},
});

export { UserContext };
