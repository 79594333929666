import React, { FunctionComponent } from 'react';

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import {
    Container,
    TextContainer,
    Title,
    Extract,
} from './styles';

import './skeleton.css';
  
import { IContentCardProps } from './types';

const ContentCardSkeleton: FunctionComponent<IContentCardProps> = (props: IContentCardProps) => {
    const size = props?.size;
    const renderCard = () => {
        switch (size) {
            case 'large': // used in search view
                return (
                    <SkeletonTheme baseColor='rgba(85, 85, 85, 0.541)' highlightColor='rgba(85, 85, 85, 0.541)'>
                        <Container searchView >
                            <div>
                                <Skeleton style={{minWidth: '300px', aspectRatio: '1/1'}} />
                            </div>
                            <TextContainer searchView >
                                <div>
                                    <Title searchView > 
                                        <Skeleton />
                                        <Skeleton width={160} />
                                    </Title>
                                    <Extract searchView >
                                        <Skeleton />
                                        <Skeleton width={600} />
                                    </Extract>
                                </div>
                                <Skeleton/>
                            </TextContainer>
                        </Container>
                    </SkeletonTheme>
                )
            case 'medium': // used in home view
                return (
                    <SkeletonTheme baseColor='rgba(85, 85, 85, 0.541)' highlightColor='rgba(85, 85, 85, 0.541)'>
                        <Container>
                            <div>
                                <Skeleton className="skeleton-medium" /> 
                            </div>
                            <TextContainer marginCard='10px'>
                                <div>
                                    <Title>
                                        <Skeleton />
                                        <Skeleton width={140}   />
                                    </Title>
                                    <Extract>
                                        <Skeleton count={3}/>
                                    </Extract>
                                </div>
                                <Skeleton/>
                            </TextContainer>
                        </Container>
                    </SkeletonTheme>
                )
            case 'short':  // used in home view
                return (
                    <SkeletonTheme baseColor='rgba(85, 85, 85, 0.541)' highlightColor='rgba(85, 85, 85, 0.541)'>
                        <Container>
                            <div>
                                <Skeleton className="skeleton-short" />
                            </div>
                            <TextContainer marginCard='10px'>
                                <div>
                                    <Title smallerText className="skeleton-short-title">
                                        <Skeleton />
                                        <Skeleton width={120}  height={20} />
                                    </Title>
                                    <Extract smallerText>
                                        <Skeleton height={15}/>
                                        <Skeleton width={160} height={15}/>
                                    </Extract>
                                </div>
                            </TextContainer>
                        </Container>
                    </SkeletonTheme>
                )
            default:
            return (<></>)
        }
    }

    return renderCard();
    
};
    
export default ContentCardSkeleton;
