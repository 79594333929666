export const COLORS = {
  black: '#000000',
  bluishGray:'#303341',
  boxShadow: '#010F401A',
  boxlightShadow: '#49458B4D',
  cherry: '#EF233C',
  darkBlue:'#151825',
  darkPurple: '#403b96', 
  darkGray: '#414141',
  darkRed: '#AA0418',
  darkPink: '#b44a9b',
  gray: '#727272',
  greenChristi: '#7DB00E',
  modalBackground: 'rgba(71,79,99,0.75)',
  homeBackgroundWhite: 'rgba(256, 256, 256, 0.6)',
  homeBackgroundBlue: '#15182599',
  lightPurple: '#e2e4e8',
  indigo: '#44487a',
  promoDarkShadow: '#561b1b',
  promoRed: '#E6002D',
  purple: '#7971FA',
  red: '#FF0000',
  transparent: '#40404000',
  white: '#FFFFFF',
};
