import React, { FunctionComponent, useState, useEffect } from 'react';

import LayoutContainer from 'containers/LayoutContainer';
import EmployeesDirectoryView from 'views/EmployeesDirectoryView';
import { getEmployees } from 'services/employee.service';

const EmployeesDirectoryContainer: FunctionComponent = () => {

  const [employeesData, setEmployeesData] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const fetchEmployeeData = async () => {
    const fetechedEmployees = await getEmployees();
    setEmployeesData(fetechedEmployees.data);
    setLoading(false);
  };

  useEffect(() => {
    fetchEmployeeData();
  }, []);

  return (
    <LayoutContainer>
      <EmployeesDirectoryView employeesInfo={employeesData} loading={loading}/>
    </LayoutContainer>
  );
};

export default EmployeesDirectoryContainer;
