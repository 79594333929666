import React, { useEffect, useRef } from 'react'
import bodymovin from 'lottie-web'
// import { ILottiePlayerProps } from './types'

import { LottieContainer } from './styles'

export const LottiePlayer: React.FunctionComponent<any> = ({ data }: any) => {
    const containerRef = useRef(null)
   
    useEffect(() => {
      // define lottie animation
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const animation = bodymovin.loadAnimation({
        container: containerRef.current,
        renderer: 'svg',
        autoplay: data.autoplay,
        loop: data.animation_loop,
        animationData: data.animation_data
      })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <> 
        <LottieContainer
            height={data.animation_height}
            width={data.animation_width}
            className='lottie--container'
            ref={containerRef}
        />
        </>
    )
}
