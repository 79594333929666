import styled from 'styled-components'
import { MOBILE_460, TABLET_740 } from 'constants/sizes'

export const ImageStandard = styled.img<IProps>`
    width: ${({ imageSize }) => imageSize };
    height: auto;
    margin: auto;
    border-radius: 20px;
    @media (max-width: ${TABLET_740}px) {
        width: 80%;
    }
    @media (max-width: ${MOBILE_460}px) {
        width: 100%;
    }
`
export const ImageContainer = styled.div`
    text-align: center;
    width: 100%;
    margin: 20px auto;
    @media (max-width: ${TABLET_740}px) {
        margin: 20px 0;
    }
`
interface IProps {
    imageSize: string;
    data?: {
        size: string 
        file: {
            url: string
        }
    }
}
