import React, { FunctionComponent, useEffect } from "react";

import GeneralInfoCard from "components/generalInfoCard";
import ContentCard from "components/ContentCard";
import SocialMediaCard from "components/socialMediaCard";
import TechWellnessNewsletter from "components/TechWellnessNewsletter";

import ContentCardSkeleton from "components/ContentCard/skeleton";
import SocialMediaCardSkeleton from "components/socialMediaCard/skeleton";
import ImageCardSkeleton from "components/strapiComponents/ImageCard/skeleton";
import { CardsContainerSkeleton } from "components/strapiComponents/ImageCard/styles";

import { Background, GridItem, SeeAll, SecondaryTitle, TeamTitle } from "styles/commons";
import { CardsSection, HeaderCards, InnerLink, PeopleLink, SecondaryCards, SocialGridContainer, SocialItem, TwoItemsGridContainer } from "./styles";

import Tips from './assets/tips.svg';
import Solidario from './assets/solidario.svg';
import { IRenderNewsProps } from "./types";

export const RenderNews: FunctionComponent<IRenderNewsProps> = React.memo(({loading,friendlyReminders, socialMedias, newNotificationEnglish, techWellnessNewsletterCards, scrollToNews}: IRenderNewsProps) => {
    const News = React.useRef<HTMLInputElement>(null);
    useEffect(() => {
        if (scrollToNews && News.current) {
            News.current.scrollIntoView({
                behavior: "smooth",
            });
        }
    }, [scrollToNews]);

   
    return(
        <Background ref={News} >
            <TeamTitle>News</TeamTitle>
            <CardsSection>
                <HeaderCards>
                    <SecondaryTitle>Welcome!</SecondaryTitle>
                    <SeeAll to="/friendly-reminders">VER TODO</SeeAll>
                </HeaderCards>
                <SocialGridContainer>
                    <SocialItem spanSize={10} spanSizeTablet={9} startColumn={1} spanSizeMobile={18}>
                        {
                            !loading.FriendlyRemindersLoading && friendlyReminders[0] ?
                                <ContentCard data={friendlyReminders[0]} size='medium' />
                                : <ContentCardSkeleton size='medium'/>
                        }
                    </SocialItem>

                    <SocialItem notDisplayDesktop notDisplayMobile spanSize={10} spanSizeTablet={9} startColumn={10} spanSizeMobile={18}>
                        {
                            !loading.FriendlyRemindersLoading && friendlyReminders[1] ?
                                <ContentCard data={friendlyReminders[1]} size='medium' />
                                : <ContentCardSkeleton size='medium'/>
                        }
                    </SocialItem>

                    <GridItem spanSize={8} spanSizeTablet={10} startColumn={11} spanSizeMobile={18} notDisplayTablet>
                        <SecondaryCards>
                            {   
                                !loading.FriendlyRemindersLoading && (friendlyReminders.length > 2) ?
                                <>
                                    <ContentCard data={friendlyReminders[1]} size='short'/>
                                    <ContentCard data={friendlyReminders[2]} size='short'/>
                                </>
                                :
                                <>
                                    <ContentCardSkeleton size='short'/>
                                    <ContentCardSkeleton size='short'/>
                                </>
                            }  
                        </SecondaryCards>
                    </GridItem>
                </SocialGridContainer>
            </CardsSection>
            <CardsSection marginTop='60px' marginTopMobile='30px'>
                <HeaderCards>
                    <SecondaryTitle>En redes </SecondaryTitle>
                    <SeeAll to="/social-media">VER TODO</SeeAll>
                </HeaderCards>
                <SocialGridContainer>
                    <SocialItem spanSize={6} spanSizeTablet={9} startColumn={1} spanSizeMobile={18}>
                        {
                            !loading.SocialMediaCardsLoading && socialMedias[0] ?
                                <SocialMediaCard data={socialMedias[0]} size='large'/>
                                : <SocialMediaCardSkeleton />
                        }
                    </SocialItem>
                    <SocialItem spanSize={6} spanSizeTablet={9} startColumn={10} notDisplayMobile>
                        {
                            !loading.SocialMediaCardsLoading && socialMedias[1] ?
                                <SocialMediaCard data={socialMedias[1]} size='large'/>
                                : <SocialMediaCardSkeleton />
                        }
                    </SocialItem>
                    <SocialItem spanSize={6} notDisplayTablet notDisplayMobile>
                        {
                            !loading.SocialMediaCardsLoading && socialMedias[2] ?
                                <SocialMediaCard data={socialMedias[2]} size='large'/>
                                : <SocialMediaCardSkeleton />
                        }
                    </SocialItem>
                </SocialGridContainer>
            </CardsSection>
                {
                    !loading.TechWellnessNewsletterCardsLoading && Object.keys(techWellnessNewsletterCards).length !== 0 ?
                        <TechWellnessNewsletter techWellnessNewsletterCards={techWellnessNewsletterCards}/>
                        :
                        <CardsContainerSkeleton>
                            <ImageCardSkeleton skeleton='homepage' />
                            <ImageCardSkeleton skeleton='homepage' />
                            <ImageCardSkeleton skeleton='homepage' />
                        </CardsContainerSkeleton>
                }
            <TwoItemsGridContainer marginTop='40px' marginTopMobile='30px'>
                <GridItem spanSize={1} spanSizeTablet={1} startColumn={1} spanSizeMobile={2}>
                    <PeopleLink href="https://docs.google.com/forms/d/e/1FAIpQLSf5HDm1oMlwAAfFD_MpMoAxfmzeZOp43xDvrBm8IEbqhL19Bg/viewform" target="_blank">
                        <GeneralInfoCard
                            title='PSH SOLIDARIO'
                            text='¡Ayudanos a ayudar! Si sabés de alguna campaña solidaria, compartila en este espacio'
                            image={Solidario}
                        />
                    </PeopleLink>
                </GridItem>
                <GridItem spanSize={1} spanSizeTablet={1} startColumn={2} spanSizeMobile={2}>
                    <InnerLink to="/english-tips">
                        <GeneralInfoCard
                            title='ENGLISH TIPS'
                            text='Ampliá tu vocabulario y aprendé nuevas formas de expresarte en tu día a día'
                            image={Tips}
                            hasNewContentNotification={newNotificationEnglish}
                        />
                    </InnerLink>
                </GridItem>
            </TwoItemsGridContainer>
        </Background>
    )
})
