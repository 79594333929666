import styled from 'styled-components';
import { COLORS } from 'helpers/enums/colors';
import { MOBILE_575, MOBILE_460 } from 'constants/sizes';
import { ReactComponent as IconSearch }  from './assets/iconSearch.svg'

export const FormGroup = styled.form`
  width: 80%;
  max-width: 875px;
  margin: auto;
  position: relative;
  box-sizing: border-box;
  @media (max-width: ${MOBILE_575}px) {
    width: 100%;
  }
`;

export const CustomInput = styled.input`
  width: 100%;
  height: fit-content;
  margin-bottom: 0px !important;
  background-color: ${COLORS.white};
  border-radius: 50px;
  padding: 23px 30px;
  border: none;
  box-shadow: 20px 10px 20px 10px ${({ theme }) => theme.boxShadow};
  box-sizing: border-box;

  label {
    display: flex;
    font-size: 14px;
    span {
      display: none;
    }
  }

  ::placeholder {
    font-weight: bold;
    color: black;
  }

  .MuiFormLabel-root.Mui-focused {
    color: ${COLORS.black};
  }

  .MuiInputBase-input {
    font-size: 14px;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill::first-line,
    &:-webkit-autofill:focus {
      -webkit-box-shadow: 0 0 0px 1000px white inset !important;
      background-color: ${COLORS.white};
      background-clip: content-box !important;
      font-size: 14px;
    }
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${COLORS.black};
  }

  .MuiInput-underline:after {
    border-bottom-color: ${COLORS.black};
  }
`;

export const ErrorBlock = styled.div`
  width: 100%;
  position: absolute;
  font-size: 13px;
  line-height: 1.5;
  color: ${COLORS.red};
  text-align: left;

  @media (max-width: ${MOBILE_460}px) {
    font-size: 12px;
  }
`;

export const SearchIcon = styled(IconSearch)`
  width: 25px;
  height: 25px;
  transform: rotate(274deg);
`;

export const SubmitButton = styled.button`
  top: 17px;
  right: 10px;
  position: absolute;
  background-color: transparent;
  border: none;
`;
