import React from 'react'
import { IImage } from './types'
import { ImageContainer, ImageStandard } from './styles'

export const Image: React.FunctionComponent<IImage> = ({ data }) => {
  let imageSize: string;
  
  switch (data?.size) {
    case ('small'):
      imageSize = '20%'
      break
    case ('medium'):
      imageSize = '40%'
      break
    case ('big'):
      imageSize = '80%'
      break
    default:
      imageSize = '100%'
  };

  const image = {
    url: data.file?.url,
    ext: data.file?.ext,
  }

  return (
    <ImageContainer >
      <ImageStandard 
        src={image.url}
        imageSize={imageSize}
      />
    </ImageContainer>
  )
}
