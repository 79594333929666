import styled, { css } from "styled-components";
import { Link } from 'react-router-dom';

import { COLORS } from "helpers/enums/colors";
import { DESKTOP_1440 } from 'constants/sizes';

import { ReactComponent as CloseMenuSVG } from '../main/Header/assets/close-menu.svg'; 

export const PromobarContainer = styled.div<IDivProps>`
    width: 100%;
    height: 54px;
    margin: 0;
    padding: 0;
    background: ${COLORS.promoRed};
    display: flex;
    align-items: center;
    justify-content: center;
    position: sticky;
    z-index: 1;
    top: 0;
    left: 0;
    box-shadow: 0 0 10px ${({ theme }) => theme.promoBarShadow};
    ${({ isOpen }) =>
        isOpen
        ? `height: auto;`
        : `visibility: hidden;
            height: 0;`
    }
    
`
export const TextContainer = styled.div<IDivProps>`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`
export const PromoTitle = styled.div`
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    :first-child {
        padding-left: 20px;
    }
    @media (min-width: ${DESKTOP_1440}px) {
      width: 80%;
    }

`
export const ExternalLink = styled.a<IDivProps>`
    padding: 0px 15px;
    display: inline-block;
    color: ${({ theme }) => theme.promoTextColor};
    background: transparent;
    font: bold 16px/18px 'montserrat-bold';
    text-align: center;
    text-decoration: underline ${({ theme }) => theme.promoTextColor};
    transition: 0.15s;
    word-break: normal;
    white-space: nowrap;
    ${({ isOpen }) =>
        isOpen
        ? `visibility: hidden;
            display: none;`
        : `display: block;`
    }
    &:focus {
        outline: none;
    }
`
export const InternalLink = styled(Link)<IDivProps>`
    padding: 0px 15px;
    display: inline-block;
    color: ${({ theme }) => theme.promoTextColor};
    background: transparent;
    font: bold 16px/18px 'montserrat-bold';
    text-align: center;
    text-decoration: underline ${({ theme }) => theme.promoTextColor};
    transition: 0.15s;
    word-break: normal;
    white-space: nowrap;
    ${({ isOpen }) =>
        isOpen
        ? `visibility: hidden;
            display: none;`
        : `display: block;`
    }
    &:focus {
        outline: none;
    }
`
export const CloseIconSVG = css`
    width: 10px;
    height: 10px;
    cursor: pointer;
    margin-right: 20px;
    color: ${COLORS.white};
`
export const CloseIcon = styled(CloseMenuSVG)`
  ${CloseIconSVG}
`

interface IDivProps {
    isOpen?: boolean | null;
}
  