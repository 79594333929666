import React, { FunctionComponent } from 'react'

import { BlockQuote } from 'components/strapiComponents/BlockQuote'
import { SubTitle } from 'components/strapiComponents/Subtitle'
import { MarkdownParser } from 'components/strapiComponents/Markdown'
import { Video } from 'components/strapiComponents/Video'
import { CallToAction } from 'components/strapiComponents/CallToAction'
import { Image } from 'components/strapiComponents/Image'
import { LottiePlayer } from 'components/strapiComponents/LottiePLayer'

import { IStrapiProps } from './types';
import { Gallery } from '../Gallery'

const StrapiParser: FunctionComponent<IStrapiProps> = ({ component }) => {
  // eslint-disable-next-line 
  const componentName = component['__component'].replace('strapi_Component', '')

  switch (componentName) {
    case ('text.subtitle'):
      return (
        <SubTitle data={component}/>
      )
    
    case ('text.quote'):
      return (
        <BlockQuote data={component}/>
      )
    
    case ('text.rich-text'):
      return (
        <MarkdownParser data={component.content} />
      )

    case ('multimedia.embed'):
      return (
        <Video data={component} />
      )

    case ('text.cta'):
      return (
        <CallToAction data={component} />
      )

    case ('multimedia.image'):
      return (
        <Image data={component} />
      )

    case ('multimedia.gallery'):
      return (
        <Gallery data={component}/>
      )

    case ('multimedia.lottie'):
      return (
        <LottiePlayer data={component}/>
      )

    default:
      return <div> {componentName} </div>
  }
};

export default StrapiParser;
