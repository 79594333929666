import React, { FunctionComponent } from 'react';

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import {
  CardContainer,
  LogoSection,
  Text,
  LikeSection,
  TextContainer
} from './styles';

const SocialMediaCardSkeleton: FunctionComponent = () => {

    return (
        <SkeletonTheme baseColor='rgba(85, 85, 85, 0.541)' highlightColor='rgba(85, 85, 85, 0.541)'> 
            <CardContainer>
                <LogoSection>
                    <div>
                        <Skeleton width={40} height={40} highlightColor='rgba(20, 20, 20, 0.541)' circle/>
                    </div>
                    <LikeSection>
                        <Skeleton width={20} height={20} highlightColor='rgba(20, 20, 20, 0.541)' circle/>
                        <Skeleton style={{margin: '5px 0 0 10px'}} width={40} height={10} />
                    </LikeSection>
                </LogoSection>
                <Skeleton style={{aspectRatio: '1/1'}} />
                <TextContainer>
                <Text>
                    <Skeleton width={200} height={20} />
                </Text>
                </TextContainer>
            </CardContainer>
        </SkeletonTheme>
    );
};

export default SocialMediaCardSkeleton;
