import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { TABLET_992 } from "constants/sizes";
import { COLORS } from 'helpers/enums/colors';

export const CardContainer = styled.div<IDivProps>`
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    ${props => props.isTraining ? "aspect-ratio: 3 / 4" : "aspect-ratio: 1 / 1"}
`;

export const CardContainerSkeleton = styled.div<IDivProps>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    ${props => props.isTraining ? "aspect-ratio: 3 / 4" : "aspect-ratio: 1 / 1"}
    border-radius: 10px;
    padding: 20px 0;
    @media (max-width: ${TABLET_992}px) {
        padding: 10px 0 0;
    }
`;

export const CardsContainerSkeleton = styled.div`
    padding: 20px 0 0;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-gap: 30px;
    @media (max-width: ${TABLET_992}px) {
        grid-template-columns: repeat(2,1fr);
    }
    @media (max-width: 575px) {
        display: flex;
        flex-direction: column;
    }
`;

export const HoverContainer = styled.div`
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 3;
    background: rgba(0, 0, 0, 0);
    border-radius: 8px;
    &:hover{
        background: rgba(0, 0, 0, 0.90);
        transition: all 0.3s ease-in-out;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        a,div{
            display: flex;
        }
    }
`;

export const Button = styled.a<IDivProps>`
    width: 70%;
    border-radius: 10px;
    height: fit-content;
    padding: 15px 0;
    background: red;
    ${props => props.hasRegistrationUrl 
        ? `position: relative; top: -25px;` 
        : `position: static;`
    };
    z-index: 4;
    color: ${COLORS.white};
    display: none;
    align-items: center;
    justify-content: center;
    text-align: center;
    text-decoration: none;
    font: 600 16px/22px montserrat-semi-bold;
    opacity: 1;
    margin-top: ${({ hasMarginTop }) => hasMarginTop && '42px'};
    &:focus {
        outline: none;
    }
`;

export const Image = styled.img`
   width: 100%;
   border-radius: 10px;
   object-fit: cover;
`;

export const ExternalLink = styled.a`
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    text-decoration: none;
    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;   
    }
    &:focus {
        outline: none;
    }
`;

export const InternalLink = styled(Link)`
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    text-decoration: none;
    &:focus, &:hover, &:visited, &:link, &:active {
        text-decoration: none;   
    }
    &:focus {
        outline: none;
    }
`;

export const HoverTitle = styled.div<IDivProps>`
    font: 600 21px/25px montserrat-semi-bold;
    color: ${({ theme }) => theme.redSeeAll};
    display: none;
    margin-bottom: ${props => props.onlyTitle
        ? '0px' 
        : '27px'
    };
    ${props => props.hasRegistrationUrl
        ? 'position: relative; top: -25px;' 
        : 'position: static;'
    };
`;

interface IDivProps {
    isTraining?: boolean | null;
    hasMarginTop?: boolean | null;
    hasRegistrationUrl?: boolean | null;
    onlyTitle?: boolean;
}