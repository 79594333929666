import React, { FunctionComponent } from 'react';
import StrapiParser from 'components/strapiComponents/StrapiParser';
import { IPostView } from 'views/PostView/types';
import { BodyContainer, ComponentContainer } from './styles';

const StrapiBody: FunctionComponent <IPostView> = ({data}) => {
  const { body } = data;
  return (
    <BodyContainer>
      {body.map( (component: any, index) => {
        const keyId = component.id + index;
        return(
          <ComponentContainer>
            <StrapiParser key={keyId} component={component} />
          </ComponentContainer>
        ); 
      })}
    </BodyContainer>
  )
}
export default StrapiBody;
