import React, { FunctionComponent } from 'react';
import moment from 'moment';
import { IEmployeesProps } from './types';

import IconArrow from './assets/iconArrowDown.png'

import {
    CardContainer, ImageContainer, Name, Role, Email, Slack, SocialContainer, SeeMoreButton,
    ProfileImage, ImageBorder, EmailIcon, CollapsibleContainer, CollapsibleContent, SlackIcon, 
    InfoContainer, InfoCollapsible, CakeIcon, LinkedInIcon, ArrowImg, ExternalLink, PhoneIcon
} from './styles';


const EmployeeCard: FunctionComponent<IEmployeesProps> = ({employeesInfo, onClick, visibleId}: IEmployeesProps) => {

    const isOpen = employeesInfo.id === visibleId;
    const newEmail = employeesInfo.email.replace("@wearepsh.com", "");

    const monthDate = () => {
        return  moment.utc(employeesInfo.birthday).format("DD/MM");
    }

    return (
        <CardContainer>
            <ImageContainer>
                <ImageBorder>
                    <ProfileImage src={employeesInfo.photo_url}/>
                </ImageBorder>
            </ImageContainer>
            <Name>
                {employeesInfo.complete_name.replace(',', '')}
            </Name>
            <Role>
                {employeesInfo.role}
            </Role>
            {
                employeesInfo.phone_number && 
                <SocialContainer hasMarginTop>
                    <PhoneIcon />
                    <ExternalLink href={`tel:${employeesInfo.phone_number}`} target="_blank">
                        <Email>
                            {employeesInfo.phone_number}
                        </Email>
                    </ExternalLink>
                </SocialContainer>
            }
                <SocialContainer hasMarginTop={!employeesInfo.phone_number}>
                    <EmailIcon />
                <ExternalLink href={`mailto:${employeesInfo.email}`} target="_blank">
                    <Email>
                        {newEmail}
                    </Email>
                </ExternalLink>
            </SocialContainer>
            <SocialContainer>
                <SlackIcon />
                <Slack>
                    {employeesInfo.slack_user}
                </Slack>
            </SocialContainer>
            <CollapsibleContainer isOpen={isOpen}>        
                <CollapsibleContent>
                    <InfoContainer>
                        <CakeIcon />
                        <InfoCollapsible hasMarginTop>
                            {monthDate()}
                        </InfoCollapsible>
                    </InfoContainer>
                    <InfoContainer hasMarginTop>
                        <LinkedInIcon />
                        <InfoCollapsible>
                            <ExternalLink href={`https://${employeesInfo.url_linkedin}`} target="_blank">
                                {employeesInfo.linkedin_user}
                            </ExternalLink>
                        </InfoCollapsible>
                    </InfoContainer>
                </CollapsibleContent>
            </CollapsibleContainer>
            <SeeMoreButton onClick={onClick}>
                { !(employeesInfo.id === visibleId) ? "VER MÁS INFO" : <ArrowImg src={IconArrow}/>}
            </SeeMoreButton>
        </CardContainer>
    );
};

export default EmployeeCard;

EmployeeCard.defaultProps = {
  
};
