import React, { FunctionComponent, useState, useEffect } from 'react';

import LayoutContainer from 'containers/LayoutContainer';

import { getSearchResults } from 'services/search.service';

import SearchResultsView from 'views/SearchResultsView';

import SearchView from 'views/SearchView';

const SearchContainer: FunctionComponent = (props: any) => {
  const { contentType } = props;
  const [loading, setLoading] = useState<boolean>(true);
  const [searchResults, setSearchResults] = useState<any>([]);
  const [textQuery, setTextQuery] = useState<string>('');
  const [textSearchInput, setTextSearchInput] = useState<string>('');
  const [clickMainIput, setClickMainIput] = useState<boolean>(false);

  const searchQuery = async () => {
    const fetchedResults = await getSearchResults( textQuery, contentType );
    const results = fetchedResults.data;
    setSearchResults(results);
    setLoading(false);
  };

  const input = {
    onChange: (text: any) => setTextSearchInput(text),
    onSumbit: () =>{ 
      setTextQuery(textSearchInput)
    },
    value: textSearchInput,
  };

  const mainInput = {
    onChange: (text: string) => setTextSearchInput(text),
    onSumbit: (text: string) =>{ 
      setTextQuery(text)
      setTextSearchInput(text)
      setClickMainIput(true)
    },
    value: textSearchInput,
    type: 'sumbit',
  };

  useEffect(() => {
    setLoading(true);
    searchQuery();
  }, [textQuery,contentType]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <LayoutContainer isGradient={!clickMainIput && !contentType}>
        {!clickMainIput && !contentType ?
          <SearchView input={mainInput}/>
        :
          <div>
            <SearchResultsView textQuery={textQuery} loading={loading} name="search" results={searchResults} contentType={contentType} input={input}/>
          </div>
        }
    </LayoutContainer>
  );
};

export default SearchContainer;