import styled from 'styled-components';
import { Link } from "react-router-dom";
import { DESKTOP_1024, TABLET_992, TABLET_768, MOBILE_460 } from 'constants/sizes';
import { COLORS } from 'helpers/enums/colors';

export const HomeContainer = styled.div`
  flex: 1;
  margin-left: 20px;
  @media (max-width: ${TABLET_768}px){
    margin-left: 0px;
  }
`;

export const SectionTitle = styled.h2`
  margin-top: 30px;
  font: 900 20px/24px roboto-black;
  color: ${({ theme }) => theme.title};
  @media (max-width: ${TABLET_992}px) {
    font: 500 20px/24px roboto-medium;
  }
`;

export const SeeAll = styled(Link)`
  text-decoration: none;
  font-weight: 900;
  color: ${({ theme }) => theme.purpleLink};
  &:focus, &:hover {
    border-bottom: 1px solid ${({ theme }) => theme.purpleLink};
  }
`

export const TotalResults = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 25px;
  padding-bottom: 20px;
  color: ${({ theme }) => theme.subTitleCardsColor};
  border-bottom: 1px solid ${COLORS.lightPurple};
  width: fit-content;
`;

export const Bold = styled.div`
  font-weight: 800;
  margin: 0 5px;
`;

export const NewsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

export const CardsGrid = styled.div`
  width: 100%;
  grid-template-columns: repeat(3, 1fr);
  display: grid;
  grid-gap: 35px;

  @media (max-width: ${DESKTOP_1024}px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: ${TABLET_768}px) {
    grid-template-columns: 1fr;
  }
  @media (max-width: ${MOBILE_460}px) {
    width: 100%;
  }
`;

export const SocialCardsContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
`

export const SeeMoreContent = styled.div`
  width: 100%;
  font-size: 15px;
  color: ${({ theme }) => theme.title};
  margin-top: 15px;
`;
