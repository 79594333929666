import React, { FunctionComponent } from 'react';
import { useHistory } from "react-router-dom";

import { ArrowIcon, Button } from './styles';

const BackButton: FunctionComponent = () => {
    const history = useHistory();

    const onClick = () => {
       return history.action === "REPLACE" ? history.push('/') : history.goBack()
    } 
    


    return (
    <>
        <Button type='button' onClick={() => onClick()}>
            <ArrowIcon/>
            BACK
        </Button>
    </>
  )
}
export default BackButton;
