import React, { FunctionComponent } from 'react';

import FaqAdmin from 'components/FaqAdmin';
import BackButton from 'components/BackButton';

import FaqCardSkeleton from 'components/FaqAdmin/skeleton';
import { Background, SectionName, Title, TopContainer } from 'styles/commons';
import { Container, QButton, SpecificQuestion, SpecificText } from './styles';

import { IFaqAdminView } from './types';

const FaqAdminView: FunctionComponent <IFaqAdminView> = ({dataList, loading}: IFaqAdminView) => {

  return (
    <Container>
      <Background hasMarginBottom>
        <TopContainer>
          <BackButton />
          <SectionName>ADMIN</SectionName>
        </TopContainer>
        <Title>FAQ Admin</Title>
        {
          !loading && (dataList.length > 0) ?
              <FaqAdmin dataList={dataList} />
            : 
              (
                <>
                  <FaqCardSkeleton />
                  <FaqCardSkeleton />
                  <FaqCardSkeleton />
                  <FaqCardSkeleton />
                </>
              )
        }
      </Background>
      <SpecificQuestion>
        <SpecificText>¿No encontraste la información que buscabas?</SpecificText>
        <a href="https://docs.google.com/forms/d/e/1FAIpQLScXZ_ch7ktUNAcogjlkLPAw-LvAIOjrIReDWvOVEnLOaEGDMA/viewform" target="_blank" rel="noreferrer">
          <QButton>mandanos tu consulta</QButton>
        </a>
      </SpecificQuestion>
    </Container>
  )
}
export default FaqAdminView;
