import React, { ChangeEvent, FunctionComponent } from 'react';

import { IInputProps } from './types';
import { FormGroup, CustomInput, SearchIcon, SubmitButton } from './styles';

const Input: FunctionComponent<IInputProps> = (props: IInputProps) => {
  const { input }=props;
  const { onChange , onSumbit, type = 'text' }=input;
  const {value}=input

  const getInputType = () => {
    return type;
  };

  const handleSubmit = (inputValue: any, e: any) => {
    e.preventDefault();
    onSumbit(inputValue);
  }

  return (
    <FormGroup onSubmit= {(e:any) => onSumbit ? handleSubmit(value, e) : e.preventDefault() }>
      <CustomInput
        onChange={(event: ChangeEvent<HTMLInputElement>) => onChange(event.target.value) }
        type={getInputType()}
        value={value}
        placeholder="Buscar"
      />
      <SubmitButton type= "submit"> 
        <SearchIcon onClick={() => onSumbit && onSumbit(value)}/>
      </SubmitButton>
    </FormGroup>
  );
};

export default Input;
