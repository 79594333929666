import styled from "styled-components";
import { MOBILE_460, MOBILE_575, TABLET_800, TABLET_992 } from "constants/sizes";
import { COLORS } from "helpers/enums/colors";
import { Link } from "react-router-dom";

export const CardsSection = styled.div<IDivProps>`
  display: flex;
  flex-direction: column;
  margin-top: ${({ marginTop }) => marginTop};
  @media (max-width: ${MOBILE_575}px) {
    margin-top: ${({ marginTopMobile }) => marginTopMobile};
  }
`
export const HeaderCards = styled.div<IDivProps>`
  display: flex;
  flex-direction: row;
  margin-bottom: 14px;
  margin-top: ${({ hasMarginTop }) => (hasMarginTop ? '53px' : '0')};
  justify-content: space-between;
`
export const Title = styled.h2`
  font: 600 38px/47px montserrat-semi-bold;
  color: ${COLORS.black};
  @media (max-width: ${MOBILE_460}px) {
    width: 70%;
    font: 600 28px/30px montserrat-semi-bold;
  }
`
export const SecondaryCards = styled.div`
  display: grid;
  grid-row-gap: 20px;
  grid-template-rows: 1fr 1fr;
  grid-column-start: 6;
  grid-column-end: 10;
  @media (max-width: ${TABLET_992}px) {
    grid-column-start: 1;
    grid-column-end: 10;
    display: none;
  }
  @media (max-width: ${TABLET_800}px) {
    display: none;
  }
  @media (max-width: ${MOBILE_460}px) {
    display: none;
  }
`
export const TwoItemsGridContainer = styled.div<IDivProps>`
  grid-template-columns: 1fr 1fr;
  display: grid;
  grid-gap: 30px;
  margin-top: ${({ marginTop }) => marginTop};
  margin-bottom: ${({ marginBottom }) => marginBottom};
  @media (max-width: ${MOBILE_575}px) {
    margin-top: ${({ marginTopMobile }) => marginTopMobile};
  }
`
export const PeopleLink = styled.a`
  text-decoration: none;
  width: 100%;
  display:flex;
  &:visited {
    color: ${COLORS.black};
  }
  &:focus {
    outline: none;
  }
`
export const InnerLink = styled(Link)`
  text-decoration: none;
  color: ${COLORS.black};
  grid-column: auto/span 3;
  &:focus {
    outline: none;
  }
`

export const SocialGridContainer = styled.div<IDivProps>`
  display: grid;
  grid-template-columns: repeat(18, 1fr);
  grid-gap: 30px;
  margin-top: ${({ marginTop }) => marginTop};
  margin-bottom: ${({ marginBottom }) => marginBottom && marginBottom};
  @media (max-width: ${MOBILE_575}px) {
    grid-gap: 30px 1px;
  }
`;

export const SocialItem = styled.div<IDivProps>`
  display: grid;
  grid-column: auto/span ${({ spanSize }) => spanSize && spanSize};
  @media (min-width: ${TABLET_992 + 1}px) {
    display: ${({ notDisplayDesktop }) => notDisplayDesktop && 'none'};
  }
  @media (max-width: ${TABLET_992}px) {
    grid-column: auto/span ${({ spanSizeTablet }) => spanSizeTablet && spanSizeTablet};
    grid-column-start: ${({ startColumn }) => startColumn && startColumn};
    display: ${({ notDisplayTablet }) => notDisplayTablet && 'none'};
  }
  @media (max-width: ${MOBILE_575}px) {
    grid-column: auto/span ${({ spanSizeMobile }) => spanSizeMobile && spanSizeMobile};
    display: ${({ notDisplayMobile }) => notDisplayMobile && 'none'};
  }
`;

interface IDivProps {
  hasMarginTop?: boolean | null;
  marginTop?: string | null;
  marginTopMobile?: string | null;
  marginBottom?: string | null;
  notDisplayMobile?: boolean | null;
  notDisplayTablet?: boolean | null;
  notDisplayDesktop?: boolean | null;
  spanSize?: number | null;
  spanSizeTablet?: number | null;
  spanSizeMobile?: number | null;
  startColumn?: number | null;
}
