import styled from 'styled-components';
import { TABLET_768 } from 'constants/sizes';

export const HomeContainer = styled.div`
  width: 100%;
`
export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`
export const NotFoundParagraph = styled.p`
  width: 80%;
  margin: 20px auto;
  font: 400 20px/22px montserrat;
  text-align: center;
  color: ${({ theme }) => theme.title};
  @media (max-width: ${TABLET_768}px) {
    font: 400 16px/20px montserrat;
  }
`