import React, { FunctionComponent } from 'react';
import { Route, RouteProps, Redirect, useLocation } from 'react-router-dom';

import { IFirebaseUser } from 'types/user.types';
import { ILocationState } from 'types/router.types';

interface IPublicRouteProps extends RouteProps {
  restricted: boolean;
  errorState: boolean;
  currentUser: IFirebaseUser | undefined;
}

const PublicRoute: FunctionComponent<IPublicRouteProps> = ({
  currentUser,
  restricted,
  component: Component,
  ...rest
}) => {
  const location = useLocation<ILocationState>();

  if (!Component) return null;
  return currentUser && restricted ? (
    <Redirect to={{ pathname: location?.state?.from?.pathname || '/' }} />
  ) : (
    <Route {...rest} render={(props) => <Component {...props} {...rest} />} />
  );
};

export default PublicRoute;
