import React, { FunctionComponent } from 'react';

import LoginView from 'views/LoginView';

interface IErrorMessage {
  errorState: boolean;
}

const LoginContainer: FunctionComponent<IErrorMessage> = ({errorState}) => {
  return <LoginView errorState={errorState} />;
};

export default LoginContainer;
