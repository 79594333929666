import { AxiosResponse } from 'axios';

import { get } from 'services/api';

export async function getFriendlyReminders(): Promise<AxiosResponse> {
  const response = await get('friendly-reminders');
  return response;
}

export async function getFriendlyRemindersCards(limit : number): Promise<AxiosResponse> {
  const response = await get(`friendly-reminders-cards?limit=${limit}&sort=published_at:DESC`);
  return response;
}

export async function getPostBySlug(slug: string): Promise<AxiosResponse> {
  const response = await get(`friendly-reminders?slug=${slug}`);
  return response;
}

