import React, { FunctionComponent } from 'react';
import ReactPlayer from 'react-player'

import { VideoContainer, Container } from './styles';

import { IVideoComponentProps } from './types'; 

const VideoComponent: FunctionComponent<IVideoComponentProps> = ({data}: IVideoComponentProps) => {
  const { url }=data;
  return (
    <Container>
      <VideoContainer>
        <ReactPlayer
          url={url}
          controls
          loop={false}
          className='player'
          width='100%'
          height='100%'
        />
      </VideoContainer>
    </Container>
  );
};

export default VideoComponent;
