import styled from "styled-components"

export const ImageGalleryContainer = styled.div`

    outline: none;

    & *{
        outline: none;
    }

    /* changing the height of the fullscreen photo to fit the thumbnail*/ 
    .image-gallery-content .image-gallery-slide .image-gallery-image{max-height:calc(100vh - 110px)}

    /* chaging the color to purple */
    @media(hover: hover)and (pointer: fine){.image-gallery-icon:hover{color:#7971FA}.image-gallery-icon:focus{outline:2px solid #7971FA}}
    .image-gallery-bullets .image-gallery-bullet:focus{transform:scale(1.2);background:#7971FA;border:1px solid #7971FA}
    @media(hover: hover)and (pointer: fine){.image-gallery-bullets .image-gallery-bullet:hover{background:#7971FA;border:1px solid #7971FA}.image-gallery-bullets .image-gallery-bullet.active:hover{background:#7971FA}}
    .image-gallery-thumbnail.active,.image-gallery-thumbnail:focus{outline:none;border:4px solid #7971FA}
    @media(max-width: 768px){.image-gallery-thumbnail.active,.image-gallery-thumbnail:focus{border:3px solid #7971FA}}
    @media(hover: hover)and (pointer: fine){.image-gallery-thumbnail:hover{outline:none;border:4px solid #7971FA}}
    @media(hover: hover)and (pointer: fine)and (max-width: 768px){.image-gallery-thumbnail:hover{border:3px solid #7971FA}}

    /* chaging the vertical aligment of the thumbnail */
    .image-gallery-thumbnails-wrapper.left,.image-gallery-thumbnails-wrapper.right{display:inline-block;vertical-align:top;width:100px}
    .image-gallery-thumbnail .image-gallery-thumbnail-image{vertical-align:top;width:100%;line-height:0}

    /* hiding the thumbnail if its too long*/
    .image-gallery-thumbnail{display:inline-block;border:4px solid transparent;transition:border .3s ease-out;width:100px;max-height:100px;overflow:hidden;background:transparent;padding:0}
`
