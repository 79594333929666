
import { COLORS } from 'helpers/enums/colors';
import styled from 'styled-components';
import { ReactComponent as Arrow } from './assets/back-button.svg';

export const Button = styled.button`
  width: 70px;
  font: 600 14px/18px montserrat-semi-bold;
  display: flex;
  justify-content: center;
  color: ${COLORS.purple};
  background: none;
	border: none;
	padding: 0;
	cursor: pointer;
	outline: inherit;
  margin: 8px 0;
`;

export const ArrowIcon = styled(Arrow)`
  transform: rotate(270deg);
  width: 12px;
  padding-left: 3px;
`