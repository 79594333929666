import React, { FunctionComponent } from 'react';

import { Container, Title, LogoAndText, Text, TextContainer, Image, ImageContainer, NewNotificationContainer, NotificationText  } from './styles';

import { IGeneralInfoCardProps } from './types';

const GeneralInfoCard: FunctionComponent<IGeneralInfoCardProps> = (props: IGeneralInfoCardProps) => {
  const { title, text, image, hasNewContentNotification } = props;
 
  return (
    <Container>
      {hasNewContentNotification && 
        <NewNotificationContainer>
          <NotificationText>
            NEW
          </NotificationText>
        </NewNotificationContainer>
      }
  
     <Title>{title}</Title>
     <LogoAndText>
      <TextContainer>
        <Text>
          {text}
        </Text>
      </TextContainer>
        <ImageContainer>
          <Image src={image}/>
        </ImageContainer>
     </LogoAndText>
    </Container>

  );
};

export default GeneralInfoCard;
