import React, { useEffect, useRef } from 'react'
import bodymovin, { AnimationItem } from 'lottie-web'
import { LottieContainer } from './styles'

export const LottiePlayer: React.FunctionComponent<any> = ({ data }: any) => {
  const containerRef = useRef(null)
  useEffect(() => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const animation = bodymovin.loadAnimation({
      container: containerRef.current,
      renderer: 'svg',
      autoplay: data.autoplay,
      loop: data.animation_loop,
      animationData: data.animation_data
    })
  }, [])

  return (
    <> 
      <LottieContainer
        height={data.animation_height}
        width={data.animation_width}
        className='lottie--container'
        ref={containerRef}
      />
    </>
  )
}
