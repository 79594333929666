import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { COLORS } from 'helpers/enums/colors';

import { DESKTOP_1440, DESKTOP_1200, DESKTOP_1024, TABLET_992, TABLET_800, TABLET_740, MOBILE_575, MOBILE_460, MOBILE_320  } from 'constants/sizes';

export const ExternalLink = styled.a`
    text-decoration: none;
    width: 100%;
    display: flex;
    grid-column: auto/span 3;
    &:focus {
        outline: none;
    }
`
export const InnerLink = styled(Link)`
    text-decoration: none;
    color: ${COLORS.black};
    grid-column: auto/span 3;
    width: 100%;
    &:visited {
        color: ${COLORS.black};
    }
    &:focus {
        outline: none;
    }
`
export const Section = styled.div<IDivProps>`
    display: flex;
    flex-direction: column;
    margin-top: ${({ marginTop }) => (marginTop)};
`
export const AdminBoxes = styled.div`
    margin: 0;
    grid-template-columns: 1fr 1fr 1fr;
    display: grid;
    margin-top: 48px;
`
export const AdminBoxTitle = styled.h2`
    margin: 0;
    text-transform: uppercase;
    align-self: center;
    color: ${COLORS.white};
    font: 600 52px/47px montserrat-extra-bold;
    > span {
        font: 600 50px montserrat-extra-bold;
    }
    @media (max-width: ${DESKTOP_1440}px) {
        font: 600 44px/35px montserrat-extra-bold;
        > span {
            font: 600 44px montserrat-extra-bold;
        }
    }
    @media (max-width: ${DESKTOP_1200}px) {
        font: 600 35px/35px montserrat-extra-bold;
        > span {
            font: 600 35px montserrat-extra-bold;
        }
    }
    @media (max-width: ${DESKTOP_1024}px) {
        font: 600 34px/35px montserrat-extra-bold;
    }
    @media (max-width: ${TABLET_992}px) {
        font: 600 51px/47px montserrat-extra-bold;
        > span {
            font: 600 51px/47px montserrat-extra-bold;
        }
    }
    @media (max-width: ${MOBILE_575}px) {
        font-size: 40px;
        line-height: 44px;
        > span {
            font: 600 40px/44px montserrat-extra-bold;
        }
    }
`
export const AdminBoxTitleNormal = styled.div`
    font-size: 24px;
    text-transform: initial;
    display: block;
    line-height: 32px;
    @media (max-width: ${DESKTOP_1440}px) {
        font-size: 20px;
    }
    @media (max-width: ${DESKTOP_1200}px) {
        font-size: 18px;
        line-height: 30px;
    }
    @media (max-width: 1060px) {
        font-size: 16px;
    }
    @media (max-width: ${TABLET_992}px) {
        font-size: 20px;
    }
`
export const PeopleBoxes = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 30px;
    @media (max-width: ${TABLET_992}px) {
        grid-template-columns: repeat(9, 1fr);
        grid-gap: auto;
    }
    @media (max-width: ${TABLET_740}px) {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 30px;
    }
`
export const Box = styled.div<IDivProps>`
    width: 100%;
    height: 100%;
    background-color: ${({ color }) => (color)};
    min-height: 208px;
    padding: 25px 20px;
    border-radius: 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: ${({ isFlexColumn }) => (isFlexColumn ? 'column' : 'row')};
    box-shadow: 0px 10px 20px ${({ theme }) => theme.boxShadow};
    @media (max-width: ${DESKTOP_1440}px) {
        padding: 15px;
    }
    @media (max-width: ${MOBILE_575}px) {
        margin-bottom: ${({ marginBottomMobile }) => (marginBottomMobile)};
        padding: 15px 10px 15px 15px;
        width: 100%;
        min-height: 160px;
    }
`
export const TextSection = styled.div`
    width: 70%;
    @media (max-width: ${MOBILE_460}px) {
        width: auto;
        margin-right: auto;
    }
`
export const PeopleIcon = styled.div`
    display: flex;
    align-self: center;
    margin-left: auto;
    justify-content: right;
`
export const PeopleImage = styled.img`
    height: 130px;
    @media (max-width: ${DESKTOP_1200}px) {
        width: 80%;
        height: auto;
    }
    @media (max-width: ${TABLET_992}px) {
        width: 95px;
    }
    @media (max-width: ${MOBILE_575}px) {
        width: 85px;
    }
`
export const TextPeople = styled.p<IDivProps>`
    margin: 4px 0 0;
    align-self: flex-start;
    font: 400 22px/31px Montserrat;
    color: ${({ color }) => (color)};
    padding-right: 14px;
    @media (max-width: ${DESKTOP_1440}px) {
        width: auto;
        padding: 0;
    }
    @media (max-width: ${MOBILE_460}px) {
        margin: 0;
        padding: 10px 0 0 0;
        font: 400 18px/20px montserrat;
    }
`
export const CareerReferralsTitle = styled.h2<IDivProps>`
    margin: 0;
    white-space: nowrap;
    text-transform: uppercase;
    font: 800 50px/52px montserrat-extra-bold;
    > span {
        font: 600 50px/54px montserrat-extra-bold;
    }
    color:${({ isPurple }) => (isPurple ? COLORS.purple :  COLORS.white)};
    margin-top: ${({ marginTop }) => (marginTop)};
    @media (max-width: ${DESKTOP_1440}px) {
        font: 600 44px/48px montserrat-extra-bold;
        > span {
            font: 600 38px/42px montserrat-extra-bold;
        }
    }
    @media (max-width: ${TABLET_800}px) {
        font: 600 34px/38px montserrat-extra-bold;
        > span {
            font: 600 32px/34px montserrat-extra-bold;
        }
    }
    @media (max-width: ${MOBILE_575}px) {
        font: 600 30px/28px montserrat-extra-bold;
        > span {
            font: 600 28px/30px montserrat-extra-bold;
        }
    }
    @media (max-width: ${MOBILE_320}px) {
        white-space: normal;
    }
`
export const ReferralsTitle = styled.p`
    margin: 0;
    text-transform: uppercase;
    font: normal normal 600 34px/47px montserrat;
    @media (max-width: ${DESKTOP_1440}px) {
        font: normal 28px/34px montserrat;
    }
    @media (max-width: ${TABLET_800}px) {
        font: normal 30px/32px montserrat;
    }
    @media (max-width: ${MOBILE_575}px) {
        font: normal 24px/28px montserrat;
    }
`
export const PeopleBlackBoxes = styled.div`
    grid-template-columns: 1fr 1fr 1fr;
    display: grid;
    margin-top: 48px;
`

export const PeopleBoxTitle = styled.h2<IDivProps>`
    text-transform: uppercase;
    color: ${COLORS.purple};
    font: 800 42px/47px montserrat-extra-bold;
    margin-top: ${({ marginTop }) => (marginTop)};
    @media (max-width: ${DESKTOP_1440}px) {
        font: 600 40px/40px montserrat-extra-bold;
    }
    @media (max-width: ${TABLET_800}px) {
        font: 600 36px/36px montserrat-extra-bold;
    }
    @media (max-width: ${MOBILE_575}px) {
        font: 600 26px/28px montserrat-extra-bold;
    }
`
export const TwoItemsGridContainer = styled.div`
    grid-template-columns: 1fr 1fr;
    display: grid;
    grid-gap: 30px;
    margin-top: 30px;
    @media (max-width: ${TABLET_992}px) {
        grid-template-columns: repeat(9,1fr);
    }
`
export const TrainingPaloozaIcon = styled.div`
    display: flex;
    margin-right: auto;
`
export const TrainingPaloozaImage = styled.img`
    @media (max-width: ${DESKTOP_1440}px) {
        width: 80%;
        height: auto;
    }
    @media (max-width: ${MOBILE_460}px) {
        width: 60%;
        height: auto;
    }
`

interface IDivProps {
  marginTop?: string | null;
  marginBottom?: string | null;
  marginBottomMobile?: string | null;
  isPurple?: boolean | null;
  isFlexColumn?: boolean | null;
  color?: string | null;
}
