import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { TABLET_768 } from 'constants/sizes';
import { COLORS } from 'helpers/enums/colors';

export const Container = styled.div<IItemsSectionProps>`
  width: 100vw;
  padding: 10px 40px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  position: fixed;
  top: ${( {isPromobarDisplayed, isPromobarOpen} ) => (isPromobarDisplayed && isPromobarOpen) ? `49px` : `-1px`};
  z-index: 3;
  background-color: ${({ theme }) => theme.cardBackgroundColor};
  box-shadow: 0 1px 10px ${({ theme }) => theme.boxShadow};

  @media (min-width: ${TABLET_768 + 1}px) { // +1px because 768 ends up in the "no navbar" zone
    display: none;
  }
  @media (max-height: 628px) {
    display: flex;
    margin-bottom: 10px;
  }
`;

export const Logo = styled(Link)`
  text-decoration: none;
  &:focus {
    outline: none;
  }
  & img {
    height: 50px;
    margin: auto;
  }
`;

export const MenuLogo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 35px;
  filter: ${({ theme }) => theme.menuIcons};
`;

export const ItemsSection = styled.div<IItemsSectionProps>`
  width: 100vw;
  ${({isOpen, isPromobarDisplayed, isPromobarOpen}) => 
    // eslint-disable-next-line no-nested-ternary
    (isOpen) ? 
      (isPromobarDisplayed && isPromobarOpen) ?
        `height:calc(100vh - 50px); height:-moz-calc(100vh - 50px); height:-webkit-calc(100vh - 50px);`
      : `height:calc(100vh); height:-moz-calc(100vh); height:-webkit-calc(100vh);`
    : `height:0;`
  };
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${ props => props.isOpen ? "body{ overflow: hidden }" : ""}
  position: fixed;
  top: ${( {isPromobarDisplayed, isPromobarOpen} ) => (isPromobarDisplayed && isPromobarOpen) ? `50px` : `0px`};
  left: 0px;
  transition: 0.5s;
  overflow: hidden;
  background-color: ${({ theme }) => theme.cardBackgroundColor};
  z-index: 2;
  padding: ${ props => props.isOpen ? "100px 0 65px 0" : "0 0 0 0"};

  @media (min-width: ${TABLET_768 + 1 }px) { // +1px because 768 ends up in the "no navbar" zone
    display: none;
  }
  @media (max-height: 628px) {
    display: flex;
  }
`;

export const ItemList = styled.div`
  margin: 0 40px 0 40px;
  & hr {
    margin: 25px 0;
  }
`;

export const Item = styled(Link)`
  width: 100vw;
  text-decoration: none;
  color: ${COLORS.red};
  font-weight: bold;
  font-size: 1.2rem;
  &:focus {
    outline: none;
  }
`;

export const SocialMedia = styled.div`
  padding: 0 40px;
  display: flex;
  justify-content: space-between;
  & svg {
    color: ${COLORS.red};
    width: 30px;
  }
`;

interface IItemsSectionProps {
  isOpen?: boolean;
  isPromobarDisplayed?: boolean;
  isPromobarOpen?: boolean;
}
