import React from 'react';

import {
	ArrowImg,
	Box,
	BoxTitle,
	CollapsibleContainer,
	CollapsibleContent,
	CurrentPeriod,
	Days,
	InfoCollapsible,
	MainText,
	RemainingDays,
	SeeMoreButton
} from './styles';

import arrow from './assets/red-arrow.svg';
import { ICollapsibleProps } from './types';

const Collapsible = ({cardTitle, data, onClickCard, isOpen, timeRange, moreInfo} : ICollapsibleProps) => {
	return (
		<Box>
			<MainText>
				<BoxTitle>{cardTitle}</BoxTitle>
				{data !== undefined && 
					<>
						{cardTitle.toString() === "Días disponibles"
						?
							<RemainingDays colorText={data > 0}>
								{data}
							</RemainingDays>
						:
							<Days>{data}</Days>
						}
					</>
				}
				{timeRange && (
					<CurrentPeriod>				 
						<div>
							<span>{timeRange.startDate}</span> al <br /> <span>{timeRange.endDate}</span>
						</div>
					</CurrentPeriod>
				)}
			</MainText>
			{moreInfo &&
				<>
					<CollapsibleContainer isOpen={isOpen}>
						<CollapsibleContent isOpen={isOpen}>
							<InfoCollapsible>
								{moreInfo}
							</InfoCollapsible>
						</CollapsibleContent>
					</CollapsibleContainer>
					<SeeMoreButton onClick={onClickCard} isOpen={isOpen}>
						<p>{ !(isOpen) ? `VER MÁS INFO` : <ArrowImg src={arrow}/>}</p>
					</SeeMoreButton>
				</>
			}
		</Box>
	);
};

export default Collapsible;
