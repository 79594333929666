import { AxiosResponse } from 'axios';

import { get } from 'services/api';

export async function getWellnessPosts(): Promise<AxiosResponse> {
  const response = await get('wellnesses');
  return response;
}

export async function getPostBySlug(slug: string): Promise<AxiosResponse> {
  const response = await get(`wellnesses?slug=${slug}`);
  return response;
}

export async function  getWellnessCards(limit: number): Promise<AxiosResponse> {
  const response = await get(`wellnesses-cards?limit=${limit}&sort=published_at:DESC`);
  return response;
}
