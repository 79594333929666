import React, { FunctionComponent } from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import { TextContainer, Title } from 'components/TechWellnessNewsletter/styles';

import { CardContainerSkeleton } from './styles';
import { IImageCardProps } from './types';

const ImageCardSkeleton: FunctionComponent<IImageCardProps> = (props: IImageCardProps) => {
    const { skeleton, isTraining } = props;
    const renderCard = () => {
        switch (skeleton) {
            case ('homepage'):
                return (
                    <SkeletonTheme baseColor='rgba(85, 85, 85, 0.541)' highlightColor='rgba(85, 85, 85, 0.541)'> 
                        <CardContainerSkeleton>   
                            <TextContainer>
                                <Title>
                                    <Skeleton height={30} width={150}/>
                                </Title>
                                <Skeleton style={{marginTop: '20px'}} height={10} width={50}/>
                            </TextContainer>
                            <Skeleton style={{aspectRatio: '1/1'}} />
                        </CardContainerSkeleton>
                    </SkeletonTheme>
                )
            break
            case ('searchview'):
                return (
                    <SkeletonTheme baseColor='rgba(85, 85, 85, 0.541)' highlightColor='rgba(85, 85, 85, 0.541)'> 
                        <CardContainerSkeleton>
                            {
                                isTraining ? <Skeleton style={{aspectRatio: '3/4', borderRadius: '10px'}} />
                                : <Skeleton style={{aspectRatio: '1/1', borderRadius: '10px'}} />
                            }
                        </CardContainerSkeleton>
                    </SkeletonTheme>
                )
            break
            default:
                return (<></>)
        };
    }
    return (
        renderCard()
    )
}

export default ImageCardSkeleton;
