import React, { FunctionComponent } from 'react';

import { IImageCardProps } from './types';

import { CardContainer, HoverContainer, Button, Image, InternalLink, HoverTitle, ExternalLink } from './styles';

const ImageCard: FunctionComponent<IImageCardProps> = (props: IImageCardProps) => {
  const {data, hasHover} = props;
  const {previewImage, url, contentType, presentationUrl, registrationUrl, slug} = data;
 
  const renderHover = () => {
    switch (contentType) {
      case 'tech-talk':
        return (
          <HoverContainer>
            {url ?
              <>
                {presentationUrl && <Button href={presentationUrl} target="_blank" >VER PRESENTACION</Button>}
                {url && <Button hasMarginTop={!!presentationUrl} href={url} target="_blank" >VER EN YOUTUBE</Button>}
              </>
            :
              <HoverTitle onlyTitle>Proximamente en vivo</HoverTitle>
            }
          </HoverContainer>
        )
        break;
      case 'training-palooza':
        return (
          <HoverContainer>
            {url ?
                <Button hasMarginTop={!!presentationUrl} href={url} target="_blank" >VER TRAINING</Button>
              :
                <>
                  {registrationUrl ?
                      <>
                        <HoverTitle hasRegistrationUrl>PROXIMAMENTE</HoverTitle>
                        {registrationUrl && <Button href={registrationUrl} target="_blank" hasRegistrationUrl>INSCRIBITE AQUI</Button>}
                      </>
                    :
                      <HoverTitle onlyTitle>PROXIMAMENTE</HoverTitle>
                  }
                </>
            }
          </HoverContainer>
        )
        break;
      default:
        return (<> </>);
        break;
    }
  };

  return (
    (hasHover ?
      <>
        {previewImage ?
          <CardContainer isTraining={contentType==="training-palooza"} >
            {previewImage.url && <Image src={previewImage.url} alt='card_photo'/>}
            {renderHover()}
          </CardContainer>
          :
          <></>
        }
      </>
    :
      <CardContainer>
        {previewImage ?
          <>
            { slug ?
              <InternalLink to={`${contentType}/${slug}`}>
                {previewImage.url && <Image src={previewImage.url} alt='card_photo'/>}
              </InternalLink>
              :
              <ExternalLink href={url} target="_blank">
                {previewImage.url && <Image src={previewImage.url} alt='card_photo'/>}
              </ExternalLink>
            }
          </>
          :
          <></>
        }
      </CardContainer>
    )
  );
};

export default ImageCard;

ImageCard.defaultProps = {
  hasHover: false
};
