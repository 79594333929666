import { getIdToken } from 'firebase/auth';

export const getGoogleToken = async(user) => {
  return getIdToken(/* forceRefresh */ user).then(function(idToken) {
    // Send token to your backend via HTTPS
    // ...
    return idToken;
  }).catch(function(error) {
    return error
    // Handle error
  });
}
