import { AxiosResponse } from 'axios';

import { get } from 'services/api';

export async function getNewsletterPosts(): Promise<AxiosResponse> {
  const response = await get('monthly-newsletters');
  return response;
}

export async function getPostBySlug(slug: string): Promise<AxiosResponse> {
  const response = await get(`monthly-newsletters?slug=${slug}`);
  return response;
}

export async function  getNewsletterCards(limit: number): Promise<AxiosResponse> {
  const response = await get(`monthly-newsletters-cards?${limit}&sort=published_at:DESC`);
  return response;
}
