import React, { FunctionComponent } from 'react';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { SecondaryTitle } from 'styles/commons';

import RespetoIcon from './assets/respeto (1).svg' 
import RendimientoIcon from './assets/rendimiento.svg' 
import IteracionIcon from './assets/iteracion.svg' 
import CrecimientoIcon from './assets/crecimiento.svg' 
import ComunicacionIcon from './assets/comunicacion.svg' 

import { ItemsContainer, ItemDiv, IconRespeto, ItemText, IconContainer, IconRendimiento, IconIteracion, IconCrecimiento, IconComunicacion, SliderContainer } from './styles';

const Input: FunctionComponent<any> = () => {
    const settings = {
        infinite: false,
        speed: 200,
        autoplaySpeed: 6000,
        variableWidth: false,
        adaptiveHeight: false,
        slidesToScroll: 1,
        arrows: false,
        autoplay: false,
        dots: false,
        responsive: [
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 5,
            },
          },
          {
            breakpoint: 740,
            settings: {
              slidesToShow: 4,
            },
          },
          {
            breakpoint: 460,
            settings: {
              slidesToShow: 3,
            },
          },
        ],
      };
    
  return (
    <>
    <SecondaryTitle>Manifesto</SecondaryTitle>
    <ItemsContainer> 
        <ItemDiv>
            <IconContainer>
                <IconRespeto src={RespetoIcon} alt="Respeto" />
            </IconContainer>
            <ItemText>Respeto</ItemText>
        </ItemDiv>
        <ItemDiv>
            <IconContainer>
                <IconComunicacion src={ComunicacionIcon} alt="Comunicación" />
            </IconContainer>
            <ItemText>Comunicación</ItemText>
        </ItemDiv>
        <ItemDiv>
            <IconContainer>
                <IconCrecimiento src={CrecimientoIcon} alt="Crecimiento" />
            </IconContainer>
            <ItemText>Crecimiento</ItemText>
        </ItemDiv>
        <ItemDiv>
            <IconContainer>
                <IconIteracion src={IteracionIcon} alt="Iteración" />
            </IconContainer>
            <ItemText>Iteración</ItemText>
        </ItemDiv>
        <ItemDiv>
            <IconContainer>
                <IconRendimiento src={RendimientoIcon} alt="Rendimiento" />
            </IconContainer>
            <ItemText>Rendimiento</ItemText>
        </ItemDiv>
    </ItemsContainer>
    <SliderContainer>
        <Slider dots /* customPaging={(i) => <button>{i + 1}</button>} */ {...settings}>
            <ItemDiv>
                <IconContainer>
                    <IconRespeto src={RespetoIcon} alt="Respeto"/>
                </IconContainer>
                <ItemText>Respeto</ItemText>
            </ItemDiv>
            <ItemDiv>
                <IconContainer>
                    <IconComunicacion src={ComunicacionIcon} alt="Comunicación" />
                </IconContainer>
                <ItemText>Comunicación</ItemText>
            </ItemDiv>
            <ItemDiv>
                <IconContainer>
                    <IconCrecimiento src={CrecimientoIcon} alt="Crecimiento" />
                </IconContainer>
                <ItemText>Crecimiento</ItemText>
            </ItemDiv>
            <ItemDiv>
                <IconContainer>
                    <IconIteracion src={IteracionIcon} alt="Iteración" />
                </IconContainer>
                <ItemText>Iteración</ItemText>
            </ItemDiv>
            <ItemDiv>
                <IconContainer>
                    <IconRendimiento src={RendimientoIcon} alt="Rendimiento" />
                </IconContainer>
                <ItemText>Rendimiento</ItemText>
            </ItemDiv>
        </Slider>
    </SliderContainer>
    </>
  );
};

export default Input;