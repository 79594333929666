import {DESKTOP_1024, MOBILE_575 } from 'constants/sizes';
import { COLORS } from 'helpers/enums/colors';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex:1;
  height: calc(100vh - 85px); // layout padding top = 85px 
  justify-content:center;
  align-items: center;
  flex-direction: column;
  @media (min-height: 900px) {
    justify-content:left;
    padding-top: 200px;
  }
`;

export const InputWrapper = styled.div`
  width: 100%;
  max-width: 875px;
  height: fit-content;
  @media (max-width: ${MOBILE_575}px) {
    width: 80%;
  }
`;

export const Title = styled.h1`
  font: 900 38px/44px roboto-black;
  width: 100%;
  text-align: center;
  margin-bottom: 40px;
  color: ${COLORS.white};
  @media (max-width: ${DESKTOP_1024}px) {
    font: 900 35px/40px roboto-black;
  }
  @media (max-width: ${MOBILE_575}px) {
    font: 500 28px/32px roboto-medium;
  }

`;

