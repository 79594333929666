import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { COLORS } from 'helpers/enums/colors';
import { TABLET_600, TABLET_992 } from 'constants/sizes';

import { ReactComponent as PshLogo } from 'views/LoginView/assets/logo.svg';

export const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 35px 0;
  margin: 0 auto;
  height: 100vh;
  background: ${({ theme }) => theme.linearGradient} linear-gradient(100deg, ${COLORS.cherry} 0%, ${COLORS.purple} 100%) 0% 0%
    no-repeat padding-box;
  position: relative;
`;

export const LoginContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;

  @media (max-width: ${TABLET_992}px) {
    width: 70%;
  }

  @media (max-width: ${TABLET_600}px) {
    width: 100%;
  }
`;

export const LoginFormContainer = styled.div`
  margin-top: 30px;
`;

export const SignUpContainer = styled.div`
  width: 100%;
  font-size: 14px;
  line-height: 1.5;
  text-align: center;
`;

export const SignUpLink = styled(Link)`
  color: ${COLORS.greenChristi};
  margin-left: 5px;

  :hover {
    color: ${COLORS.gray};
  }
`;

export const ErrorMessage = styled.div`
  width: 350px;
  height: 20px;
  margin: 20px;
  font-size: 16px;
  color: ${COLORS.lightPurple};
`;

export const Logo = styled(PshLogo)`
  width: 171px;
  height: 171px;
`;

export const Title = styled.h1`
   font-size: 38px;
   color: ${COLORS.white};
   font-weight: 600;
   margin-top: 53px;
   text-align: center;
   line-height: 38px;

   @media (max-width: ${TABLET_600}px) {
    font-size: 28px;
  }
`;

export const Text = styled.div`
  max-width: 600px;
  font-size: 28px;
  line-height: 36px;
  color: ${COLORS.white};
  margin-top: 15px;
  width: 70%;
  justify-content: center;
  display: flex;
  text-align: center;

  @media (max-width: ${TABLET_600}px) {
    font-size: 20px;
  }
`;

export const ShadowDiv = styled.div`
  position: absolute;
  height: 100vh;
  width: 100%;
  bottom: 0;
  color: black;
  opacity: 0.1
`;