import React, { FunctionComponent } from 'react';
import { GoogleAuthProvider, signInWithPopup, getAuth } from 'firebase/auth';

import { initializeApp } from 'firebase/app';
import { FIRASEBASE_CONFIG } from 'configs/configs';

//  import { IFirebaseUser } from 'types/user.types';

import { LoginButton } from './styles';

const GoogleSSO: FunctionComponent = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const app = initializeApp(FIRASEBASE_CONFIG);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const provider = new GoogleAuthProvider();
  const auth = getAuth();

  const openLoginModal = () => {
    provider.setCustomParameters({
      prompt: 'select_account',
    });
    signInWithPopup(auth, provider).catch(() => {
      // eslint-disable-next-line
      console.error('error');
    });
  };

  return (
    <>
      <LoginButton onClick={openLoginModal}> ACCEDER A MyPSh </LoginButton>
    </>
  );
};

export default GoogleSSO;

/*
import React, { FunctionComponent } from 'react';
import { getAuth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';

import { UserContext } from 'context/UserContext';

import { initializeApp } from 'firebase/app';
import { FIRASEBASE_CONFIG } from 'configs/configs';

//  import { IFirebaseUser } from 'types/user.types';

import { LoginButton } from './styles';

const GoogleSSO: FunctionComponent = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const app = initializeApp(FIRASEBASE_CONFIG);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const provider = new GoogleAuthProvider();
  const auth = getAuth();

  const openLoginModal = () => {
    provider.setCustomParameters({
      prompt: 'select_account',
    });
    signInWithPopup(auth, provider).catch(() => {
      console.log('aca');
    });
  };

  return (
    <>
      <UserContext.Consumer>
        {(userContext) => (
          <>
            <h1>{userContext.user?.email}</h1>
            <LoginButton onClick={openLoginModal}> Login with Google </LoginButton>
          </>
        )}
      </UserContext.Consumer>
    </>
  );
};

export default GoogleSSO;
*/
