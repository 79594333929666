import styled, { css } from "styled-components";
import { TABLET_768, TABLET_992 } from "constants/sizes";
import { ReactComponent as Arrow } from './assets/faq-arrow-down.svg';

export const CommonStyle = styled.div`
  border-radius: 10px;
`
export const QAContainer = styled(CommonStyle)`
  margin-top: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: transparent;
`
export const BodyContainer = styled.div`
  cursor: pointer;
  box-sizing: border-box;
  padding: 2%;
  margin: 1% 0;
  border-radius: 10px;
  background-color:  ${({ theme }) => theme.faqCardBackgroundColor};
  display: flex;
  justify-content: space-between;
`
export const FaqBox = styled.div`
  width: 90%;
  padding-left: 6%;

  @media (max-width: ${TABLET_768}px) {
    padding-left: 1%;
  }
`
export const QSubtitle = styled.div`
  color:  ${({ theme }) => theme.title};
  text-align: left;
  font: bold 30px/40px montserrat-semi-bold;

  @media (max-width: ${TABLET_768}px) {
    font: bold 22px/26px montserrat-semi-bold;
  }
`

export const Text = styled.div<IDivProps>`
  color:  ${({ theme }) => theme.globalText};
  ${({ activeQuestion }) =>
    activeQuestion
      ? css`
          display: block;
          visibility: visible;
          height: fit-content;
        `
      : css`
          display: none;
          visibility: hidden;
          height: 0;
        `
  }
`
export const ArrowContainer = styled.div`
  width: 50px;
  height: 100%;
  padding-top: 10px;
  text-align: center;

  @media (max-width: ${TABLET_768}px) {
    padding-top: 5px;
  }
`
export const ArrowIcon = styled(Arrow)<IDivProps>`
  ${({ activeQuestion }) => activeQuestion ? css`transform: rotate(0deg);` : css`transform: rotate(180deg);`}

  @media (max-width: ${TABLET_768}px) {
    width: 30px;
  }
`

// for the skeleton 
export const QDesktopSkeleton = styled.div`
  .FaqCardSkeleton {
    height: 28px;
  }
  @media (max-width: ${TABLET_992}px) {
    display: none;
  }
`
export const QMobileSkeleton = styled.div`
  display: none;
  @media (max-width: ${TABLET_992}px) {
    display: block;
  }
  @media (max-width: ${TABLET_768}px) {
  .FaqCardSkeleton {
    height: 20px;
  }
}
`


interface IDivProps {
  activeQuestion?: boolean | null;
}
