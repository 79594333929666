import React, { FunctionComponent } from 'react';
import Input from 'components/inputs/Input'

import { IInputProps } from './types';

import { Container, Title, InputWrapper } from './styles';

const SearchView: FunctionComponent<IInputProps> = (props: IInputProps) => {
  const { input } = props;

  const meta = {
    touched: false,
    error: 'string',
  };

  return (
    <Container>
        <Title>
          Realiza una búsqueda
        </Title>
          <InputWrapper>
            <Input variant="filled" input={input} meta={meta}/>
          </InputWrapper>
    </Container>
  );
};

export default SearchView;

