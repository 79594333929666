import styled from "styled-components";
import { TABLET_992 } from "constants/sizes";
import { COLORS } from "helpers/enums/colors";

export const Container = styled.div<IListButtonProps>`
    width: 100%;
    position: relative;
    font: bold 20px/24px 'montserrat-bold';
    display: none;
    @media (max-width: ${TABLET_992}px) {
        display: block;
    }
`

export const DropdownButton = styled.button<IListButtonProps>`
    width: 100%;
    margin: 10px 0;
    padding: 20px 20px;
    font: bold 20px/24px 'montserrat-bold';
    color: ${COLORS.white};
    border: none;
    border-radius: ${({ isOpen }) => isOpen ? '8px 8px 0 0' : '8px' };
    background: ${({ theme, filter }) => filter === 'SOS' ? COLORS.cherry : theme.purpleCalendar};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: left;
    &>svg{
        transition: 0.25s;
        transform: ${({ isOpen }) => isOpen ? 'rotate(0deg)' : 'rotate(180deg)' };
        height: 20px;
    }
`
export const List = styled.div<IListButtonProps>`
    width: 100%;
    height: ${({ isOpen }) => isOpen ? 'auto' : 0 };
    position: absolute;
    bottom: 1px;
    z-index: 5;
    border-radius: 0 0 8px 8px;
    background: ${({ theme }) => theme.purpleCalendar};
    transform: translateY(100%);
    transition: 0.5s;
    overflow: hidden;
`

export const WhiteLine = styled.div`
    width: 90%;
    height: 1px;
    margin: 0 auto;
    background: ${COLORS.lightPurple};
`

export const StyledFilterButton = styled.button<IListButtonProps>`
    width: 100%;
    padding: 15px 20px;
    font: bold 20px/24px 'montserrat-bold';
    color: ${COLORS.white};
    text-align: left;
    background: ${({ theme, filter }) => filter === 'SOS' ? theme.redSeeAll : theme.purpleCalendar };
    border: none;
    display: block;
    &:hover {
        background: ${({ theme, filter }) => filter === 'SOS' ? theme.redSeeAll : theme.darkPurple };
    }
`

interface IListButtonProps {
    filter?: string;
    isOpen?: boolean;
}
