import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { DESKTOP_1440, TABLET_768 } from 'constants/sizes';
import { COLORS } from 'helpers/enums/colors';

export const Container = styled.div<IContainerProps>`
  width: 5vw;
  height: 85vh;
  min-width: 70px;
  max-width: 90px;
  margin-right: 30px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  z-index: 2;
  position: sticky;
  top: ${( {isPromobarDisplayed, isPromobarOpen} ) => (isPromobarDisplayed && isPromobarOpen) ? `65px` : `20px`};

  @media (min-width: ${DESKTOP_1440}px) {
    max-width: 110px;
  }
  @media (max-width: ${TABLET_768}px) {
    display: none;
  }
  @media (max-height: 628px) {
    display: none;
  }
`;

export const ItemsSection = styled.div`
  padding: 12px 10px;
  border-radius: 10px;
  box-shadow: 0px 10px 20px ${({ theme }) => theme.boxShadow};
  background-color: ${({ theme }) => theme.cardBackgroundColor};
  display: flex;
  flex-direction: column;
`;

export const Logo = styled(Link)`
  text-decoration: none;
  &:focus {
    outline: none;
  }
  & img {
    width: 100%;
    margin: auto;
  }
`;

export const ItemList = styled.ul`
  margin-top: 20px;
  width: 100%;
  height: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  @media (min-width: ${DESKTOP_1440}px) {
    height: 450px;
  }
`;

export const Item = styled(Link)`
  text-align: center;
  text-decoration: none;
  color: ${COLORS.black};
  &:focus {
    outline: none;
  }
  &:hover img{
    filter: none;
  }
  & p, & div{
    word-break: keep-all;
    font-size: 0.6rem;
    margin-top: 8px;
    text-decoration: none;
    color: ${({ theme }) => theme.title};
  }
`;

export const MenuIcons = styled.img`
  width: 25px;
  height: auto;
  margin: auto;
  filter: ${({ theme }) => theme.menuIcons};
  transition: 0.2s;
  @media (min-width: ${DESKTOP_1440}px) {
    width: 40px;
  }
`;

export const SocialMedia = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: 10px;
  align-items: center;
  & a {
    width: fit-content;
  }
  @media (max-height: 670px) {
    margin-top: 5px;
  }
  & svg {
    @media (min-width: ${DESKTOP_1440}px) {
      width: 28px;
      margin: 5px auto;
    }
    @media (max-height: 700px) {
      width: 20px;
      margin: 2px auto;
    }
    color: white;
    width: 25px;
    height: auto;
    margin: 5px auto;
    filter: drop-shadow(0 0 3px rgba(0,0,0,0.50));
    cursor: pointer;
  }
`;

export const ContainerMobile = styled.div<IContainerProps>`
  display: none;
  @media (max-width: ${TABLET_768}px) {
    display: flex;
    background: #f0f8ff;
    position: fixed;
    z-index: 4;
    padding: 18px 20px 21px 20px;
    font-size: 20px;
    flex-direction: column;
    box-shadow: 0px 3px 6px black;
    height: 90%;
    top: ${( {isPromobarDisplayed, isPromobarOpen} ) => (isPromobarDisplayed && isPromobarOpen) ? `50px` : `0px`};
    left: 0;
    width: 100%;
    transition: transform 0.5s ease-in-out;
  }
`;

interface IContainerProps {
  isPromobarDisplayed?: boolean;
  isPromobarOpen?: boolean;
}