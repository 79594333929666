import React from 'react'
import { Quote, Container, Author } from './styles'
import { IQuote } from './types'

export const BlockQuote: React.FunctionComponent<IQuote> = ({ data }) => {

  return (
		<Container>
			<Quote>
				{data.text}
				<Author>
				—— {data.author}
				</Author>
			</Quote>
			
		</Container>
  )
}
