import React, { FunctionComponent, useEffect, useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import LayoutContainer from 'containers/LayoutContainer';
import PostView from 'views/PostView';
import Loading from 'components/commons/Loading';

import { getPostBySlug } from 'services/friendlyReminders.service';
import { getPostBySlug as getWellnessBySlug } from 'services/wellness.service';
import { getPostBySlug as getSolidarioBySlug } from 'services/psh_solidario.service';
import { getPostBySlug as getLandingPageBySlug } from 'services/landingPages.service';

import { IStrapiComponent } from 'views/PostView/types';

const PostContainer: FunctionComponent = ({contentType}: any) => {
  const { slug } = useParams<any>();
  const [postLanding, setPostLanding] = useState<IStrapiComponent[]>([]);
  const [loadingData, setLoadingData] = useState<boolean>(true);
  
  const fetchPostLanding = async (postSlug: string) => {
    const fetechedPostSlug = await getPostBySlug(postSlug);
    setPostLanding(fetechedPostSlug.data);
    setLoadingData(false);
  };

  const fetchLandingPage = async (postSlug: string) => {
    const fetechedPostSlug = await getLandingPageBySlug(postSlug);
    setPostLanding(fetechedPostSlug.data);
    setLoadingData(false);
  };

  const fetchWellnessLanding = async (postSlug: string) => {
    const fetechedPostSlug = await getWellnessBySlug(postSlug);
    setPostLanding(fetechedPostSlug.data);
    setLoadingData(false);
  };

  const fetchPshSolidario = async (postSlug: string) => {
    const fetechedPostSlug = await getSolidarioBySlug(postSlug);
    setPostLanding(fetechedPostSlug.data);
    setLoadingData(false);
  };

  const isLoading = () => {
    return (
      loadingData
    );
  };
  

  const renderPost = () => {
    return (
      ((postLanding.length > 0) && (postLanding[0].body.length > 0)) ? <PostView data={postLanding[0]} contentType={contentType} isLoading={loadingData}/>
       :
        <Redirect to="/" />
    );
  };

  useEffect(() => {
    switch (contentType) {
      case 'psh-solidario':
         fetchPshSolidario(slug);
        break;
      case 'friendly-reminder':
          fetchPostLanding(slug);
         break;
      case 'wellness':
        fetchWellnessLanding(slug);
        break;
      case 'landing-page':
          fetchLandingPage(slug);
      break;
      default:
        break;
    }
  }, [slug, contentType]);

  return (
    <LayoutContainer>
      {isLoading() ? (
        <Loading />
      ) : (
        renderPost()
      )}
    </LayoutContainer>
  );
};

export default PostContainer;
