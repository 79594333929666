import React, { FunctionComponent, useEffect } from "react";

import { Background, GridContainer, GridItem, TeamTitle } from "styles/commons";
import { COLORS } from "helpers/enums/colors";

import Referrals from './assets/referidos.svg';
import Careers from './assets/careers.svg';
import Benefits from './assets/beneficios.svg';
import TrainingPalooza from './assets/trainingpalooza-logo.svg';

import {
  Section,
  AdminBoxTitle,
  AdminBoxTitleNormal,
  PeopleBoxTitle,
  PeopleBoxes,
  Box,
  CareerReferralsTitle,
  ReferralsTitle,
  TextSection,
  TextPeople,
  ExternalLink,
  TwoItemsGridContainer,
  TrainingPaloozaIcon,
  TrainingPaloozaImage,
  InnerLink,
  PeopleIcon,
  PeopleImage,
} from './styles'

import { IRenderPeopleAndAdminProps } from "./types";

export const RenderPeopleAndAdmin: FunctionComponent<IRenderPeopleAndAdminProps> = React.memo(({ scrollToPeopleAndAdmin }: IRenderPeopleAndAdminProps) => {
  const PeopleAndAdmin = React.useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (scrollToPeopleAndAdmin && PeopleAndAdmin.current) {
      PeopleAndAdmin.current.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [scrollToPeopleAndAdmin]);
  
  return(
    <Background marginTop='60px' hasMarginBottom ref={PeopleAndAdmin}>
      <Section>
        <TeamTitle>Admin</TeamTitle>
        <GridContainer gapSize="30px 5px">
          <GridItem spanSize={6} startColumn={0} spanSizeTablet={18} spanSizeMobile={18}>
            <InnerLink to="/content/solicitud-licencias">
              <Box color={COLORS.cherry}>
                <AdminBoxTitle>
                  <AdminBoxTitleNormal>SOLICITUD DE</AdminBoxTitleNormal>
                  LICENCIAS
                </AdminBoxTitle>
              </Box>
            </InnerLink>
          </GridItem>
          <GridItem spanSize={6} startColumn={0} spanSizeTablet={18} spanSizeMobile={18}>
            <InnerLink to="/faq-admins">
              <Box color={COLORS.cherry}>
                <AdminBoxTitle>
                  <AdminBoxTitleNormal>¿NECESITÁS SOPORTE?</AdminBoxTitleNormal>
                  ADMIN FAQ
                  <AdminBoxTitleNormal>HELP!</AdminBoxTitleNormal>
                </AdminBoxTitle>
              </Box>
            </InnerLink>
          </GridItem>
          <GridItem spanSize={6} startColumn={0} spanSizeTablet={18} spanSizeMobile={18}>
            <ExternalLink href="https://docs.google.com/presentation/d/1v5PwMPjbYTdZDywwW5b4JS4PE9p-THq60LOibfpf9As/edit?usp=sharing" target="_blank">
              <Box color={COLORS.cherry}>
                <AdminBoxTitle>
                  <span>Payroll</span>
                  <AdminBoxTitleNormal>TIPS</AdminBoxTitleNormal>
                </AdminBoxTitle>
              </Box>
            </ExternalLink>
          </GridItem>
        </GridContainer>
      </Section>
      <Section marginTop="50px">
        <TeamTitle isPurple>People</TeamTitle>
        <PeopleBoxes>
          <GridItem startColumn={0} spanSizeTablet={9} spanSizeMobile={9}>
            <ExternalLink href="https://sites.google.com/wearepsh.com/pshrecruiting/inicio" target="_blank">
              <Box color={COLORS.purple}>
                <TextSection>
                  <CareerReferralsTitle marginTop='12px'>Careers</CareerReferralsTitle>
                  <TextPeople color={COLORS.white}>Chequeá todas las posiciones que tenemos disponibles</TextPeople>
                </TextSection>
                <PeopleIcon>
                  <PeopleImage src={Careers} alt="careers"/>
                </PeopleIcon>
              </Box>
            </ExternalLink>
          </GridItem>
          <GridItem startColumn={0} spanSizeTablet={9} spanSizeMobile={9}>
            <ExternalLink href="https://docs.google.com/forms/d/e/1FAIpQLSdPM3U9P1udqkwfpDoKIjz2UggAT6qT4SAkwmZySudXNNik5Q/viewform?usp=sf_link" target="_blank">
              <Box color={COLORS.purple}>
                <TextSection>
                  <CareerReferralsTitle>
                    <ReferralsTitle>Programa</ReferralsTitle>
                    <span>Referidxs!</span>
                  </CareerReferralsTitle>
                  <TextPeople color={COLORS.white}>Ayudanos a agrandar el equipo recomendando a tus conocidos</TextPeople>
                </TextSection>
                <PeopleIcon>
                  <PeopleImage src={Referrals} alt="referrals"/>
                </PeopleIcon>
              </Box>
            </ExternalLink>
          </GridItem>
          <GridItem startColumn={0} spanSizeTablet={9} spanSizeMobile={9}>
          <InnerLink to="/content/beneficios-psh/">
              <Box color={COLORS.white}>
                <TextSection>
                <CareerReferralsTitle marginTop='12px' isPurple>BENEFITS</CareerReferralsTitle>
                <TextPeople color={COLORS.black}>Conocé todos los beneficios que tenemos para ofrecerte</TextPeople>
                </TextSection>
                <PeopleIcon>
                  <PeopleImage src={Benefits} alt="benefits"/>
                </PeopleIcon>
              </Box>
            </InnerLink>
          </GridItem>
          <GridItem startColumn={0} spanSizeTablet={9} spanSizeMobile={9}>
            <ExternalLink href="https://docs.google.com/presentation/d/1VyrvHjYWGgeHtVruw8npp4aYVBps-qrISOwddqvu3bc/edit?usp=sharing" target="_blank">
              <Box color={COLORS.white} isFlexColumn>
                <PeopleBoxTitle marginTop='12px'>PROGRAMA INTEGRAL DE DESEMPEÑO</PeopleBoxTitle>
                <TextPeople color={COLORS.black}>¿Qué es y cómo podemos ayudarte a realizarlo?</TextPeople>
              </Box>
            </ExternalLink>
          </GridItem>
        </PeopleBoxes>
        <TwoItemsGridContainer>
          <GridItem startColumn={0} spanSizeTablet={9} spanSizeMobile={9}>
            <InnerLink to="/training-palooza">   
              <Box color={COLORS.black} isFlexColumn>
                <TrainingPaloozaIcon>
                  <TrainingPaloozaImage src={TrainingPalooza} alt="training palooza"/>
                </TrainingPaloozaIcon>
                <TextPeople color={COLORS.white}>
                  Ampliá tus conocimientos con los diferentes recursos que te dejamos acá.
                </TextPeople>
              </Box>
            </InnerLink>
          </GridItem>
          <GridItem startColumn={0} spanSizeTablet={9} spanSizeMobile={9}>
            <InnerLink to="/content/tips-remoto"> 
              <Box color={COLORS.black} isFlexColumn>
                <PeopleBoxTitle marginTop='10px'>Tips trabajo remoto</PeopleBoxTitle>
                <TextPeople color={COLORS.white}>Llevá al máximo tu productividad desde casa con estos tips</TextPeople>
              </Box>
            </InnerLink>
          </GridItem>
        </TwoItemsGridContainer>
      </Section>
    </Background>
  )
})
