import styled from 'styled-components';

import { DESKTOP_1200, DESKTOP_1024, TABLET_992, TABLET_800, MOBILE_575  } from 'constants/sizes';

export const Title = styled.div`
    font-size: 38px;
    font-weight: bold;
    font-family: 'montserrat-bold';
    color: ${({ theme }) => theme.fontColor};
`;

export const ItemsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 0px 5%;
    margin-top: 32px; 

    @media screen and (max-width: ${TABLET_992}px) {
        display: none;
    }
`;

export const ItemDiv = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const ItemText = styled.div`
    margin-top: 14px;
    font: 600 22px montserrat;
    color: ${({ theme }) => theme.title};
    text-align: center;
    @media screen and (max-width: ${DESKTOP_1200}px) {
        font-size: 18px;
    }
    @media screen and (max-width: ${DESKTOP_1024}px) {
        margin-top: 10px;
        font-size: 14px;
    }
    @media screen and (max-width: ${MOBILE_575}px) {
        margin-top: 5px;
        font-size: 12px;
    }
`;

export const IconContainer = styled.div`
    background: transparent linear-gradient(135deg, #7971FA 0%, #EF233C 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 10px 20px ${({ theme }) => theme.boxShadow};
    border-radius: 105px;
    width: 120px;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    @media screen and (max-width: ${DESKTOP_1200}px) {
        width: 100px;
        height: 100px;
    }
    @media screen and (max-width: ${TABLET_992}px) {
        width: 85px;
        height: 85px;
    }
    @media screen and (max-width: ${TABLET_800}px) {
        width: 75px;
        height: 75px;
    }
`;

export const IconRespeto = styled.img`
    width: 65%;
    height: auto;
`;

export const IconRendimiento = styled.img`
    width: 60%;
    height: auto;
`;

export const IconIteracion = styled.img`
    width: 65%;
    height: auto;
`;

export const IconCrecimiento = styled.img`
    width: 70%;
    height: auto;
`;

export const IconComunicacion = styled.img`
    width: 65%;
    height: auto;
`;

export const SliderContainer = styled.div`
    display: none;

    @media screen and (max-width: ${TABLET_992}px) {
        display: block;
        width: 100%;
        margin: 20px 0 50px 0;
    }
`;