import React, {FunctionComponent, useState, useEffect } from 'react';
import { HOME, SEARCH, VACATIONS, STAFF_DIRECTORY } from 'constants/urls';

import ProfilePicture from 'components/commons/ProfilePicture';

import myPshLogo from 'views/LoginView/assets/logo.svg';
import { ReactComponent as Hamburguer } from './assets/menu-h.svg';
import { ReactComponent as Cross } from './assets/menu-x.svg';
import { ReactComponent as InstagramLogo } from './assets/instagram-brands.svg';
import { ReactComponent as LinkedinLogo } from './assets/linkedin-brands.svg';
import { ReactComponent as YoutubeLogo } from './assets/youtube-brands.svg';
import { ReactComponent as TwitchLogo } from './assets/twitch-brands.svg';

import { Container, Logo, MenuLogo, Item, ItemsSection, ItemList, SocialMedia} from './styles';

const MobileMenu: FunctionComponent<IMobileMenuProps> = ({isPromobarDisplayed, isPromobarOpen} : IMobileMenuProps) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if(isOpen){
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'visible';
    }
  }, [isOpen]);

  return (
    <>
      <Container isPromobarDisplayed={isPromobarDisplayed} isPromobarOpen={isPromobarOpen}>
        <MenuLogo onClick={() => {setIsOpen(!isOpen)}}>
          {
            isOpen 
            ? <Cross/>
            : <Hamburguer/>
          }
        </MenuLogo>
        <Logo to={HOME}>
            <img src={myPshLogo} alt="We are P S H" />
        </Logo>
        <ProfilePicture mobile />
      </Container>

      <ItemsSection isOpen={isOpen} isPromobarDisplayed={isPromobarDisplayed} isPromobarOpen={isPromobarOpen}>
        <ItemList>
            <Item to={`${HOME}?scrollTo=News`} onClick={() => {setIsOpen(!isOpen)}} >
                NEWS
            </Item>
            <hr />
            <Item to={`${HOME}?scrollTo=PshTeam`} onClick={() => {setIsOpen(!isOpen)}} >
                PSH TEAM
            </Item>
            <hr />
            <Item to={`${HOME}?scrollTo=PeopleAndAdmin`} onClick={() => {setIsOpen(!isOpen)}} >
                PEOPLE &amp; ADMIN
            </Item>
            <hr />
            <Item to={`${STAFF_DIRECTORY}`} onClick={() => {setIsOpen(!isOpen)}} >
                STAFF
            </Item>
            <hr />
            <Item to={`${VACATIONS}`} onClick={() => {setIsOpen(!isOpen)}} >
                PTO
            </Item>
            <hr />
            <Item to={`${SEARCH}`} onClick={() => {setIsOpen(!isOpen)}} >
                SEARCH
            </Item>
            <hr />
        </ItemList>
        <SocialMedia>
            <a href="https://www.instagram.com/wearepsh/" target="_blank" rel="noreferrer" >
              <InstagramLogo />
            </a>
            <a href="https://ar.linkedin.com/company/wearepsh" target="_blank" rel="noreferrer" >
              <LinkedinLogo />
            </a>
            <a href="https://www.youtube.com/channel/UCxHM9n8wjMbN3l3YnRkrf8A" target="_blank" rel="noreferrer" >
              <YoutubeLogo />
            </a>
            <a href="https://www.twitch.tv/wearepsh" target="_blank" rel="noreferrer" >
              <TwitchLogo />
            </a>
        </SocialMedia>
      </ItemsSection>
    </>

  );
};

export default MobileMenu;

export interface IMobileMenuProps {
  isPromobarDisplayed: boolean;
  isPromobarOpen: boolean;
}
