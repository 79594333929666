import React from 'react';

import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import { Box, GridContainer } from './styles';

const SkeletonTemplate = () => {
    return (
        <SkeletonTheme baseColor='rgba(85, 85, 85, 0.541)' highlightColor='rgba(85, 85, 85, 0.541)'> 
            <GridContainer>
                <Box>
                   <Skeleton className="" style={{width: `100%`, height: '220px'}}/>
                </Box>
                <Box>
                   <Skeleton className="" style={{width: `100%`, height: '220px'}}/>
                </Box>
                <Box>
                   <Skeleton className="" style={{width: `100%`, height: '220px'}}/>
                </Box>
                <Box>
                   <Skeleton className="" style={{width: `100%`, height: '220px'}}/>
                </Box>
            </GridContainer>
        </SkeletonTheme>
    )
}

export default SkeletonTemplate;
