import React, { useState, FunctionComponent, useEffect } from 'react';
import { Route, Switch, SwitchProps } from 'react-router-dom';
import { NotificationContainer } from 'react-notifications';

import TagManager from 'react-gtm-module';

import { getAuth, signOut } from 'firebase/auth';
import { initializeApp } from 'firebase/app';
import { useAuthState } from 'react-firebase-hooks/auth';

import { FIRASEBASE_CONFIG, GTM_ID } from 'configs/configs';
import { IFirebaseUser } from 'types/user.types';

import { UserContext } from 'context/UserContext';

import { setItem, getItem } from 'helpers/storage';

import LoginRoutes from 'routes/Login';
import UserRoutes from 'routes/User';
import ScrollToTopContainer from 'containers/ScrollToTopContainer';
import ErrorView from 'views/ErrorView';
import Loading from 'components/commons/Loading';
import { ThemeProvider } from 'styled-components';
import 'react-notifications/lib/notifications.css';
import GlobalStyles from 'components/globalStyles';
import { lightTheme, darkTheme } from 'styles/themes.styles';

// eslint-disable-next-line
const asciiLogo =
`...........    ..       ..     ...........     ...........      
'OKkkkkkkkkc.  .kk.     'Ox.   .xKOkkkkkOKk.   .dKOkkkkkkKO'     
,KO'.......    '0O'     ,Kk.   .O0;.....;00'   .kX:.....'OK,     
,Kk.           '0O'     ,Kk.   .O0'     '00'   .kK,     .kK,     
,KKc;;;;;;;.   '0O.     ,KO.   .ONkoooookX0'   .kK;     .kK,     
,KXkxxxxxxxc.  '0O.     ,Kk.   .ON0dlccccc;.   .kK;     .kK,     
,Kk.           '0O.     ,KO.   .ONKOo'         .kK;     .kK,     
,Kk.           '0O'     ,Kk.   .O0:cO0o'       .kK,     .kK,     
,Kk.           '0O'     ,Kk.   .O0' .cO0d'     .kK;     .kK,     
,KXxooooddo:.  'ONkdddddONk.   .O0'   .cO0d'   .kNOoddooxXK,     
.;ccccccccc,   .,c:cccc:cc,    .,;.     .;:'    ,ccccccccc;.     
                                                                 
        .........        ..........        ......                
      .o0OkkkkkkOx.    .d0kkkkkkkk0d.    .xOkkkkOk:.             
      .kXc.....;0K,    'OK:......:K0'    ,K0:...,d0k;            
      .kK,     .kK,    '00'      '00'    ,KO.     :Xk.           
      .kNxcccccoKK,    '00'      '00'    ,KO.     ,Kk.           
      .kNkoooooooc.    '00'      '00'    ,Kk.     ,Kk.           
      .kK,             '00'      '00'    ,KO.     ,Kk.           
      .kK,             .O0'      '00'    ,KO.     ,Kk.           
      .kK,             '00'      'O0'    ,Kk.    .oXx.           
      .kK,             '0XdccccccdX0'    ,KKdcccoO0l.            
       ;c.             .:oooooooooo:.    .cooooooc.              
                                                                 
                                                                 
`;

const Application: FunctionComponent<SwitchProps> = () => {

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const app = initializeApp(FIRASEBASE_CONFIG);
  const [user, setUser] = useState<IFirebaseUser | undefined>(undefined);
  const [darkMode, setDarkMode] = useState<boolean>(false);
  const [errorState, setErrorState] = useState<boolean>(false);
  const auth = getAuth();
  const [authResult, authLoadingState] = useAuthState(auth);
  const login = (newUser: any) => {
    setUser(newUser);
  };
  const logout = () => {
    signOut(auth).then(() => {
      setUser(undefined)
    }).catch((error) => {
      error.log(error)
    });
  };
  const switchMode = () => {
    if (user) {
      const options = {
        user: user.email,
        darkMode: !darkMode,
      };
      setItem('options', JSON.stringify(options));
    }
    setDarkMode(!darkMode);
  };
  const value = {
    user,
    darkMode,
    login,
    logout,
    switchMode,
  };

  useEffect(() => {
    if (user) {
      const options = getItem('options')?.toString();
      if (options && JSON.parse(options).darkMode) {
        setDarkMode(true);
      }
      //  console.log(asciiLogo);
    }
  }, [user, darkMode]);

  useEffect(() => {
    if (authResult && authResult.email?.includes('@wearepsh.com')) {
      login(authResult);
    } else if (authResult && !authResult.email?.includes('@wearepsh.com')) {
      setErrorState(true);
    }
  }, [authResult]);

  useEffect(() => {
    TagManager.initialize({
      gtmId: GTM_ID,
    });
  }, []);

  return (
    <UserContext.Provider value={value}>
      <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
        <GlobalStyles />
        <ScrollToTopContainer />
        <NotificationContainer />
        {authLoadingState && <Loading />}
        {!authLoadingState && (
          <Switch>
            {LoginRoutes(user, errorState)}
            {UserRoutes(user)}
            <Route component={ErrorView} />
          </Switch>
        )}
      </ThemeProvider>
    </UserContext.Provider>
  );
};

export default Application;
