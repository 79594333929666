import styled, { css } from 'styled-components';

import { COLORS } from 'helpers/enums/colors';

import { ReactComponent as WhatsappIcon } from './assets/whatsapp.svg';
import { ReactComponent as MailIcon } from './assets/mail.svg';
import { ReactComponent as IconSlack } from './assets/slack.svg';

import { ReactComponent as IconCake } from './assets/cake.svg';
import { ReactComponent as IconLInkedIn } from './assets/linkedin.svg';


export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Title = styled.h1`
  font-size: 38px;
  line-height: 44px;
  font-weight: 900;
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 0px 10px 20px ${({ theme }) => theme.boxShadow};
  border-radius: 10px;
  padding-top: 24px;
  background-color: ${({ theme }) => theme.cardBackgroundColor};
  position: relative;
  overflow: hidden;
  justify-content: space-between;
`;

export const CollapsibleContainer = styled.div<IDivProps>`
 position: absolute;
 top: 200px;
 background-color: ${({ theme }) => theme.purpleSeeMoreEmployeeCard};
 z-index: -1;
 transition: top 0.4s ease-in-out;

  ${({ isOpen }) =>
    isOpen
      && css`
          top: 0;
          z-index: 3;
          width: 100%;
          height: 100%;
        `
  }
`;

export const CollapsibleContent = styled.div<IDivProps>`
  margin-top: 188px;
  padding: 0 0 0 20px;
`;

export const InfoContainer = styled.div<IDivProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-top: ${({ hasMarginTop }) => hasMarginTop && '15px'};
`;

export const InfoCollapsible = styled.div<IDivProps>`
  font-size: 14px;
  line-height: 24px;
  margin-left: 5px;
  margin-top: ${({ hasMarginTop }) => hasMarginTop && '5px'};
  color: ${({ theme }) => theme.subTitleCardsColor}; 
`;

export const CakeIcon = styled(IconCake)`
  filter: ${({ theme }) => theme.menuIcons}; 
`;

export const LinkedInIcon = styled(IconLInkedIn)`
  filter: ${({ theme }) => theme.menuIcons}; 
`;

export const ImageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 4;
`;

export const ImageBorder = styled.div`
  width: fit-content;
  height: auto;
  padding: 2px;
  border: 1px solid ${COLORS.lightPurple};
  border-radius: 50%;
`;

export const ProfileImage = styled.img`
  object-fit: cover;
  width: 102px;
  height: 102px;
  border-radius: 50%;
`;

export const Name = styled.div`
  width: 100%;
  font-size: 20px;
  line-height: 30px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  z-index: 4;
  text-align: center;
  color: ${({ theme }) => theme.subTitleCardsColor}; 
`;

export const Role = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
  color: ${({ theme }) => theme.subTitleCardsColor}; 
`;

export const SocialContainer = styled.div<IDivProps>`
  margin-top: ${({ hasMarginTop }) => hasMarginTop && '36px'};
  display: flex;
  flex-direction: row;
  padding: 0 0 0 20px;
  align-items: center;
`;

export const Email = styled.div`
  color: ${({ theme }) => theme.redSeeAll};
  font-weight: bold;
  font-size: 12px;
  line-height: 17px;
  max-width: 90%;
  margin-left: 5px;
`;

export const PhoneIcon = styled(WhatsappIcon)`
  margin-bottom: 4px;
  filter: ${({ theme }) => theme.menuIcons}; 
`;

export const EmailIcon = styled(MailIcon)`
  filter: ${({ theme }) => theme.menuIcons}; 
`;

export const SlackIcon = styled(IconSlack)`
  filter: ${({ theme }) => theme.menuIcons}; 
`;

export const Slack = styled.div`
  color: ${({ theme }) => theme.redSeeAll};
  font-weight: bold;
  font-size: 12px;
  line-height: 24px;
  margin-left: 5px;
`;

export const SeeMoreButton = styled.div`
  width: 100%;
  margin-top: 22px;
  padding: 12px 0;
  color: ${({ theme }) => theme.subTitleCardsColor};
  display: flex;
  justify-content: center;
  font-size: 12px;
  line-height: 24px;
  font-weight: bold;
  background-color: ${({ theme }) => theme.purpleSeeMoreEmployeeCard};
  z-index: 4;
  position: relative;
  top: 1px;
  cursor: pointer;
  &:hover, &:active {
    background-color: ${({ theme }) => theme.purpleSeeMoreEmployeeCard};
  }
`;

export const ArrowImg = styled.img`
  width: 24px;
  height: 14px;
  margin-top: 4px;
`;

export const ExternalLink = styled.a`
  text-decoration: none;
  color: inherit;
  width: 90%;
  &:focus, &:hover, &:visited, &:link, &:active {
    text-decoration: none;    
    outline: none;
  }
`

interface IDivProps {
  isOpen?: boolean | null;
  hasMarginTop?: boolean | null;
}
