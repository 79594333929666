import React, { FunctionComponent, useEffect } from 'react';
import { Route, RouteProps, Redirect, useLocation } from 'react-router-dom';

import TagManager from 'react-gtm-module';

import { IFirebaseUser } from 'types/user.types';

interface IPrivateRouteProps extends RouteProps {
  currentUser: IFirebaseUser | undefined;
  contentType?: string;
}

const PrivateRoute: FunctionComponent<IPrivateRouteProps> = ({
  component: Component,
  currentUser,
  ...rest
}) => {

  const location = useLocation();

  useEffect(() => {
    if(currentUser){
      TagManager.dataLayer({
        dataLayer: {
          event: 'pageview',
          pagePath: location.pathname,
          pageTitle: location.pathname,
        },
      });
    }
  }, [currentUser, location]);

  if (!Component) return null;
  return currentUser ? (
    <Route {...rest} render={(props) => <Component {...props} {...rest} />} />
  ) : (
    <Redirect to={{ pathname: '/login', state: { from: location } }} />
  );
};

export default PrivateRoute;
