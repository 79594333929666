import React, { FunctionComponent } from 'react';
import { LottiePlayer } from '../LottiePlayer';
import animationData from './assets/notFound.json';
import { Container, NotFoundParagraph } from './styles';
import { SearchNotFoundProps } from './types';

export const SearchNotFound: FunctionComponent<SearchNotFoundProps> = ({widthSize}: SearchNotFoundProps) => {
    const animationWidthFunction = () => {
        let wide;
        if (window.innerWidth < 460) {
            wide = "90%"
        } else {
            wide = `${widthSize}%`;
        }
        return wide;
    }
    const data = {
        autoplay: true,
        animation_loop: true,
        animation_data: animationData,
        animation_height: "auto",
        animation_width: `${animationWidthFunction()}`,
    }

    return (
        <div>
            <Container>
                <LottiePlayer data={data} />
            </Container>
            <NotFoundParagraph>No encontramos resultados para tu búsqueda, vuelve a intentarlo.</NotFoundParagraph>
        </div>
    )
}
