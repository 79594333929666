import React, { ReactChild } from 'react';

import { IFirebaseUser } from 'types/user.types';

import PublicRoute from 'containers/PublicRoute';
import LoginContainer from 'containers/LoginContainer';

const LoginRoutes = (currentUser: IFirebaseUser | undefined, errorState: boolean): ReactChild[] => {
  return [
    <PublicRoute
      path="/login"
      component={LoginContainer}
      exact
      restricted
      currentUser={currentUser}
      errorState={errorState}
    />,
  ];
};
export default LoginRoutes;
