import React, { FunctionComponent } from 'react';
import GoogleSSO from 'components/GoogleSSO';
import { ILoginViewProps } from './types';
import { ErrorMessage, LoginContainer, Logo, Title, Text } from './styles';

const LoginView: FunctionComponent<ILoginViewProps> = ({errorState}) => (
  <LoginContainer data-testid="login-view">
    <Logo />
    <Title>¡Te damos la bienvenida!</Title>
    <Text>My PSh es nuestro espacio digital. <br /> Aquí encontrarás información, actividades y documentos relevantes para tu día a día.</Text>
    <GoogleSSO />
    {(errorState === true) &&
      <ErrorMessage>Sorry! We couldn&#39;t detect any email linked to the PSh community. Please try again.</ErrorMessage>}
  </LoginContainer>
);

export default LoginView;
