import axios, { AxiosResponse } from 'axios';

import { API_BASE_URL } from 'configs/configs';

const api = axios.create({
  baseURL: API_BASE_URL,
});

/*
// If token is expired
api.interceptors.response.use(
  (response) => response,
  (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest.retry) {
      originalRequest.retry = true;
      return api
        .post('auth/refresh-token', { refreshToken: getCookie('refreshToken') })
        .then(({ data }) => {
          saveUserCookies(data.token);
          const accessToken = getCookie('accessToken');
          originalRequest.headers.Authorization = `Bearer ${accessToken}`;
          return axios(originalRequest);
        });
    }
    return Promise.reject(error);
  },
);
*/

function getOptions() {
  const options = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return options;
}

function errorResponse(error: any) {
  const { response } = error;
  const message = response?.data?.message || error.message;
  return new Error(message);
}

export async function get(url: string, headers = {}): Promise<AxiosResponse> {
  try {
    const options = { ...headers };
    const response = await api.get(url, options);
    return response;
  } catch (error) {
    throw errorResponse(error);
  }
}

export async function post(url: string, params = {}, headers = {}): Promise<AxiosResponse> {
  try {
    const options = { ...getOptions(), ...headers };
    const response = await api.post(url, params, options);
    return response;
  } catch (error) {
    throw errorResponse(error);
  }
}

export async function put(url: string, params = {}, headers = {}): Promise<AxiosResponse> {
  try {
    const options = { ...getOptions(), ...headers };
    const response = await api.put(url, params, options);
    return response;
  } catch (error) {
    throw errorResponse(error);
  }
}
