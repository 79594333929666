import React, { FunctionComponent, useState, useEffect } from 'react';
import LayoutContainer from 'containers/LayoutContainer';
import HomeView from 'views/HomeView';
import { getPromobar } from 'services/promobar.service';
import { getFriendlyRemindersCards } from 'services/friendlyReminders.service';
import { getSocialMediaCards } from 'services/socialMedia.service';
import { getTechTalkCards } from 'services/techTalks.service';
import { getNewsletterCards } from 'services/newsletter.service';
import { getWellnessCards } from 'services/wellness.service';
// import { getEnglishTipsLastPostDate } from 'services/englishTips.service';
import { getCalendar } from 'services/calendar.service';
// import { getTownhallsLastPostDate } from 'services/townhall.service';

const name = 'Home';
const HomeContainer: FunctionComponent = () => {

  const [friendlyReminders, setFriendlyReminders] = useState<any>([]);

  const [SocialMedia, setSocialMedia] = useState<any>([]);

  const [calendar, setCalendar] = useState<any>([]);

  const [techWellnessNewsletterCards, setTechWellnessNewsletterCards] = useState<any>({});

  const [promobar, setPromobar] = useState<any>([]);

  // const [newNotificationEnglish, setNewNotificationEnglish] = useState<boolean>(false);
  // const [newNotificationTownhall, setNewNotificationTownhall] = useState<boolean>(false);
  
  // const differenceBetweenDateAndToday = async (date) => {
  //   const dayDifference = (new Date().getTime() - new Date(date).getTime())/ (1000 * 3600 * 24);
  //   return Math.round(dayDifference);
  // };

  
  const [loading, setLoading] = useState(
    {
      PromobarLoading: true,
      FriendlyRemindersLoading: true,
      SocialMediaCardsLoading: true,
      TechWellnessNewsletterCardsLoading: true,
      CalendarCardsLoading: true,
    }
  );
    
  const fetchPromobar = async () => {
    const fetechedPromobar = await getPromobar();
    setPromobar(fetechedPromobar.data);
    setLoading(prevState => ({ ...prevState, PromobarLoading: false }));
  };

  const fetchFriendlyReminderCards = async () => {
    const fetchedFriendlyReminders = await getFriendlyRemindersCards(3);
    setFriendlyReminders(fetchedFriendlyReminders.data);
    setLoading(prevState => ({ ...prevState, FriendlyRemindersLoading: false }));
  };

  const fetchSocialMediaCards = async () => {
    const fetchedSocialMedia = await getSocialMediaCards(3);
    setSocialMedia(fetchedSocialMedia.data);
    setLoading(prevState => ({ ...prevState, SocialMediaCardsLoading: false }));
  };

  const fetchTechWellnessNewsletter = async () => {
    const fetchedTech = await getTechTalkCards(1);
    const fetchedWellness = await getWellnessCards(1);
    const fetchedNewsletter = await getNewsletterCards(1);
    setTechWellnessNewsletterCards(prevState => ({...prevState, wellness:fetchedWellness.data[0], newsletter:fetchedNewsletter.data[0], tech:fetchedTech.data[0]}));
    setLoading(prevState => ({ ...prevState, TechWellnessNewsletterCardsLoading: false }));
  };

  const fetchCalendarCards = async () => {
    const fetchedCalendar = await getCalendar();
    setCalendar(fetchedCalendar.data);
    setLoading(prevState => ({ ...prevState, CalendarCardsLoading: false }));
  };

  // const lastEnglishTipsPostDate = async () => {
  //   const dateLastPublication = await getEnglishTipsLastPostDate();
  //   setNewNotificationEnglish(await differenceBetweenDateAndToday(dateLastPublication.data) <= 3);
  // };

  // const lastTownhallPostDate = async () => {
  //   const dateLastPublication = await getTownhallsLastPostDate();
  //   setNewNotificationTownhall(await differenceBetweenDateAndToday(dateLastPublication.data) <= 3);
  // };

  useEffect(() => {
    fetchPromobar();
    fetchFriendlyReminderCards();
    fetchSocialMediaCards();
    fetchTechWellnessNewsletter();
    fetchCalendarCards();
    // lastEnglishTipsPostDate();
    // lastTownhallPostDate();
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LayoutContainer 
    promobar={promobar}
    loading={loading}
    >
        <HomeView
          name={name}
          friendlyReminders={friendlyReminders}
          socialMedias={SocialMedia}
          calendar={calendar}
          generalInfo={[]}
          techWellnessNewsletterCards={techWellnessNewsletterCards}
          // newNotificationEnglish={newNotificationEnglish}
          // newNotificationTownhall={newNotificationTownhall}
          loading={loading}
        />
    </LayoutContainer>
  );
};

export default HomeContainer;
