import React, { FunctionComponent, useState, useEffect } from 'react';

import { getFaq } from 'services/faq.service';
import LayoutContainer from 'containers/LayoutContainer';
import FaqAdminView from 'views/FaqAdminView';
import { IStrapiComponent } from 'views/PostView/types';

const FaqAdminContainer: FunctionComponent = () => {

  const [faqData, setFaqData] = useState<IStrapiComponent[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const fetchFaqData = async () => {
    const fetechedFaq = await getFaq();
    setFaqData(fetechedFaq.data);
    setLoading(false);
  };

  useEffect(() => {
    fetchFaqData();
  }, []);

  return (
    <LayoutContainer>
      <FaqAdminView dataList={faqData} loading={loading} />
    </LayoutContainer>
  );
};

export default FaqAdminContainer;
