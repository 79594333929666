import React, { FunctionComponent } from 'react';

import EnglishTipsCards from 'components/EnglishTips';
import BackButton from 'components/BackButton';

import { Background, SectionName, Title, TopContainer } from 'styles/commons';

import { IEnglishTipsView } from './types';

const EnglishTipsView: FunctionComponent <IEnglishTipsView>=(props : IEnglishTipsView) => {
    const { dataList } = props;
    return (
        <Background hasMarginBottom>
            <TopContainer>
                <BackButton />
                <SectionName>NEWS</SectionName>
            </TopContainer>
            <Title hasMarginBottom>English Tips</Title>
            <EnglishTipsCards dataList={dataList} />
        </Background>
    )
}
export default EnglishTipsView;
