import { AxiosResponse } from 'axios';

import { get } from 'services/api';

export async function getSocialMedia(): Promise<AxiosResponse> {
  const response = await get('social-medias');
  return response;
}

export async function getSocialMediaCards(limit: number): Promise<AxiosResponse> {
  const response = await get(`social-medias-cards?limit=${limit}&sort=published_at:DESC`);
  return response;
}

export async function getPostBySlug(slug: string): Promise<AxiosResponse> {
  const response = await get(`friendly-reminders?slug=${slug}`);
  return response;
}
