import React, { ReactChild } from 'react';

import PrivateRoute from 'containers/PrivateRoute';
import HomeContainer from 'containers/HomeContainer';
import PostContainer from 'containers/PostContainer';
import SearchContainer from 'containers/SearchContainer';
import FaqAdminContainer from 'containers/FaqAdminContainer';
import VacationsContainer from 'containers/VacationsContainer';
import EnglishTipsContainer from 'containers/EnglishTipsContainer';
import EmployeesDirectoryContainer from 'containers/EmployeesDirectoryContainer';

import { IFirebaseUser } from 'types/user.types';

const UserRoutes = (currentUser: IFirebaseUser | undefined): ReactChild[] => {
  return [
    <PrivateRoute path="/posts/:id" component={PostContainer} currentUser={currentUser} />,
    <PrivateRoute path="/social-media/:id" component={PostContainer} currentUser={currentUser}/>,
    <PrivateRoute
      path="/friendly-reminder/:slug"
      component={PostContainer}
      currentUser={currentUser}
      contentType="friendly-reminder"
    />,

    <PrivateRoute
    path="/content/:slug"
    component={PostContainer}
    currentUser={currentUser}
    contentType="landing-page"
    />,

    <PrivateRoute
      path="/wellness/:slug"
      component={PostContainer}
      currentUser={currentUser}
      contentType="wellness"
    />,

    <PrivateRoute
      path="/psh-solidario/:slug"
      component={PostContainer}
      currentUser={currentUser}
      contentType="psh-solidario"
    />,

    // Search view specefic routes
    <PrivateRoute
      path="/friendly-reminders"
      exact
      component={SearchContainer}
      contentType="friendly-reminder"
      currentUser={currentUser}
    />,
    <PrivateRoute
      path="/social-media"
      exact
      component={SearchContainer}
      contentType="social-media"
      currentUser={currentUser}
    />,
    <PrivateRoute
      path="/training-palooza"
      exact
      component={SearchContainer}
      contentType="training-palooza"
      currentUser={currentUser}
    />,
    <PrivateRoute
      path="/tech-talks"
      exact
      component={SearchContainer}
      contentType="tech-talk"
      currentUser={currentUser}
    />,
    <PrivateRoute
      path="/wellness"
      exact
      component={SearchContainer}
      contentType="wellness"
      currentUser={currentUser}
    />,
    <PrivateRoute
      path="/newsletters"
      exact
      component={SearchContainer}
      contentType="monthly-newsletter"
      currentUser={currentUser}
    />,
    <PrivateRoute
      path="/townhall"
      exact
      component={SearchContainer}
      contentType="townhall"
      currentUser={currentUser}
    />,
    
    <PrivateRoute
     path="/search"
     component={SearchContainer}
     currentUser={currentUser}
    />,
    <PrivateRoute path="/staff-directory" component={EmployeesDirectoryContainer} currentUser={currentUser} />,
    <PrivateRoute path="/faq-admins" component={FaqAdminContainer} currentUser={currentUser} />,
    <PrivateRoute path="/english-tips" component={EnglishTipsContainer} currentUser={currentUser} />,
    <PrivateRoute path="/vacations-calendar" component={VacationsContainer} currentUser={currentUser} />,
    <PrivateRoute path="/vacations" component={VacationsContainer} currentUser={currentUser} />,
    <PrivateRoute path="/pto" component={VacationsContainer} currentUser={currentUser} />,
    <PrivateRoute path="/" component={HomeContainer} currentUser={currentUser} />,
  ]
};
export default UserRoutes;
